// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const ArchitectureIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 512 512" {...props} fill="none" ref={ref}>
      <path
        d="M304 160L240 96L304 32M207 352L271 416L207 480"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M112 144C138.51 144 160 122.51 160 96C160 69.4903 138.51 48 112 48C85.4903 48 64 69.4903 64 96C64 122.51 85.4903 144 112 144Z"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M400 464C426.51 464 448 442.51 448 416C448 389.49 426.51 368 400 368C373.49 368 352 389.49 352 416C352 442.51 373.49 464 400 464Z"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M256 96H340C355.913 96 371.174 102.321 382.426 113.574C393.679 124.826 400 140.087 400 156V368M255 416H171C155.087 416 139.826 409.679 128.574 398.426C117.321 387.174 111 371.913 111 356V144"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
});

export const ArchitectureIconMetadata = {
  name: 'Architecture',
  importName: 'ArchitectureIcon',
  component: <ArchitectureIcon aria-label="Architecture Icon" />,
  tags: 'architecture, git, compare, flow, nodes, edges, graph, diagram, structure, design, software',
};
