// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const ExpandAllIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref} fill="none">
      <path d="M18 6.41L16.59 5L12 9.58L7.41 5L6 6.41L12 12.41L18 6.41Z" fill="currentColor" />

      <path d="M18 13L16.59 11.59L12 16.17L7.41 11.59L6 13L12 19L18 13Z" fill="currentColor" />
    </Icon>
  );
});

export const ExpandAllIconMetadata = {
  name: 'Expand All',
  importName: 'ExpandAllIcon',
  component: <ExpandAllIcon aria-label="Expand All Icon" />,
  tags: 'expand, all, arrow, up, down, table, double, direction',
};
