// 3rd
import { Heading as ChakraHeading, forwardRef } from '@chakra-ui/react';
import type { HeadingProps as ChakraHeadingProps, StyleProps } from '@chakra-ui/react';

export type HeadingProps = Pick<ChakraHeadingProps, 'variant' | 'color' | 'as' | 'children'> &
  StyleProps;

export const Heading = forwardRef(
  ({ color, variant, children, ...styleProps }: HeadingProps, ref) => {
    return (
      <ChakraHeading variant={variant} color={color || 'text.primary'} {...styleProps} ref={ref}>
        {children}
      </ChakraHeading>
    );
  }
);
