// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const FilterFunnelIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 16 16" {...props} ref={ref} fill="none">
      <g clipPath="url(#clip0_420_17462)">
        <path
          d="M2.13191 3.83625C2.06684 3.76465 2.02395 3.67571 2.00843 3.58022C1.99291 3.48473 2.00542 3.38678 2.04446 3.29826C2.08349 3.20974 2.14737 3.13445 2.22834 3.0815C2.30932 3.02856 2.40391 3.00025 2.50066 3H13.5007C13.5975 3.00001 13.6923 3.02815 13.7734 3.08099C13.8546 3.13384 13.9187 3.20912 13.9579 3.29769C13.997 3.38626 14.0097 3.48431 13.9942 3.57991C13.9787 3.67552 13.9358 3.76457 13.8707 3.83625L9.50066 8.5V12.1656C9.50071 12.248 9.48043 12.329 9.44161 12.4017C9.4028 12.4743 9.34665 12.5362 9.27816 12.5819L7.27816 13.915C7.20293 13.9652 7.11549 13.994 7.02516 13.9985C6.93484 14.0029 6.845 13.9828 6.76521 13.9402C6.68543 13.8976 6.61869 13.8342 6.5721 13.7566C6.52552 13.6791 6.50082 13.5904 6.50066 13.5V8.5L2.13191 3.83625Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>

      <defs>
        <clipPath id="clip0_420_17462">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
});

export const FilterFunnelIconMetadata = {
  name: 'Filter Funnel',
  importName: 'FilterFunnelIcon',
  component: <FilterFunnelIcon aria-label="Filter Funnel Icon" />,
  tags: 'filter, sort, order, arrange, table, data, list, burger',
};
