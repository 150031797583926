// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const RefreshIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 25" {...props} ref={ref}>
      <g id="IoRefreshOutline">
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.41655 7.20776C8.77325 6.30124 10.3683 5.81738 12 5.81738C13.0484 5.81738 13.7844 5.85279 14.2988 5.90985C14.7743 5.9626 15.1704 6.04286 15.4416 6.20706C15.5878 6.29558 15.8211 6.48453 15.8811 6.81975C15.9413 7.156 15.7851 7.40366 15.6986 7.5115C15.6082 7.62409 15.5079 7.69591 15.4562 7.73027C15.4259 7.75041 15.399 7.76632 15.3784 7.77784C15.368 7.78371 15.3587 7.7887 15.3509 7.7928L15.3401 7.79835L15.3355 7.80067L15.3334 7.80171L15.3324 7.80221C15.3319 7.80244 15.3314 7.80268 15 7.12988L15.3314 7.80268C14.9599 7.98572 14.5102 7.83289 14.3272 7.46131C14.3209 7.44856 14.315 7.43571 14.3095 7.42279C14.2577 7.41544 14.1992 7.408 14.1334 7.4007C13.697 7.35229 13.0226 7.31738 12 7.31738C10.665 7.31738 9.35994 7.71326 8.2499 8.45496C7.13987 9.19666 6.27471 10.2509 5.76382 11.4843C5.25293 12.7177 5.11925 14.0749 5.3797 15.3842C5.64015 16.6936 6.28303 17.8963 7.22703 18.8404C8.17104 19.7844 9.37377 20.4272 10.6831 20.6877C11.9925 20.9481 13.3497 20.8145 14.5831 20.3036C15.8165 19.7927 16.8707 18.9275 17.6124 17.8175C18.3541 16.7074 18.75 15.4024 18.75 14.0674C18.75 13.6532 19.0858 13.3174 19.5 13.3174C19.9142 13.3174 20.25 13.6532 20.25 14.0674C20.25 15.6991 19.7661 17.2941 18.8596 18.6508C17.9531 20.0075 16.6646 21.065 15.1571 21.6894C13.6497 22.3138 11.9909 22.4772 10.3905 22.1589C8.79017 21.8405 7.32016 21.0548 6.16637 19.901C5.01259 18.7472 4.22685 17.2772 3.90853 15.6769C3.5902 14.0765 3.75357 12.4177 4.378 10.9102C5.00242 9.40275 6.05984 8.11428 7.41655 7.20776Z"
          fill="currentColor"
        />

        <path
          id="Vector (Stroke)_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.4697 2.47455C11.7626 2.18166 12.2374 2.18166 12.5303 2.47455L16.2803 6.22455C16.5732 6.51745 16.5732 6.99232 16.2803 7.28521L12.5303 11.0352C12.2374 11.3281 11.7626 11.3281 11.4697 11.0352C11.1768 10.7423 11.1768 10.2674 11.4697 9.97455L14.6893 6.75488L11.4697 3.53521C11.1768 3.24232 11.1768 2.76745 11.4697 2.47455Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
});

export const RefreshIconMetadata = {
  name: 'Refresh',
  importName: 'RefreshIcon',
  component: <RefreshIcon aria-label="Refresh Icon" />,
  tags: 'refresh, reload, sync, update, repeat, arrow, circular, loop, clockwise, redo, undo',
};
