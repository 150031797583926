// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

// INSIGHT this icon is from Adam
export const BusinessLogicIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <g id="briefcase-outline">
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 8.1875C1 7.04841 1.92341 6.125 3.0625 6.125H20.9375C22.0766 6.125 23 7.04841 23 8.1875V19.1875C23 20.3266 22.0766 21.25 20.9375 21.25H3.0625C1.92341 21.25 1 20.3266 1 19.1875V8.1875ZM3.0625 7.5C2.6828 7.5 2.375 7.8078 2.375 8.1875V19.1875C2.375 19.5672 2.6828 19.875 3.0625 19.875H20.9375C21.3172 19.875 21.625 19.5672 21.625 19.1875V8.1875C21.625 7.8078 21.3172 7.5 20.9375 7.5H3.0625Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.1875 6.8125C7.1875 4.15463 9.34213 2 12 2C14.6579 2 16.8125 4.15463 16.8125 6.8125C16.8125 7.1922 16.5047 7.5 16.125 7.5C15.7453 7.5 15.4375 7.1922 15.4375 6.8125C15.4375 4.91402 13.8985 3.375 12 3.375C10.1015 3.375 8.5625 4.91402 8.5625 6.8125C8.5625 7.1922 8.2547 7.5 7.875 7.5C7.4953 7.5 7.1875 7.1922 7.1875 6.8125Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 12.3125C1 11.9328 1.3078 11.625 1.6875 11.625H10.625C11.0047 11.625 11.3125 11.9328 11.3125 12.3125C11.3125 12.6922 11.0047 13 10.625 13H1.6875C1.3078 13 1 12.6922 1 12.3125Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.6875 12.3125C12.6875 11.9328 12.9953 11.625 13.375 11.625H22.3125C22.6922 11.625 23 11.9328 23 12.3125C23 12.6922 22.6922 13 22.3125 13H13.375C12.9953 13 12.6875 12.6922 12.6875 12.3125Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 10.9366C11.6203 10.9366 11.3125 11.2444 11.3125 11.6241V12.9991C11.3125 13.3788 11.6203 13.6866 12 13.6866C12.3797 13.6866 12.6875 13.3788 12.6875 12.9991V11.6241C12.6875 11.2444 12.3797 10.9366 12 10.9366ZM9.9375 11.6241C9.9375 10.485 10.8609 9.56158 12 9.56158C13.1391 9.56158 14.0625 10.485 14.0625 11.6241V12.9991C14.0625 14.1382 13.1391 15.0616 12 15.0616C10.8609 15.0616 9.9375 14.1382 9.9375 12.9991V11.6241Z"
            fill="currentColor"
          />
        </g>
      </g>
    </Icon>
  );
});

export const BusinessLogicIconMetadata = {
  name: 'Business Logic',
  importName: 'BusinessLogicIcon',
  component: <BusinessLogicIcon aria-label="Business Logic Icon" />,
  tags: 'business, logic, process, rules, decision, operations, strategy',
};
