// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const DropboxIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 94 80" {...props} ref={ref} fill="none">
      <path
        d="M23.4982 0L0 14.9706L23.4982 29.9416L47 14.9706L23.4982 0ZM70.5 0L47 14.9721L70.5 29.9441L94.0004 14.9721L70.5 0ZM0 44.9147L23.4982 59.8857L47 44.9147L23.4982 29.9441L0 44.9147ZM70.5 29.9441L47 44.9162L70.5 59.8879L94 44.9162L70.5 29.9441ZM23.5 64.9081L47.0018 79.8791L70.5 64.9081L47.0018 49.9375L23.5 64.9081Z"
        fill="#0061FF"
      />
    </Icon>
  );
});

export const DropboxIconMetadata = {
  name: 'Dropbox Logo',
  importName: 'DropboxIcon',
  component: <DropboxIcon aria-label="Dropbox Icon" />,
  tags: 'dropbox, drop, box, logo, brand, company, org, organization',
};
