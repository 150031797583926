// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const SettingsIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <path
        d="M19.14 12.94C19.18 12.64 19.2 12.33 19.2 12C19.2 11.68 19.18 11.36 19.13 11.06L21.16 9.48003C21.2474 9.40793 21.3072 9.30772 21.3291 9.19649C21.351 9.08527 21.3336 8.96989 21.28 8.87003L19.36 5.55003C19.3034 5.44959 19.2132 5.3724 19.1052 5.33214C18.9972 5.29187 18.8785 5.29112 18.77 5.33003L16.38 6.29003C15.88 5.91003 15.35 5.59003 14.76 5.35003L14.4 2.81003C14.3823 2.69556 14.3241 2.59123 14.236 2.516C14.1479 2.44077 14.0358 2.39962 13.92 2.40003H10.08C9.83996 2.40003 9.64996 2.57003 9.60996 2.81003L9.24996 5.35003C8.65996 5.59003 8.11996 5.92003 7.62996 6.29003L5.23996 5.33003C5.01996 5.25003 4.76996 5.33003 4.64996 5.55003L2.73996 8.87003C2.61996 9.08003 2.65996 9.34003 2.85996 9.48003L4.88996 11.06C4.83996 11.36 4.79996 11.69 4.79996 12C4.79996 12.31 4.81996 12.64 4.86996 12.94L2.83996 14.52C2.75249 14.5921 2.69272 14.6923 2.67084 14.8036C2.64896 14.9148 2.66632 15.0302 2.71996 15.13L4.63996 18.45C4.75996 18.67 5.00996 18.74 5.22996 18.67L7.61996 17.71C8.11996 18.09 8.64996 18.41 9.23996 18.65L9.59996 21.19C9.64996 21.43 9.83996 21.6 10.08 21.6H13.92C14.16 21.6 14.36 21.43 14.39 21.19L14.75 18.65C15.34 18.41 15.88 18.09 16.37 17.71L18.76 18.67C18.98 18.75 19.23 18.67 19.35 18.45L21.27 15.13C21.39 14.91 21.34 14.66 21.15 14.52L19.14 12.94ZM12 15.6C10.02 15.6 8.39996 13.98 8.39996 12C8.39996 10.02 10.02 8.40003 12 8.40003C13.98 8.40003 15.6 10.02 15.6 12C15.6 13.98 13.98 15.6 12 15.6Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const SettingsIconMetadata = {
  name: 'Settings',
  importName: 'SettingsIcon',
  component: <SettingsIcon aria-label="Settings Icon" />,
  tags: 'settings, gear, cog, preferences, options, configuration, tools',
};
