// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const WarningIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <path
        d="M23.1189 20L13.7719 2.15001C13.6025 1.82675 13.3479 1.55601 13.0356 1.3671C12.7234 1.1782 12.3654 1.07834 12.0004 1.07834C11.6355 1.07834 11.2775 1.1782 10.9652 1.3671C10.6529 1.55601 10.3983 1.82675 10.2289 2.15001L0.880918 20C0.721239 20.3049 0.642922 20.6458 0.65354 20.9897C0.664159 21.3337 0.763354 21.6691 0.941533 21.9635C1.11971 22.2579 1.37085 22.5014 1.67065 22.6704C1.97045 22.8393 2.30878 22.9281 2.65292 22.928H21.3469C21.6911 22.9281 22.0294 22.8393 22.3292 22.6704C22.629 22.5014 22.8801 22.2579 23.0583 21.9635C23.2365 21.6691 23.3357 21.3337 23.3463 20.9897C23.3569 20.6458 23.2786 20.3049 23.1189 20ZM10.9999 8.42301C10.9999 8.1578 11.1053 7.90344 11.2928 7.71591C11.4803 7.52837 11.7347 7.42301 11.9999 7.42301C12.2651 7.42301 12.5195 7.52837 12.707 7.71591C12.8946 7.90344 12.9999 8.1578 12.9999 8.42301V14.423C12.9999 14.6882 12.8946 14.9426 12.707 15.1301C12.5195 15.3177 12.2651 15.423 11.9999 15.423C11.7347 15.423 11.4803 15.3177 11.2928 15.1301C11.1053 14.9426 10.9999 14.6882 10.9999 14.423V8.42301ZM12.0499 19.933H12.0219C11.6276 19.9317 11.249 19.7781 10.9653 19.5041C10.6816 19.2301 10.5149 18.8571 10.4999 18.463C10.4927 18.267 10.5246 18.0716 10.5938 17.888C10.663 17.7045 10.7681 17.5366 10.9029 17.3942C11.0377 17.2518 11.1996 17.1376 11.379 17.0584C11.5584 16.9792 11.7518 16.9366 11.9479 16.933H11.9759C12.3702 16.9335 12.749 17.0864 13.0331 17.3599C13.3171 17.6333 13.4844 18.006 13.4999 18.4C13.5077 18.5965 13.4761 18.7925 13.407 18.9765C13.3379 19.1606 13.2327 19.329 13.0977 19.4718C12.9626 19.6146 12.8003 19.729 12.6204 19.8082C12.4404 19.8874 12.2465 19.9298 12.0499 19.933Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const WarningIconMetadata = {
  name: 'Warning',
  importName: 'WarningIcon',
  component: <WarningIcon aria-label="Warning Icon" />,
  tags: 'warning, caution, alert, danger, attention, exclamation, error, problem, issue, hazard, risk, alarm, notice',
};
