// React & Next
import type { ReactElement } from 'react';

// 3rd
import { forwardRef, IconButton as ChakraIconButton } from '@chakra-ui/react';
import type { IconButtonProps as ChakraIconButtonProps } from '@chakra-ui/react';
import type { SystemStyleObject } from '@chakra-ui/styled-system';

export type IconButtonProps = Omit<
  ChakraIconButtonProps,
  | '_active'
  | '_disabled'
  | '_hover'
  | '_focus'
  | '_focusVisible'
  | '_focusWithin'
  | '_loading'
  | '_after'
  | '_before'
  | '_first'
  | '_last'
  | '_notFirst'
  | '_notLast'
  | '_checked'
  | '_hidden'
  | '_placeholder'
  | '_placeholderShown'
  | '_selected'
  | '_selection'
  | '_mediaReduceMotion'
  | '_mediaDark'
  | '_dark'
  | '_light'
  | '_odd'
  | '_even'
  | '_ltr'
  | '_rtl'
  | '_indeterminate'
  | '_horizontal'
  | '_vertical'
  | '_visited'
  | '_valid'
  | '_activeLink'
  | '_activeStep'
  | '_autofill'
  | '_grabbed'
  | '_expanded'
  | '_groupActive'
  | '_groupChecked'
  | '_groupDisabled'
  | '_groupFocus'
  | '_groupFocusVisible'
  | '_groupFocusWithin'
  | '_groupHover'
  | '_groupInvalid'
  | '_peerActive'
  | '_peerChecked'
  | '_peerDisabled'
  | '_peerFocus'
  | '_peerFocusVisible'
  | '_peerFocusWithin'
  | '_peerHover'
  | '_peerInvalid'
  | '_peerPlaceholderShown'
  | '_firstLetter'
  | '_fullScreen'
  | '_highlighted'
  | '_invalid'
  | '_pressed'
  | '_empty'
  | '_readOnly'
> & {
  icon: ReactElement;
  size?: 'sm' | 'md' | 'lg';
  sx?: SystemStyleObject;
};

export const IconButton = forwardRef(({ variant, icon, size, ...props }: IconButtonProps, ref) => {
  const isOld = !!variant && typeof variant === 'string' && variant.includes('old');

  return (
    <ChakraIconButton
      {...props}
      ref={ref}
      variant={variant ? variant : 'circular'}
      size={`icon${isOld ? '-old' : ''}-${size ? size : 'md'}`}
      icon={icon}
    />
  );
});
