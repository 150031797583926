// 3rd
import { Text as ChakraText, forwardRef } from '@chakra-ui/react';
import type { TextProps as ChakraTextProps, StyleProps } from '@chakra-ui/react';

// App - Other
import { useIsOverflowing } from '@/components/hooks/use-is-overflowing';
import { Tooltip } from '@/components/molecules/tooltips';

export type TextProps = Pick<
  ChakraTextProps,
  'variant' | 'color' | 'onClick' | 'noOfLines' | 'children'
> &
  StyleProps;

export const Text = forwardRef(
  ({ color, variant, noOfLines, onClick, children, ...styleProps }: TextProps, ref) => {
    return (
      <ChakraText
        variant={variant}
        color={color}
        onClick={onClick}
        noOfLines={noOfLines}
        {...styleProps}
        as="span"
        ref={ref}
      >
        {children}
      </ChakraText>
    );
  }
);

export const EllipsisText = forwardRef(({ children, ...textProps }: TextProps, ref) => {
  const { ref: containerRef, isOverflowing } = useIsOverflowing(ref);

  return (
    <Tooltip content={children} isDisabled={!isOverflowing}>
      <Text
        {...textProps}
        ref={containerRef}
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
      >
        {children}
      </Text>
    </Tooltip>
  );
});

export const LinesClampedText = forwardRef(
  ({ children, noOfLines = 1, ...textProps }: TextProps, ref) => {
    return (
      <Text {...textProps} noOfLines={noOfLines} ref={ref}>
        {children}
      </Text>
    );
  }
);
