'use client';

// 3rd
import { createIcon } from '@chakra-ui/icons';

// INSIGHT this illustration is from Shay
export const ErrorOldIllustration = createIcon({
  displayName: 'ErrorOldIllustration',
  viewBox: '0 0 289 372',
  path: (
    <svg
      width="289"
      height="372"
      viewBox="0 0 289 372"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2204_110)">
        <path
          d="M142.066 113.35C99.5763 185.72 102.637 301.557 102.637 301.557L87.5947 299.933C87.5947 299.933 90.4982 179.608 138.695 111.373L142.063 113.35H142.066Z"
          fill="#334DFF"
        />
        <path
          d="M146.073 106.292C146.073 106.292 90.2531 137.838 57.7932 119.967C41.7203 111.119 35.8662 90.9169 44.7149 74.8409C48.6302 67.7268 54.7734 62.6142 61.7964 59.893C60.3416 52.5023 61.3785 44.5806 65.2938 37.4665C74.1425 21.3936 94.3475 15.5364 110.42 24.3851C142.415 42.0008 146.076 106.292 146.076 106.292H146.073Z"
          fill="#334DFF"
        />
        <path
          d="M146.073 106.292C146.073 106.292 114.527 50.4724 132.398 18.0125C141.246 1.93968 161.451 -3.91441 177.524 4.93429C184.638 8.84959 189.751 14.9928 192.472 22.0158C199.863 20.5609 207.784 21.5979 214.899 25.5132C230.971 34.3619 236.829 54.5668 227.98 70.6397C210.364 102.635 146.073 106.295 146.073 106.295V106.292Z"
          fill="#334DFF"
        />
        <path
          d="M146.073 106.292C146.073 106.292 177.618 162.112 159.748 194.572C150.899 210.645 130.694 216.502 114.621 207.653C107.507 203.738 102.395 197.595 99.6735 190.572C92.2829 192.026 84.3611 190.99 77.247 187.074C61.1741 178.226 55.3169 158.021 64.1656 141.948C81.7813 109.953 146.073 106.292 146.073 106.292Z"
          fill="#334DFF"
        />
      </g>
      <path
        d="M186.342 116.559C186.342 116.559 250.404 113.919 271.153 144.619C281.427 159.821 277.432 180.475 262.231 190.749C255.503 195.297 247.705 197.048 240.214 196.269C238.141 203.511 233.607 210.089 226.88 214.637C211.679 224.911 191.025 220.917 180.751 205.715C160.3 175.454 186.342 116.559 186.342 116.559Z"
        fill="#E2EAFD"
      />
      <g clipPath="url(#clip1_2204_110)">
        <path
          d="M195.927 362.856C195.927 367.446 152.169 371.185 98.1756 371.185C44.1818 371.185 0.424072 367.446 0.424072 362.856C0.424072 358.265 44.1818 354.526 98.1756 354.526C152.169 354.526 195.927 358.247 195.927 362.856Z"
          fill="#E2EAFD"
          fillOpacity="0.25"
        />
        <path
          d="M37.0369 289.112V320.005C37.0369 343.568 56.3243 362.856 79.8876 362.856H116.482C140.045 362.856 159.333 343.568 159.333 320.005V289.112H37.0369Z"
          fill="#E2EAFD"
        />
        <mask
          id="mask0_2204_110"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x="37"
          y="289"
          width="123"
          height="74"
        >
          <path
            d="M37.0369 289.112V320.005C37.0369 343.568 56.3243 362.856 79.8876 362.856H116.482C140.045 362.856 159.333 343.568 159.333 320.005V289.112H37.0369Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_2204_110)">
          <path d="M182.896 309.306H13.4551V318.376H182.896V309.306Z" fill="white" />
        </g>
        <path
          opacity="0.1"
          d="M136.491 289.112V320.005C136.491 343.568 117.204 362.856 93.6406 362.856H116.464C140.027 362.856 159.314 343.568 159.314 320.005V289.112H136.491Z"
          fill="#482C80"
        />
        <path d="M163.201 283.559H33.1497V295.054H163.201V283.559Z" fill="#E2EAFD" />
      </g>
      <defs>
        <clipPath id="clip0_2204_110">
          <rect
            width="210.967"
            height="300.749"
            fill="white"
            transform="translate(40.5891 0.808472)"
          />
        </clipPath>
        <clipPath id="clip1_2204_110">
          <rect
            width="195.503"
            height="87.6081"
            fill="white"
            transform="translate(0.424072 283.559)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
});
