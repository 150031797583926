// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const PushPinIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <path d="M16 12V4H17V2H7V4H8V12L6 14V16H11.2V22H12.8V16H18V14L16 12Z" fill="currentColor" />
    </Icon>
  );
});

export const PushPinIconMetadata = {
  name: 'Push Pin',
  importName: 'PushPinIcon',
  component: <PushPinIcon aria-label="Push Pin Icon" />,
  tags: 'push, pin, table, sticky, reminder, marker, table',
};
