// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const SpoofingIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 512 512" {...props} ref={ref} fill="none">
      <path
        d="M224 232C224 223.513 227.371 215.374 233.373 209.373C239.374 203.371 247.513 200 256 200C264.487 200 272.626 203.371 278.627 209.373C284.629 215.374 288 223.513 288 232M448 200H464M448 200C448 296 432 328 368 328C304 328 288 296 288 200C288 200 304 184 368 184C432 184 448 200 448 200ZM64 200H48M64 200C64 296 80 328 144 328C208 328 224 296 224 200C224 200 208 184 144 184C80 184 64 200 64 200Z"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
});

export const SpoofingIconMetadata = {
  name: 'Spoofing',
  importName: 'SpoofingIcon',
  component: <SpoofingIcon aria-label="Spoofing Icon" />,
  tags: 'spoofing, glasses, stride, security',
};
