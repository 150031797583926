// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const SunIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 512 512" {...props} ref={ref} fill="none">
      <g clipPath="url(#clip0_569_2)">
        <path
          d="M256 160C203.1 160 160 203.1 160 256C160 308.9 203.1 352 256 352C308.9 352 352 308.9 352 256C352 203.1 308.9 160 256 160ZM502.4 240.5L407.7 193.2L441.2 92.8C445.7 79.2 432.8 66.3 419.3 70.9L318.9 104.4L271.5 9.6C265.1 -3.2 246.9 -3.2 240.5 9.6L193.2 104.3L92.7 70.8C79.1 66.3 66.2 79.2 70.8 92.7L104.3 193.1L9.6 240.5C-3.2 246.9 -3.2 265.1 9.6 271.5L104.3 318.8L70.8 419.3C66.3 432.9 79.2 445.8 92.7 441.2L193.1 407.7L240.4 502.4C246.8 515.2 265 515.2 271.4 502.4L318.7 407.7L419.1 441.2C432.7 445.7 445.6 432.8 441 419.3L407.5 318.9L502.2 271.6C515.2 265.1 515.2 246.9 502.4 240.5ZM346.5 346.5C296.6 396.4 215.4 396.4 165.5 346.5C115.6 296.6 115.6 215.4 165.5 165.5C215.4 115.6 296.6 115.6 346.5 165.5C396.4 215.4 396.4 296.6 346.5 346.5Z"
          fill="currentColor"
        />
      </g>

      <defs>
        <clipPath id="clip0_569_2">
          <rect width="512" height="512" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
});

export const SunIconMetadata = {
  name: 'Sun',
  importName: 'SunIcon',
  component: <SunIcon aria-label="Clover Logo Icon" />,
  tags: 'sun, day, light, sky, space, astronomy, solar, mode, color',
};
