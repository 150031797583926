// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const DensitySmallIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 512 512" {...props} ref={ref} fill="none">
      <path
        d="M96 304H416M96 208H416M96 112H416M96 400H416"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
});

export const DensitySmallIconMetadata = {
  name: 'Density Small',
  importName: 'DensitySmallIcon',
  component: <DensitySmallIcon aria-label="Density Small Icon" />,
  tags: 'density, small, space, room, size, scale, area',
};
