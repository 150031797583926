// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const ColorableCloverLogoIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref} fill="none">
      <path
        d="M5.30032 23.6037C2.68427 23.6037 0.555915 21.4753 0.555915 18.8593C0.555915 17.6239 1.04232 16.6138 2.04216 15.771C2.91075 15.0401 4.01096 14.5627 5.06999 14.1573C5.77129 13.8884 6.68749 13.5551 7.55093 13.3582C9.67671 12.8757 11.1514 10.9378 11.0523 8.7605C11.0098 7.83272 11.0896 6.75181 11.2878 5.54737C11.5413 4.01094 12.1641 2.67525 13.088 1.6857C14.1174 0.582918 15.4454 0 16.9317 0C20.0483 0 22.5845 2.53498 22.5845 5.65289C22.5845 7.10825 22.0029 8.29854 20.8049 9.29066C19.7883 10.1335 18.4565 10.7241 17.18 11.2556C16.1326 11.6918 15.2112 11.9749 14.4404 12.0984C12.4317 12.4201 10.8876 14.0492 10.674 16.0721C10.6611 16.1943 10.6444 16.3204 10.6264 16.4478C10.4874 17.382 10.23 18.324 10.0203 19.0381C9.13497 22.066 7.54835 23.6011 5.30161 23.6011L5.30032 23.6037Z"
        fill="currentColor"
      />

      <path
        d="M18.0228 24C15.1031 24 13.335 21.5718 12.7367 18.7138C12.46 17.391 12.0058 15.1636 12.7971 14.2397C13.7661 13.1073 16.5121 13.3389 18.0228 13.4277C20.9374 13.6001 23.309 15.7941 23.309 18.7138C23.309 21.6336 20.9425 24 18.0228 24Z"
        fill="currentColor"
      />

      <path
        d="M4.46646 11.3534C2.04472 10.8798 0 9.3537 0 6.88691C0 4.42013 1.99968 2.42045 4.46646 2.42045C6.93325 2.42045 8.27409 4.50892 8.93293 6.88691C9.24948 8.03087 9.88515 10.4462 9.1208 11.2479C8.30754 12.101 5.90381 11.6339 4.46646 11.3534Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const ColorableCloverLogoIconMetadata = {
  name: 'Colorable Logo',
  importName: 'ColorableCloverLogoIcon',
  component: <ColorableCloverLogoIcon aria-label="Colorable Clover Logo Icon" />,
  tags: 'clover, logo, brand, company, org, organization, colorable',
};
