// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

// INSIGHT this icon is from Adam
export const DocumentIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 16 16" {...props} ref={ref}>
      <g id="file-outline">
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.58579 1.25253C2.96086 0.877462 3.46957 0.666748 4 0.666748H9.33333C9.51014 0.666748 9.67971 0.736986 9.80474 0.86201L13.8047 4.86201C13.9298 4.98703 14 5.1566 14 5.33341V13.3334C14 13.8638 13.7893 14.3726 13.4142 14.7476C13.0391 15.1227 12.5304 15.3334 12 15.3334H4C3.46957 15.3334 2.96086 15.1227 2.58579 14.7476C2.21071 14.3726 2 13.8638 2 13.3334V2.66675C2 2.13632 2.21071 1.62761 2.58579 1.25253ZM4 2.00008C3.82319 2.00008 3.65362 2.07032 3.5286 2.19534C3.40357 2.32037 3.33333 2.48994 3.33333 2.66675V13.3334C3.33333 13.5102 3.40357 13.6798 3.5286 13.8048C3.65362 13.9298 3.82319 14.0001 4 14.0001H12C12.1768 14.0001 12.3464 13.9298 12.4714 13.8048C12.5964 13.6798 12.6667 13.5102 12.6667 13.3334V5.60956L9.05719 2.00008H4Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.33333 0.666748C9.70152 0.666748 10 0.965225 10 1.33341V4.66675H13.3333C13.7015 4.66675 14 4.96522 14 5.33341C14 5.7016 13.7015 6.00008 13.3333 6.00008H9.33333C8.96514 6.00008 8.66667 5.7016 8.66667 5.33341V1.33341C8.66667 0.965225 8.96514 0.666748 9.33333 0.666748Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.66667 8.66675C4.66667 8.29856 4.96514 8.00008 5.33333 8.00008H10.6667C11.0349 8.00008 11.3333 8.29856 11.3333 8.66675C11.3333 9.03494 11.0349 9.33341 10.6667 9.33341H5.33333C4.96514 9.33341 4.66667 9.03494 4.66667 8.66675Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.66667 11.3334C4.66667 10.9652 4.96514 10.6667 5.33333 10.6667H10.6667C11.0349 10.6667 11.3333 10.9652 11.3333 11.3334C11.3333 11.7016 11.0349 12.0001 10.6667 12.0001H5.33333C4.96514 12.0001 4.66667 11.7016 4.66667 11.3334Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.66667 6.00008C4.66667 5.63189 4.96514 5.33341 5.33333 5.33341H6.66667C7.03486 5.33341 7.33333 5.63189 7.33333 6.00008C7.33333 6.36827 7.03486 6.66675 6.66667 6.66675H5.33333C4.96514 6.66675 4.66667 6.36827 4.66667 6.00008Z"
            fill="currentColor"
          />
        </g>
      </g>
    </Icon>
  );
});

export const DocumentIconMetadata = {
  name: 'Document',
  importName: 'DocumentIcon',
  component: <DocumentIcon aria-label="Document Icon" />,
  tags: 'document, file, paper, sheet, text, note, notes, page, pages, pdf, doc, docx, txt, rtf, odt, md, markdown, html, css, js, ts, json, xml, yaml, yml, csv, xls, xlsx, ppt, pptx, odp, ods, odg, odf, odi, odc, odf, odb, odf',
};
