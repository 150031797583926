// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const JiraStoryIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="#36B37E"
        fillRule="evenodd"
        d="M2,0 L14,0 C15.1045695,-2.02906125e-16 16,0.8954305 16,2 L16,14 C16,15.1045695 15.1045695,16 14,16 L2,16 C0.8954305,16 1.3527075e-16,15.1045695 0,14 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z M8,11 L5.13733333,12.8221936 C4.71733333,13.2020108 4,12.9331243 4,12.3953514 L4,4.20576891 C4,3.5389787 4.596,3 5.33333333,3 L10.6666667,3 C11.4026667,3 12,3.5389787 12,4.20576891 L12,12.3953514 C12,12.9331243 11.2813333,13.2020108 10.8613333,12.8221936 L8,11 Z M8.00025021,8.6290354 L10,9.9025435 L10,5 L6,5 L6,9.90226887 L8.00025021,8.6290354 Z"
      />
    </Icon>
  );
});

export const JiraStoryIconMetadata = {
  name: 'Jira Story',
  importName: 'JiraStoryIcon',
  component: <JiraStoryIcon aria-label="Jira Story Icon" />,
  tags: 'jira, story, issue, type, crm, ticket, atlassian, logo, brand, company, org, organization',
};
