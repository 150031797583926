// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const SearchOffIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 25 25" {...props} ref={ref}>
      <path
        d="M7.29159 22.9167C5.85061 22.9167 4.62214 22.4087 3.60617 21.3927C2.5902 20.3767 2.08256 19.1486 2.08325 17.7083C2.08325 16.2674 2.59124 15.0389 3.60721 14.0229C4.62318 13.0069 5.85131 12.4993 7.29159 12.5C8.73256 12.5 9.96103 13.008 10.977 14.024C11.993 15.0399 12.5006 16.2681 12.4999 17.7083C12.4999 19.1493 11.9919 20.3778 10.976 21.3937C9.95999 22.4097 8.73186 22.9174 7.29159 22.9167ZM21.4583 21.875L14.7916 15.2083C14.5833 14.9826 14.3621 14.7528 14.128 14.5187C13.894 14.2847 13.6638 14.0632 13.4374 13.8542C14.0971 13.4375 14.6267 12.8819 15.026 12.1875C15.4253 11.4931 15.6249 10.7292 15.6249 9.89583C15.6249 8.59375 15.169 7.48681 14.2572 6.575C13.3454 5.66319 12.2388 5.20764 10.9374 5.20833C9.63534 5.20833 8.52839 5.66424 7.61659 6.57604C6.70478 7.48785 6.24922 8.59444 6.24992 9.89583C6.24992 10 6.25443 10.1 6.26346 10.1958C6.27249 10.2917 6.28534 10.3913 6.302 10.4948C5.9895 10.5295 5.64679 10.599 5.27388 10.7031C4.90096 10.8073 4.56659 10.9288 4.27075 11.0677C4.23603 10.8767 4.20999 10.6858 4.19263 10.4948C4.17527 10.3038 4.16659 10.1042 4.16659 9.89583C4.16659 8.00347 4.82214 6.40208 6.13325 5.09167C7.44436 3.78125 9.04575 3.12569 10.9374 3.125C12.8298 3.125 14.4312 3.78056 15.7416 5.09167C17.052 6.40278 17.7076 8.00417 17.7083 9.89583C17.7083 10.6424 17.5909 11.35 17.3562 12.0187C17.1214 12.6875 16.7961 13.2993 16.3801 13.8542L22.9166 20.4167L21.4583 21.875ZM5.44263 20.2865L7.29159 18.4375L9.1145 20.2865L9.86971 19.5573L8.02075 17.7083L9.86971 15.8594L9.14054 15.1302L7.29159 16.9792L5.44263 15.1302L4.71346 15.8594L6.56242 17.7083L4.71346 19.5573L5.44263 20.2865Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const SearchOffIconMetadata = {
  name: 'Search Off',
  importName: 'SearchOffIcon',
  component: <SearchOffIcon aria-label="Search Off Icon" />,
  tags: 'search, off, disable, find, magnifying, glass, look, seek, discover, research, query, investigate, explore, inspect, input',
};
