// React & Next
import type { ReactNode } from 'react';

// 3rd
import { Flex } from '@chakra-ui/react';

// App - Other
import { Text } from '@/components/atoms/typography';

type IllustrationProps = {
  message?: string;
  children: ReactNode;
};

export const Illustration = ({ message, children }: IllustrationProps) => {
  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      gap="32px"
      w="100%"
      mt="48px"
    >
      {children}

      {message ? <Text variant="h3">{message}</Text> : null}
    </Flex>
  );
};
