// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const ArrowRightFilledIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 17 17" {...props} ref={ref}>
      <g id="AiFillCaretDown">
        <path
          id="Vector"
          d="M5.10449 3.44079L5.10449 13.7033C5.10449 14.0111 5.42949 14.183 5.65137 13.9924L11.6014 8.8611C11.7717 8.71423 11.7717 8.43142 11.6014 8.28298L5.65137 3.15173C5.42949 2.9611 5.10449 3.13298 5.10449 3.44079Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
});

export const ArrowRightFilledIconMetadata = {
  name: 'Arrow Right (filled)',
  importName: 'ArrowRightFilledIcon',
  component: <ArrowRightFilledIcon aria-label="Arrow Right Filled Icon" />,
  tags: 'right, forward, next, filled, arrow, direction, navigate, navigation, point, move, indicator, go',
};
