// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const CodeIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 512 512" {...props} ref={ref} fill="none">
      <path
        d="M160 389C154.914 389.005 150.001 387.157 146.18 383.8L18.1804 271.8C15.9291 269.829 14.1251 267.399 12.8891 264.674C11.6531 261.949 11.0137 258.992 11.0137 256C11.0137 253.007 11.6531 250.05 12.8891 247.325C14.1251 244.6 15.9291 242.171 18.1804 240.2L146.18 128.2C150.372 124.532 155.849 122.679 161.407 123.049C166.965 123.42 172.147 125.983 175.815 130.175C179.483 134.366 181.336 139.843 180.965 145.401C180.595 150.959 178.032 156.142 173.84 159.81L63.8904 256L173.83 352.19C177.048 355.003 179.331 358.731 180.374 362.875C181.417 367.02 181.17 371.385 179.667 375.385C178.164 379.386 175.475 382.833 171.961 385.266C168.447 387.699 164.274 389.001 160 389ZM352 389C347.726 389.003 343.551 387.702 340.036 385.27C336.52 382.838 333.83 379.391 332.326 375.39C330.821 371.389 330.574 367.024 331.616 362.878C332.659 358.732 334.942 355.003 338.16 352.19L448.11 256L338.17 159.81C333.979 156.142 331.416 150.959 331.045 145.401C330.675 139.843 332.527 134.366 336.195 130.175C339.863 125.983 345.046 123.42 350.604 123.049C356.161 122.679 361.639 124.532 365.83 128.2L493.83 240.2C496.082 242.171 497.886 244.6 499.122 247.325C500.358 250.05 500.997 253.007 500.997 256C500.997 258.992 500.358 261.949 499.122 264.674C497.886 267.399 496.082 269.829 493.83 271.8L365.83 383.8C362.007 387.159 357.09 389.008 352 389Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const CodeIconMetadata = {
  name: 'Code',
  importName: 'CodeIcon',
  component: <CodeIcon aria-label="Code Icon" />,
  tags: 'code, programming, application',
};
