// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

// INSIGHT this icon is from Adam
export const AuthorizationIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <g id="id-card-outline">
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.43411 8.88372C5.43411 7.14124 6.84667 5.72868 8.58915 5.72868C10.3316 5.72868 11.7442 7.14124 11.7442 8.88372C11.7442 10.6262 10.3316 12.0388 8.58915 12.0388C6.84667 12.0388 5.43411 10.6262 5.43411 8.88372ZM8.58915 7.26357C7.69436 7.26357 6.96899 7.98893 6.96899 8.88372C6.96899 9.77851 7.69436 10.5039 8.58915 10.5039C9.48393 10.5039 10.2093 9.77851 10.2093 8.88372C10.2093 7.98893 9.48393 7.26357 8.58915 7.26357Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.35255 15.2791H11.8257C11.4793 13.8124 10.1617 12.7209 8.58915 12.7209C7.01662 12.7209 5.69901 13.8124 5.35255 15.2791ZM3.72868 16.0465C3.72868 13.3622 5.90479 11.186 8.58915 11.186C11.2735 11.186 13.4496 13.3622 13.4496 16.0465C13.4496 16.2501 13.3688 16.4453 13.2248 16.5892C13.0809 16.7331 12.8857 16.814 12.6822 16.814H4.49612C4.29259 16.814 4.09738 16.7331 3.95346 16.5892C3.80954 16.4453 3.72868 16.2501 3.72868 16.0465Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.2791 9.22481C13.2791 8.80096 13.6227 8.45736 14.0465 8.45736H18.1395C18.5634 8.45736 18.907 8.80096 18.907 9.22481C18.907 9.64865 18.5634 9.99225 18.1395 9.99225H14.0465C13.6227 9.99225 13.2791 9.64865 13.2791 9.22481Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.2791 11.9535C13.2791 11.5296 13.6227 11.186 14.0465 11.186H19.5039C19.9277 11.186 20.2713 11.5296 20.2713 11.9535C20.2713 12.3773 19.9277 12.7209 19.5039 12.7209H14.0465C13.6227 12.7209 13.2791 12.3773 13.2791 11.9535Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 5.13178C1 3.95443 1.95443 3 3.13178 3H20.8682C22.0456 3 23 3.95443 23 5.13178V18.7752C23 19.9525 22.0456 20.907 20.8682 20.907H18.1395C17.7157 20.907 17.3721 20.5634 17.3721 20.1395C17.3721 19.8099 17.1049 19.5426 16.7752 19.5426C16.4455 19.5426 16.1783 19.8099 16.1783 20.1395C16.1783 20.5634 15.8347 20.907 15.4109 20.907H8.58915C8.1653 20.907 7.82171 20.5634 7.82171 20.1395C7.82171 19.8099 7.55446 19.5426 7.22481 19.5426C6.89515 19.5426 6.62791 19.8099 6.62791 20.1395C6.62791 20.5634 6.28431 20.907 5.86047 20.907H3.13178C1.95443 20.907 1 19.9525 1 18.7752V5.13178ZM3.13178 4.53488C2.80212 4.53488 2.53488 4.80212 2.53488 5.13178V18.7752C2.53488 19.1049 2.80212 19.3721 3.13178 19.3721H5.23534C5.54343 18.5739 6.318 18.0078 7.22481 18.0078C8.13161 18.0078 8.90618 18.5739 9.21427 19.3721H14.7857C15.0938 18.5739 15.8684 18.0078 16.7752 18.0078C17.682 18.0078 18.4566 18.5739 18.7647 19.3721H20.8682C21.1979 19.3721 21.4651 19.1049 21.4651 18.7752V5.13178C21.4651 4.80212 21.1979 4.53488 20.8682 4.53488H3.13178Z"
            fill="currentColor"
          />
        </g>
      </g>
    </Icon>
  );
});

export const AuthorizationIconMetadata = {
  name: 'Authorization',
  importName: 'AuthorizationIcon',
  component: <AuthorizationIcon aria-label="Authorization Icon" />,
  tags: 'authorization, auth, permission, access, control, security, verify, lock, identity, user, secure, grant',
};
