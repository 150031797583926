// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const ReloadIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 512 512" {...props} ref={ref} fill="none">
      <path
        d="M400 148L378.88 123.43C360.973 104.622 339.426 89.6536 315.55 79.4366C291.674 69.2197 265.97 63.9676 240 64C134 64 48 150 48 256C48 362 134 448 240 448C279.71 447.997 318.443 435.688 350.87 412.765C383.296 389.843 407.821 357.435 421.07 320"
        stroke="currentColor"
        strokeWidth="32"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />

      <path
        d="M464 97.42V208C464 212.243 462.314 216.313 459.314 219.314C456.313 222.314 452.244 224 448 224H337.42C323.16 224 316.02 206.77 326.1 196.69L436.69 86.1C446.77 76 464 83.16 464 97.42Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const ReloadIconMetadata = {
  name: 'Reload',
  importName: 'ReloadIcon',
  component: <ReloadIcon aria-label="Reload Icon" />,
  tags: 'reload, restart, reset, refresh, recycle, loop, repeat, sync',
};
