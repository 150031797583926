// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const MoonIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 512 512" {...props} ref={ref} fill="none">
      <path
        d="M283.211 512C362.173 512 434.29 476.075 482.068 417.208C489.136 408.5 481.429 395.778 470.506 397.858C346.303 421.512 232.244 326.282 232.244 200.904C232.244 128.682 270.906 62.269 333.742 26.51C343.428 20.998 340.992 6.313 329.986 4.28C314.557 1.43493 298.9 0.00233634 283.211 0C141.902 0 27.2109 114.511 27.2109 256C27.2109 397.309 141.722 512 283.211 512Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const MoonIconMetadata = {
  name: 'Moon',
  importName: 'MoonIcon',
  component: <MoonIcon aria-label="Clover Logo Icon" />,
  tags: 'moon, night, dark, sky, space, astronomy, lunar, mode, color',
};
