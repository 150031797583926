// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const AzureDevOpsRepositoryIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 125 125" {...props} ref={ref} fill="none">
      <path
        d="M125 23.4107V99.4107L93.75 125L45.3125 107.366V124.848L17.8929 89.0179L97.8125 95.2679V26.8304L125 23.4107ZM98.3571 27.2321L53.5179 0V17.8661L12.3393 29.9643L0 45.8036V81.7768L17.6607 89.5714V43.4732L98.3571 27.2411V27.2321Z"
        fill="#E15815"
      />

      <path
        d="M91.8396 43.4116H61.8429C60.686 43.4116 59.7557 44.3419 59.7557 45.4988V52.333H93.9269V45.4988C93.9269 44.3419 92.9965 43.4116 91.8396 43.4116Z"
        fill="#BC3200"
      />

      <path
        d="M95.3719 47.5858H58.3144C56.6446 47.5858 55.2969 48.9336 55.2969 50.6034V52.9769H98.3895V50.6034C98.3895 48.9336 97.0418 47.5858 95.3719 47.5858Z"
        fill="#D13600"
      />

      <path
        d="M99.7135 51.8683H53.9731C52.3033 51.8683 50.9556 53.2161 50.9556 54.8859V97.8473C50.9556 99.5171 52.3153 100.877 53.985 100.877H99.7015C101.371 100.877 102.731 99.5171 102.731 97.8473V54.8859C102.731 53.2161 101.383 51.8683 99.7135 51.8683Z"
        fill="#E15815"
      />

      <path
        d="M66.2336 56.292C63.061 56.292 60.4847 58.8682 60.4847 62.0407C60.4847 65.2134 63.061 67.7896 66.2336 67.7896C69.4063 67.7896 71.9825 65.2134 71.9825 62.0407C71.9825 58.8682 69.4063 56.292 66.2336 56.292ZM66.2336 65.7621C64.1702 65.7621 62.5005 64.0922 62.5005 62.0288C62.5005 59.9655 64.1702 58.2957 66.2336 58.2957C68.2971 58.2957 69.9668 59.9655 69.9668 62.0288C69.9668 64.0922 68.2971 65.7621 66.2336 65.7621Z"
        fill="#FFB290"
      />

      <path d="M68.3926 67.171H64.1346V78.8237H68.3926V67.171Z" fill="#FFB290" />

      <path
        d="M93.537 62.1255C93.537 58.9529 90.9607 56.3766 87.7881 56.3766C84.6156 56.3766 82.0393 58.9529 82.0393 62.1255C82.0393 64.5705 83.566 66.6578 85.7248 67.4926V71.4405L64.1367 78.5729H64.1009V85.8842C61.9898 86.731 60.4989 88.8064 60.4989 91.2156C60.4989 94.3883 63.0752 96.9645 66.2478 96.9645C69.4204 96.9645 71.9967 94.3883 71.9967 91.2156C71.9967 88.8302 70.5415 86.7787 68.4782 85.908V81.3758L89.9469 74.2791L89.8992 74.1957H89.9469V67.4449C92.0461 66.5981 93.537 64.5348 93.537 62.1255ZM69.969 91.2276C69.969 93.291 68.2993 94.9607 66.2359 94.9607C64.1724 94.9607 62.5027 93.291 62.5027 91.2276C62.5027 89.1641 64.1724 87.4944 66.2359 87.4944C68.2993 87.4944 69.969 89.1641 69.969 91.2276ZM87.7881 65.8467C85.7248 65.8467 84.055 64.1769 84.055 62.1135C84.055 60.0502 85.7248 58.3803 87.7881 58.3803C89.8515 58.3803 91.5213 60.0502 91.5213 62.1135C91.5213 64.1769 89.8396 65.8467 87.7881 65.8467Z"
        fill="#FFDBCA"
      />
    </Icon>
  );
});

export const AzureDevOpsRepositoryIconMetadata = {
  name: 'Azure DevOps Repository',
  importName: 'AzureDevOpsRepositoryIcon',
  component: <AzureDevOpsRepositoryIcon aria-label="Azure DevOps Repository Icon" />,
  tags: 'azure, devops, code, repo, repository, cat, microsoft, logo, brand, company, org, organization, version, control, vc',
};
