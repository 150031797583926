// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const DataExchangeIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <path
        d="M18 15.75V17.25H19.311C18.4793 18.4123 17.3818 19.359 16.1101 20.0113C14.8384 20.6636 13.4292 21.0026 12 21C7.03725 21 3 16.9628 3 12H1.5C1.5 17.79 6.21 22.5 12 22.5C15.2663 22.5 18.2805 20.9993 20.25 18.4913V19.5H21.75V15.75H18Z"
        fill="currentColor"
      />

      <path
        d="M16.8787 8.72784L12.387 6.10284C12.2723 6.03578 12.1419 6.00038 12.0091 6.00024C11.8763 6.00011 11.7458 6.03526 11.631 6.10209L7.12275 8.72709C7.00938 8.79307 6.91529 8.88764 6.84989 9.00134C6.78448 9.11505 6.75004 9.24392 6.75 9.37509V14.6251C6.75004 14.7563 6.78448 14.8851 6.84989 14.9988C6.91529 15.1125 7.00938 15.2071 7.12275 15.2731L11.631 17.8981C11.7425 17.9648 11.87 18 12 18.0001C12.1305 18.0001 12.27 17.9663 12.387 17.8973L16.8787 15.2723C16.9917 15.2062 17.0854 15.1117 17.1506 14.9982C17.2157 14.8846 17.25 14.756 17.25 14.6251V9.37509C17.25 9.24419 17.2157 9.11557 17.1506 9.00202C17.0854 8.88847 16.9917 8.79395 16.8787 8.72784ZM12.0082 7.61784L15.0135 9.37509L12.0082 11.1316L8.991 9.37509L12.0082 7.61784ZM8.25 10.6801L11.25 12.4276V15.9413L8.25 14.1938V10.6801ZM12.75 15.9488V12.4366L15.75 10.6831V14.1953L12.75 15.9488Z"
        fill="currentColor"
      />

      <path
        d="M12 1.50001C10.4107 1.49771 8.84176 1.85752 7.41228 2.55212C5.9828 3.24671 4.73035 4.25785 3.75 5.50876V4.50001H2.25V8.25001H6V6.75001H4.689C5.52073 5.5877 6.61816 4.64097 7.88988 3.98867C9.16159 3.33638 10.5708 2.99741 12 3.00001C16.9628 3.00001 21 7.03726 21 12H22.5C22.5 6.21001 17.79 1.50001 12 1.50001Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const DataExchangeIconMetadata = {
  name: 'Data Exchange',
  importName: 'DataExchangeIcon',
  component: <DataExchangeIcon aria-label="Data Exchange Icon" />,
  tags: 'data, exchange, change, transfer, transform, convert, move, shift, switch, switch-over, switch-around, switch-out, switch-up, switch-upon, switch-off, switch-on',
};
