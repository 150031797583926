// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const LayersIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 512 512" {...props} ref={ref} fill="none">
      <path
        d="M160 308.52L77.3001 345.63C59.7001 353.63 59.7001 366.73 77.3001 374.73L225.3 442.23C242.19 449.92 269.99 449.92 286.88 442.23L434.88 374.73C452.48 366.73 452.48 353.63 434.88 345.63L354.94 307.16M434.8 137.65L285.44 69.55C269.25 62.15 242.75 62.15 226.56 69.55L77.3001 137.65C59.7001 145.65 59.7001 158.74 77.3001 166.74L225.3 234.24C242.19 241.94 269.99 241.94 286.88 234.24L434.88 166.74C452.4 158.74 452.4 145.64 434.8 137.65Z"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M160 204.48L77.2 241.64C59.6 249.64 59.6 262.74 77.2 270.74L225.2 338.23C242.09 345.93 269.89 345.93 286.78 338.23L434.78 270.74C452.48 262.74 452.48 249.64 434.88 241.64L352 204.48"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
});

export const LayersIconMetadata = {
  name: 'Layers',
  importName: 'LayersIcon',
  component: <LayersIcon aria-label="Layers Icon" />,
  tags: 'layers, stack, stackable, dynamic, order, arrange, organize, group, categorize',
};
