// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const ChevronRightIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <path fill="currentColor" d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
    </Icon>
  );
});

export const ChevronRightIconMetadata = {
  name: 'Chevron Right',
  importName: 'ChevronRightIcon',
  component: <ChevronRightIcon aria-label="Chevron Right Icon" />,
  tags: 'chevron, right, arrow, next, forward, navigate, go, move, direction, swipe, slide, scroll, carousel, slider, menu, list, item, option, select, choose',
};
