// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const FirstPageIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <path
        d="M6 18V6H8V18H6ZM17 18L11 12L17 6L18.4 7.4L13.8 12L18.4 16.6L17 18Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const FirstPageIconMetadata = {
  name: 'First Page',
  importName: 'FirstPageIcon',
  component: <FirstPageIcon aria-label="First Page Icon" />,
  tags: 'first, page, arrow, left, start, beginning, table, pagination',
};
