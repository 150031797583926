export * from './error-illustration';
export * from './error-old-illustration';
export * from './error-404-illustration';
export * from './empty-state-illustration';
export * from './warning-illustration';
export * from './empty-illustration';
export * from './completed-illustration';
export * from './wrong-illustration';
export * from './no-results-illustration';
export * from './fresh-start-illustration';
