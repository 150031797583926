// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const BulbIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 16 16" {...props} ref={ref}>
      <g id="bulb-outline">
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.21768 0.666504C8.5483 0.666504 8.81632 0.934524 8.81632 1.26514V2.1631C8.81632 2.49372 8.5483 2.76174 8.21768 2.76174C7.88706 2.76174 7.61904 2.49372 7.61904 2.1631V1.26514C7.61904 0.934524 7.88706 0.666504 8.21768 0.666504Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.0859 2.68326C13.3197 2.91704 13.3197 3.29608 13.0859 3.52986L12.4507 4.16502C12.2169 4.3988 11.8379 4.3988 11.6041 4.16502C11.3703 3.93123 11.3703 3.5522 11.6041 3.31841L12.2393 2.68326C12.4731 2.44947 12.8521 2.44947 13.0859 2.68326Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.0068 7.55086C13.0068 7.22024 13.2748 6.95222 13.6054 6.95222H14.5034C14.834 6.95222 15.102 7.22024 15.102 7.55086C15.102 7.88148 14.834 8.1495 14.5034 8.1495H13.6054C13.2748 8.1495 13.0068 7.88148 13.0068 7.55086Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.6041 10.9373C11.8379 10.7035 12.2169 10.7035 12.4507 10.9373L13.0859 11.5725C13.3197 11.8062 13.3197 12.1853 13.0859 12.4191C12.8521 12.6528 12.4731 12.6528 12.2393 12.4191L11.6041 11.7839C11.3703 11.5501 11.3703 11.1711 11.6041 10.9373Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.34948 2.68326C3.58327 2.44947 3.9623 2.44947 4.19609 2.68326L4.83124 3.31841C5.06503 3.5522 5.06503 3.93123 4.83124 4.16502C4.59746 4.3988 4.21842 4.3988 3.98464 4.16502L3.34948 3.52986C3.1157 3.29608 3.1157 2.91704 3.34948 2.68326Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.33333 7.55086C1.33333 7.22024 1.60135 6.95222 1.93197 6.95222H2.82993C3.16055 6.95222 3.42857 7.22024 3.42857 7.55086C3.42857 7.88148 3.16055 8.1495 2.82993 8.1495H1.93197C1.60135 8.1495 1.33333 7.88148 1.33333 7.55086Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.83124 10.9373C5.06503 11.1711 5.06503 11.5501 4.83124 11.7839L4.19609 12.4191C3.9623 12.6528 3.58327 12.6528 3.34948 12.4191C3.1157 12.1853 3.1157 11.8062 3.34948 11.5725L3.98464 10.9373C4.21842 10.7035 4.59746 10.7035 4.83124 10.9373Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.42176 10.0639C6.75238 10.0639 7.0204 10.332 7.0204 10.6626V12.0395C7.0204 12.7007 7.55644 13.2367 8.21768 13.2367C8.87892 13.2367 9.41496 12.7007 9.41496 12.0395V10.6626C9.41496 10.332 9.68298 10.0639 10.0136 10.0639C10.3442 10.0639 10.6122 10.332 10.6122 10.6626V12.0395C10.6122 13.3619 9.54016 14.434 8.21768 14.434C6.8952 14.434 5.82312 13.3619 5.82312 12.0395V10.6626C5.82312 10.332 6.09114 10.0639 6.42176 10.0639Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.02721 7.55086C4.02721 5.23652 5.90335 3.36038 8.21768 3.36038C10.532 3.36038 12.4082 5.23652 12.4082 7.55086C12.4082 9.86519 10.532 11.7413 8.21768 11.7413C5.90335 11.7413 4.02721 9.86519 4.02721 7.55086ZM8.21768 4.55766C6.56458 4.55766 5.22448 5.89776 5.22448 7.55086C5.22448 9.20396 6.56458 10.5441 8.21768 10.5441C9.87078 10.5441 11.2109 9.20396 11.2109 7.55086C11.2109 5.89776 9.87078 4.55766 8.21768 4.55766Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.21768 13.2379C8.5483 13.2379 8.81632 13.506 8.81632 13.8366V14.7345C8.81632 15.0652 8.5483 15.3332 8.21768 15.3332C7.88706 15.3332 7.61904 15.0652 7.61904 14.7345V13.8366C7.61904 13.506 7.88706 13.2379 8.21768 13.2379Z"
            fill="currentColor"
          />
        </g>
      </g>
    </Icon>
  );
});

export const BulbIconMetadata = {
  name: 'Bulb',
  importName: 'BulbIcon',
  component: <BulbIcon aria-label="Bulb Icon" />,
  tags: 'bulb, idea, light, innovation, inspiration, energy, creativity, creative',
};
