// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const ClearAllIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <path
        d="M16.0999 12.8L8.24992 4.90002L10.7749 2.95002C11.1416 2.66669 11.5499 2.52502 11.9999 2.52502C12.4499 2.52502 12.8582 2.66669 13.2249 2.95002L18.9749 7.42502C19.4916 7.82502 19.7499 8.35002 19.7499 9.00002C19.7499 9.65002 19.4916 10.175 18.9749 10.575L16.0999 12.8ZM18.9749 15.65L17.5249 14.2L18.7499 13.275C18.9332 13.1417 19.1376 13.075 19.3629 13.075C19.5883 13.075 19.7922 13.1417 19.9749 13.275C20.2416 13.475 20.3749 13.7374 20.3749 14.062C20.3749 14.3867 20.2416 14.6494 19.9749 14.85L18.9749 15.65ZM19.0999 21.4L15.7999 18.1L13.2249 20.1C12.8582 20.3834 12.4499 20.525 11.9999 20.525C11.5499 20.525 11.1416 20.3834 10.7749 20.1L4.02492 14.85C3.75825 14.65 3.62892 14.3877 3.63692 14.063C3.64492 13.7384 3.78258 13.4757 4.04992 13.275C4.23325 13.1417 4.43325 13.075 4.64992 13.075C4.86658 13.075 5.06658 13.1417 5.24992 13.275L11.9999 18.5L14.3499 16.675L12.5999 14.975H13.3249L13.2249 15.025C12.8582 15.3084 12.4499 15.4544 11.9999 15.463C11.5499 15.4717 11.1416 15.334 10.7749 15.05L5.02492 10.575C4.50825 10.175 4.24992 9.65002 4.24992 9.00002C4.24992 8.35002 4.50825 7.82502 5.02492 7.42502L5.07491 7.37502L2.09992 4.42502C1.89992 4.22502 1.79558 3.98736 1.78692 3.71202C1.77825 3.43669 1.87425 3.19936 2.07492 3.00002C2.27492 2.80002 2.51258 2.70002 2.78792 2.70002C3.06325 2.70002 3.30058 2.80002 3.49992 3.00002L20.4999 20C20.6832 20.1834 20.7749 20.4167 20.7749 20.7C20.7749 20.9834 20.6832 21.2167 20.4999 21.4C20.3166 21.5834 20.0832 21.675 19.7999 21.675C19.5166 21.675 19.2832 21.5834 19.0999 21.4Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const ClearAllIconMetadata = {
  name: 'Clear All',
  importName: 'ClearAllIcon',
  component: <ClearAllIcon aria-label="Clear All Icon" />,
  tags: 'clear, all, remove, delete, cancel, x, close',
};
