// React & Next
import { useLayoutEffect, useState, useRef, useEffect } from 'react';
import type { ForwardedRef } from 'react';

export const useIsOverflowing = (ref?: ForwardedRef<HTMLDivElement>) => {
  const _ref = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useLayoutEffect(() => {
    const checkOverflow = () => {
      if (_ref.current) {
        const { scrollWidth, offsetWidth } = _ref.current;

        setIsOverflowing(scrollWidth > offsetWidth);
      }
    };

    checkOverflow();

    // window.addEventListener('resize', checkOverflow);
    //
    // return () => window.removeEventListener('resize', checkOverflow);
  });

  useEffect(() => {
    if (!ref) return;

    if (typeof ref === 'function') {
      ref(_ref.current);
    } else {
      ref.current = _ref.current;
    }
  });

  return { ref: _ref, isOverflowing };
};
