// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const ChevronDownIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <path fill="currentColor" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
    </Icon>
  );
});

export const ChevronDownIconMetadata = {
  name: 'Chevron Down',
  importName: 'ChevronDownIcon',
  component: <ChevronDownIcon aria-label="Chevron Down Icon" />,
  tags: 'chevron, down, arrow, expand, collapse, dropdown, select, open, close, show, hide',
};
