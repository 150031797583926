// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const ServerIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 512 512" {...props} fill="none" ref={ref}>
      <path
        d="M256 192C353.202 192 432 156.183 432 112C432 67.8172 353.202 32 256 32C158.798 32 80 67.8172 80 112C80 156.183 158.798 192 256 192Z"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M432 112V400C432 444.183 353.202 480 256 480C158.798 480 80 444.183 80 400V112"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M432 256C432 300.183 353.202 336 256 336C158.798 336 80 300.183 80 256"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
});

export const ServerIconMetadata = {
  name: 'Server',
  importName: 'ServerIcon',
  component: <ServerIcon aria-label="Server Icon" />,
  tags: 'server, data, software, network, database, storage, cloud, computing, hosting, serverless, backend, infrastructure',
};
