// 3rd
import { forwardRef, Icon as ChakraIcon } from '@chakra-ui/react';
import type { IconProps as ChakraIconProps } from '@chakra-ui/react';

export type IconProps = ChakraIconProps & {
  size?: '5xs' | '4xs' | '3xs' | '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | number;
  'aria-label': string;
};

const iconSizes = {
  '5xs': 6,
  '4xs': 8,
  '3xs': 10,
  '2xs': 12,
  xs: 16,
  sm: 20,
  md: 24,
  lg: 28,
  xl: 32,
  '2xl': 36,
  '3xl': 40,
};

export const Icon = forwardRef(({ size, style, ...props }: IconProps, ref) => {
  const sizeInPx = `${iconSizes[size as '5xs' | '4xs' | '3xs' | '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'] || size || iconSizes['md']}px`;
  const dimensions = size
    ? { style: { height: sizeInPx, width: sizeInPx } }
    : { height: sizeInPx, width: sizeInPx };
  const styles = { ...(style || {}), ...(dimensions?.style || {}) };

  return <ChakraIcon {...dimensions} style={styles} {...props} ref={ref} />;
});
