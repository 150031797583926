// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const DeleteIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 12 12" {...props} ref={ref}>
      <g>
        <path
          d="M7.65505 4.34766C7.49983 4.34766 7.37402 4.47346 7.37402 4.62869V9.94015C7.37402 10.0953 7.49983 10.2212 7.65505 10.2212C7.81028 10.2212 7.93608 10.0953 7.93608 9.94015V4.62869C7.93608 4.47346 7.81028 4.34766 7.65505 4.34766Z"
          fill="currentColor"
        />

        <path
          d="M4.33914 4.34766C4.18391 4.34766 4.05811 4.47346 4.05811 4.62869V9.94015C4.05811 10.0953 4.18391 10.2212 4.33914 10.2212C4.49436 10.2212 4.62016 10.0953 4.62016 9.94015V4.62869C4.62016 4.47346 4.49436 4.34766 4.33914 4.34766Z"
          fill="currentColor"
        />

        <path
          d="M1.92219 3.57252V10.4965C1.92219 10.9057 2.07226 11.2901 2.33441 11.5658C2.59535 11.8424 2.95849 11.9993 3.33854 12H8.65571C9.03586 11.9993 9.39901 11.8424 9.65984 11.5658C9.92199 11.2901 10.0721 10.9057 10.0721 10.4965V3.57252C10.5932 3.4342 10.9308 2.93076 10.8611 2.39603C10.7913 1.86142 10.3358 1.4615 9.79662 1.46139H8.35777V1.1101C8.35942 0.814693 8.24261 0.531029 8.03349 0.322343C7.82436 0.113766 7.54026 -0.00237817 7.24485 3.6925e-05H4.74939C4.45398 -0.00237817 4.16988 0.113766 3.96076 0.322343C3.75163 0.531029 3.63483 0.814693 3.63647 1.1101V1.46139H2.19762C1.6584 1.4615 1.20293 1.86142 1.13311 2.39603C1.06341 2.93076 1.40108 3.4342 1.92219 3.57252ZM8.65571 11.4379H3.33854C2.85804 11.4379 2.48425 11.0252 2.48425 10.4965V3.59722H9.50999V10.4965C9.50999 11.0252 9.1362 11.4379 8.65571 11.4379ZM4.19853 1.1101C4.19667 0.963771 4.25419 0.822927 4.35804 0.719626C4.46178 0.616326 4.60295 0.559571 4.74939 0.562096H7.24485C7.39129 0.559571 7.53247 0.616326 7.63621 0.719626C7.74005 0.822817 7.79758 0.963771 7.79571 1.1101V1.46139H4.19853V1.1101ZM2.19762 2.02345H9.79662C10.076 2.02345 10.3025 2.24992 10.3025 2.5293C10.3025 2.80869 10.076 3.03516 9.79662 3.03516H2.19762C1.91824 3.03516 1.69177 2.80869 1.69177 2.5293C1.69177 2.24992 1.91824 2.02345 2.19762 2.02345Z"
          fill="currentColor"
        />

        <path
          d="M5.99734 4.34766C5.84211 4.34766 5.71631 4.47346 5.71631 4.62869V9.94015C5.71631 10.0953 5.84211 10.2212 5.99734 10.2212C6.15256 10.2212 6.27837 10.0953 6.27837 9.94015V4.62869C6.27837 4.47346 6.15256 4.34766 5.99734 4.34766Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
});

export const DeleteIconMetadata = {
  name: 'Delete',
  importName: 'DeleteIcon',
  component: <DeleteIcon aria-label="Delete Icon" />,
  tags: 'delete, remove, trash, bin, garbage',
};
