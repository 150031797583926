// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const ClassificationsIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <path
        d="M12 1L18 11H6L12 1ZM13 13.5H21V21.5H13V13.5ZM6.75 22C8.00978 22 9.21796 21.4996 10.1088 20.6088C10.9996 19.718 11.5 18.5098 11.5 17.25C11.5 15.9902 10.9996 14.782 10.1088 13.8912C9.21796 13.0004 8.00978 12.5 6.75 12.5C5.49022 12.5 4.28204 13.0004 3.39124 13.8912C2.50044 14.782 2 15.9902 2 17.25C2 18.5098 2.50044 19.718 3.39124 20.6088C4.28204 21.4996 5.49022 22 6.75 22Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const ClassificationsIconMetadata = {
  name: 'Classifications',
  importName: 'ClassificationsIcon',
  component: <ClassificationsIcon aria-label="Classifications Icon" />,
  tags: 'classifications, classify, labels, tags, shapes, triangle, circle, square',
};
