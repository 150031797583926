import { v4 } from 'uuid';

export const uuid4 = () => {
  return v4();
};

export const randomDate = (start: Date, end: Date): Date => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
};

export const randomItemFromList = <T>(list: T[]) => {
  return list[Math.floor(Math.random() * list.length)];
};

export const randomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and
};
