// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const SecurityAnalysisIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.8 8.39581e-08C3.95131 8.39581e-08 3.13737 0.337142 2.53726 0.937258C1.93714 1.53737 1.6 2.35131 1.6 3.2V8.4C1.6 8.71826 1.72643 9.02349 1.95147 9.24853C2.17652 9.47357 2.48174 9.6 2.8 9.6C3.11826 9.6 3.42348 9.47357 3.64853 9.24853C3.87357 9.02349 4 8.71826 4 8.4V3.2C4 2.98783 4.08429 2.78434 4.23431 2.63431C4.38434 2.48429 4.58783 2.4 4.8 2.4H14.4C14.6122 2.4 14.8157 2.48429 14.9657 2.63431C15.1157 2.78434 15.2 2.98783 15.2 3.2V19.5744C15.1951 19.8467 15.2826 20.1126 15.4482 20.3288C15.6138 20.545 15.8478 20.6987 16.112 20.7648C16.3766 20.8296 16.6554 20.8027 16.9028 20.6885C17.1501 20.5743 17.3514 20.3795 17.4736 20.136L23.3936 8.8608C23.5651 8.53463 23.6704 8.17781 23.7037 7.81083C23.737 7.44384 23.6975 7.0739 23.5875 6.7222C23.4775 6.37051 23.2992 6.04399 23.0627 5.76136C22.8263 5.47873 22.5363 5.24556 22.2096 5.0752L17.5536 2.6528C17.4247 1.90997 17.0376 1.23652 16.4607 0.751189C15.8837 0.265861 15.1539 -0.000172542 14.4 8.39581e-08H4.8ZM17.6 5.3824V14.7328L21.2688 7.7456C21.2933 7.69898 21.3083 7.64798 21.313 7.59553C21.3177 7.54309 21.3121 7.49022 21.2963 7.43998C21.2806 7.38973 21.255 7.34309 21.2212 7.30274C21.1874 7.26238 21.1459 7.2291 21.0992 7.2048L17.6 5.3824ZM9.9472 19.4496C10.3411 18.6948 10.4839 17.834 10.3548 16.9925C10.2258 16.1509 9.83153 15.3725 9.2295 14.7705C8.62748 14.1685 7.84907 13.7742 7.00752 13.6452C6.16597 13.5161 5.30518 13.6589 4.5504 14.0528L9.9472 19.4496ZM8.2512 21.1472C7.49642 21.5411 6.63563 21.6839 5.79408 21.5548C4.95253 21.4258 4.17412 21.0315 3.5721 20.4295C2.97007 19.8275 2.57584 19.0491 2.44676 18.2075C2.31768 17.366 2.46049 16.5052 2.8544 15.7504L8.2512 21.1472ZM6.4 24C8.09739 24 9.72525 23.3257 10.9255 22.1255C12.1257 20.9253 12.8 19.2974 12.8 17.6C12.8 15.9026 12.1257 14.2747 10.9255 13.0745C9.72525 11.8743 8.09739 11.2 6.4 11.2C4.70261 11.2 3.07475 11.8743 1.87452 13.0745C0.674284 14.2747 0 15.9026 0 17.6C0 19.2974 0.674284 20.9253 1.87452 22.1255C3.07475 23.3257 4.70261 24 6.4 24Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const SecurityAnalysisIconMetadata = {
  name: 'Security Analysis',
  importName: 'SecurityAnalysisIcon',
  component: <SecurityAnalysisIcon aria-label="Security Analysis Icon" />,
  tags: 'security, analysis, engine, process, data, threats, risks, vulnerabilities, protection, secure',
};
