// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const ArrowLeftIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 16 17" {...props} ref={ref}>
      <path
        d="M11.6733 3.07999L10.4866 1.89999L3.89331 8.49999L10.4933 15.1L11.6733 13.92L6.25331 8.49999L11.6733 3.07999Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const ArrowLeftIconMetadata = {
  name: 'Arrow Left',
  importName: 'ArrowLeftIcon',
  component: <ArrowLeftIcon aria-label="Arrow Left Icon" />,
  tags: 'arrow, left, back, previous, return, backward, reverse',
};
