// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const BellIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 32 32" {...props} ref={ref}>
      <g clipPath="url(#clip0_264_225)">
        <path
          d="M18.98 25.3333C20.1333 25.3333 20.7426 26.6987 19.9733 27.5573C19.4735 28.1166 18.8612 28.5639 18.1765 28.87C17.4917 29.1761 16.75 29.334 16 29.3333C15.2499 29.334 14.5082 29.1761 13.8235 28.87C13.1387 28.5639 12.5264 28.1166 12.0266 27.5573C11.2906 26.736 11.816 25.452 12.872 25.3427L13.0186 25.3347L18.98 25.3333ZM16 2.66666C17.8106 2.66666 19.3413 3.87066 19.8333 5.52132L19.8946 5.74932L19.9053 5.80666C21.3752 6.63586 22.6277 7.80146 23.5603 9.20799C24.4928 10.6145 25.079 12.222 25.2706 13.8987L25.308 14.2813L25.3333 14.6667V18.5747L25.3613 18.756C25.5439 19.7384 26.0875 20.6169 26.8853 21.2187L27.108 21.3747L27.324 21.5067C28.4706 22.156 28.0706 23.8613 26.8213 23.992L26.6666 24H5.3333C3.96264 24 3.48397 22.1813 4.67597 21.5067C5.18401 21.2192 5.62297 20.8239 5.96202 20.3487C6.30108 19.8736 6.53202 19.3299 6.63864 18.756L6.66664 18.5653L6.66797 14.6053C6.74927 12.8638 7.25618 11.1688 8.14435 9.66861C9.03252 8.16842 10.2749 6.9088 11.7626 5.99999L12.0933 5.80532L12.1066 5.74799C12.2952 4.95069 12.7239 4.23044 13.3348 3.68444C13.9456 3.13844 14.7093 2.79297 15.5226 2.69466L15.7653 2.67199L16 2.66666Z"
          fill="currentColor"
        />
      </g>

      <defs>
        <clipPath id="clip0_264_225">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
});

export const BellIconMetadata = {
  name: 'Bell',
  importName: 'BellIcon',
  component: <BellIcon aria-label="Bell Icon" />,
  tags: 'bell, notification, alert, reminder, alarm, sound, ring',
};
