// React & Next
import type { ReactElement } from 'react';

// 3rd
import { forwardRef, Button as ChakraButton } from '@chakra-ui/react';
import type { ButtonProps as ChakraButtonProps } from '@chakra-ui/react';

export type ButtonProps = Omit<
  ChakraButtonProps,
  | '_active'
  | '_hover'
  | '_disabled'
  | '_loading'
  | '_after'
  | '_before'
  | '_hidden'
  | '_placeholder'
  | '_placeholderShown'
  | '_selected'
  | '_selection'
  | '_checked'
  | '_visited'
  | '_valid'
  | '_invalid'
  | '_rtl'
  | '_ltr'
  | '_dark'
  | '_mediaReduceMotion'
  | '_mediaDark'
  | '_light'
  | '_vertical'
  | '_highlighted'
  | '_horizontal'
  | '_indeterminate'
  | '_empty'
  | '_even'
  | '_odd'
  | '_expanded'
  | '_last'
  | '_notLast'
  | '_notFirst'
  | '_first'
  | '_firstLetter'
  | '_focus'
  | '_focusVisible'
  | '_focusWithin'
  | '_fullScreen'
  | '_grabbed'
  | '_groupActive'
  | '_groupChecked'
  | '_groupDisabled'
  | '_groupFocus'
  | '_groupFocusVisible'
  | '_groupFocusWithin'
  | '_groupHover'
  | '_groupInvalid'
  | '_activeLink'
  | '_activeStep'
  | '_autofill'
  | '_peerActive'
  | '_peerChecked'
  | '_peerDisabled'
  | '_peerFocus'
  | '_peerFocusVisible'
  | '_peerFocusWithin'
  | '_peerHover'
  | '_peerInvalid'
  | '_peerPlaceholderShown'
  | '_pressed'
  | '_readOnly'
> & {
  size?: 'sm' | 'md' | 'lg';
  leftElement?: ReactElement;
  rightElement?: ReactElement;
  'aria-label': string;
};

export const Button = forwardRef(
  (
    {
      variant,
      size,
      isActive,
      leftIcon,
      leftElement,
      rightIcon,
      rightElement,
      children,
      ...props
    }: ButtonProps,
    ref
  ) => {
    const isOld = !!variant && typeof variant === 'string' && variant.includes('old');

    return (
      // we're adding selected attributes based on this link => https://chakra-ui.com/docs/styled-system/style-props#pseudo
      <ChakraButton
        {...props}
        variant={variant || 'solid'}
        size={`button${isOld ? '-old' : ''}-${size ? size : 'md'}`}
        aria-label={props['aria-label']}
        isActive={isActive}
        {...(leftIcon
          ? { leftIcon, className: 'with-left-icon' }
          : leftElement
            ? { leftIcon: leftElement }
            : {})}
        {...(rightIcon
          ? { rightIcon, className: 'with-right-icon' }
          : rightElement
            ? { rightIcon: rightElement }
            : {})}
        {...(isActive ? { 'data-selected': true, 'aria-selected': true } : {})}
        ref={ref}
      >
        {children}
      </ChakraButton>
    );
  }
);
