// React & Next
import type { ReactNode } from 'react';

// App - Types
import type { TooltipProps } from './tooltip';

// App - Other
import Locale from '@/locale/en.json';
import { Tooltip } from './tooltip';

const locale = Locale.components.tooltips['coming-soon-tooltip'];

type ComingSoonTooltipProps = Partial<TooltipProps> & {
  children: ReactNode;
  when: boolean;
};

export const ComingSoonTooltip = ({ when, children, ...tooltipProps }: ComingSoonTooltipProps) => {
  if (!when) return <>{children}</>;

  return (
    <Tooltip
      placement="top"
      {...tooltipProps}
      content={
        tooltipProps?.content
          ? `${tooltipProps.content} ${locale['(coming soon)']}`
          : locale['Coming soon']
      }
    >
      {children}
    </Tooltip>
  );
};
