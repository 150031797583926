// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const ArrowTrendDownIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 14 10" {...props} ref={ref}>
      <path
        d="M7.695 5.73832L7.55145 6.21727L7.55145 6.21727L7.695 5.73832ZM12.9356 7.67822C13.1787 7.54724 13.2696 7.24398 13.1387 7.00088L11.0042 3.03928C10.8733 2.79618 10.57 2.70528 10.3269 2.83626C10.0838 2.96724 9.99291 3.27049 10.1239 3.51359L12.0211 7.03502L8.49972 8.93227C8.25662 9.06325 8.16572 9.36651 8.2967 9.60961C8.42768 9.85271 8.73093 9.94361 8.97404 9.81263L12.9356 7.67822ZM1.00592 0.278044C0.729823 0.273141 0.502026 0.492989 0.497123 0.769087C0.492219 1.04519 0.712067 1.27298 0.988166 1.27789L1.00592 0.278044ZM3.59624 0.824124L3.58736 1.32404L3.59624 0.824124ZM5.41745 2.08046L5.88163 1.89462L5.88163 1.89462L5.41745 2.08046ZM6.41251 4.56588L5.94833 4.75172L5.94833 4.75172L6.41251 4.56588ZM7.55145 6.21727L12.5549 7.71699L12.842 6.75909L7.83856 5.25938L7.55145 6.21727ZM0.988166 1.27789L3.58736 1.32404L3.60512 0.324202L1.00592 0.278044L0.988166 1.27789ZM4.95327 2.26629L5.94833 4.75172L6.87669 4.38005L5.88163 1.89462L4.95327 2.26629ZM3.58736 1.32404C4.19075 1.33476 4.72897 1.70604 4.95327 2.26629L5.88163 1.89462C5.50779 0.960857 4.61077 0.342062 3.60512 0.324202L3.58736 1.32404ZM7.83856 5.25938C7.4007 5.12813 7.04658 4.8044 6.87669 4.38005L5.94833 4.75172C6.23148 5.45898 6.82168 5.99854 7.55145 6.21727L7.83856 5.25938Z"
        fill="#1AAA55"
      />
    </Icon>
  );
});

export const ArrowTrendDownIconMetadata = {
  name: 'Arrow Trend Down',
  importName: 'ArrowTrendDownIcon',
  component: <ArrowTrendDownIcon aria-label="Arrow Trend Down Icon" />,
  tags: 'arrow, trend, down',
};
