// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const GroovyIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 128 128" {...props} ref={ref}>
      <path d="M103.555 95.851L64 80.52 24.446 95.847l15.618-24.436L0 56.447l49.208.23L63.999 32.1l14.794 24.578L128 56.453l-40.065 14.96 15.62 24.438" />

      <path
        d="M98.204 91.48l-34.17-13.244-34.168 13.242 13.491-21.11-34.61-12.926 42.506.198L64.03 36.41l12.78 21.23 42.509-.194L84.71 70.37l13.493 21.11"
        fill="#619cbc"
      />

      <path d="M37.804 44.66c3.413 0 1.424 8.528.21 11.1-1.04 2.201-3.38 5.377-6.153 5.377-3.327 0-3.228-3.727-1.904-6.532.717-1.52 1.82-3.222 3.338-4.474-.39 1.307.925 2.2.393 3.325-.363.769-1.114 1.609-1.504 2.436-.631 1.337-.39 3.71 1.135 3.71 1.365 0 2.578-1.692 3.075-2.745 1.019-2.158 3.566-11.366.719-11.366-3.28 0-8.93 7.99-10.113 10.497-2.112 4.474-.58 9.984 4.231 9.984 3.252 0 5.926-3.126 7.218-5.862a14.931 14.931 0 001.353-4.781l.632-.116c.139 4.21 4.592 11.18 2.697 15.196-2.427 5.14-9.681 5.094-13.824 6.918-.87-2.037-2.05-3.93-3.711-5.376 3.695-1.243 12.775-3.33 14.75-7.512.83-1.758.264-4.671-.694-5.659a10.916 10.916 0 01-.689 1.89c-1.69 3.58-5.31 6.357-9.041 6.357-5.196 0-7.398-3.733-4.953-8.913 1.467-3.109 8.96-13.455 12.835-13.455" />

      <path
        d="M37.804 44.66c3.413 0 1.424 8.528.21 11.1-1.04 2.201-3.38 5.377-6.153 5.377-3.327 0-3.228-3.727-1.904-6.532.717-1.52 1.82-3.222 3.338-4.474-.39 1.307.925 2.2.393 3.325-.363.769-1.114 1.609-1.504 2.436-.631 1.337-.39 3.71 1.135 3.71 1.365 0 2.578-1.692 3.075-2.745 1.019-2.158 3.566-11.366.719-11.366-3.28 0-8.93 7.99-10.113 10.497-2.112 4.474-.58 9.984 4.231 9.984 3.252 0 5.926-3.126 7.218-5.862a14.931 14.931 0 001.353-4.781l.632-.116c.139 4.21 4.592 11.18 2.697 15.196-2.427 5.14-9.681 5.094-13.824 6.918-.87-2.037-2.05-3.93-3.711-5.376 3.695-1.243 12.775-3.33 14.75-7.512.83-1.758.264-4.671-.694-5.659a10.916 10.916 0 01-.689 1.89c-1.69 3.58-5.31 6.357-9.041 6.357-5.196 0-7.398-3.733-4.953-8.913 1.467-3.109 8.96-13.455 12.835-13.455z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path d="M46.681 57.002c.206.508.803 3.38 1.256 3.38.979 0 2.748-2.663 3.222-3.355.87.57.388 1.767 2 1.767.73 0 1.098-1.573 1.858-1.599.026.636.067 1.211-.28 1.946-.534 1.132-1.886 2.666-3.215 2.666-.953 0-2.279-1.146-2.54-1.146-.282 0-.802.132-.967.483-.693 1.467 1.158 6.363 3.014 6.363.992 0 2.175-.728 3.213-1.176-.27 1.764-.308 3.251.05 4.77a5.674 5.674 0 01-2.942.85c-4.56 0-2.045-10.605-6.25-12.865l1.58-2.084" />

      <path
        d="M46.681 57.002c.206.508.803 3.38 1.256 3.38.979 0 2.748-2.663 3.222-3.355.87.57.388 1.767 2 1.767.73 0 1.098-1.573 1.858-1.599.026.636.067 1.211-.28 1.946-.534 1.132-1.886 2.666-3.215 2.666-.953 0-2.279-1.146-2.54-1.146-.282 0-.802.132-.967.483-.693 1.467 1.158 6.363 3.014 6.363.992 0 2.175-.728 3.213-1.176-.27 1.764-.308 3.251.05 4.77a5.674 5.674 0 01-2.942.85c-4.56 0-2.045-10.605-6.25-12.865z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path
        d="M64.209 56.154c3.447 0 4.808 6.2 3.261 9.476-1.2 2.542-3.327 4.384-6.052 4.384-4.812 0-4.21-6.56-2.332-10.538.518-1.096 2.287-3.484 3.67-3.484.51 0 .93.162 1.453.162zm-.107.579c-.542 0-1.02.268-1.27.802-.53 1.118 2.105 2.25 1.727 3.051-.238.504-1.144.976-1.601.976-2.284 0-2.265-2.972-.903-4.908-.847.539-1.402 1.363-1.762 2.127-1.338 2.835-.276 6.734 2.778 6.734 1.688 0 3.631-1.516 4.387-3.117.93-1.968-1.448-5.665-3.356-5.665"
        fillRule="evenodd"
      />

      <path
        d="M64.209 56.154c3.447 0 4.808 6.2 3.261 9.476-1.2 2.542-3.327 4.384-6.052 4.384-4.812 0-4.21-6.56-2.332-10.538.518-1.096 2.287-3.484 3.67-3.484.51 0 .93.162 1.453.162z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path
        d="M64.101 56.733c-.542 0-1.02.268-1.271.802-.528 1.119 2.106 2.25 1.728 3.051-.238.504-1.144.976-1.601.976-2.284 0-2.265-2.972-.903-4.908-.847.54-1.402 1.363-1.762 2.127-1.339 2.835-.277 6.735 2.778 6.735 1.687 0 3.631-1.517 4.387-3.117.93-1.968-1.448-5.666-3.356-5.666z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path
        d="M79.875 66.36c-.901 1.908-2.58 2.955-4.47 2.955-5.997 0-7.187-5.469-4.587-10.975.613-1.3 1.62-2.737 3.159-2.737 4.818 0 8.174 5.933 5.897 10.757zM75.09 56.293c-.443 0-.83.16-1.043.61-.466.989 2.072 1.87 2.075 2.892-.265.314-1.007.665-1.33.665-1.828 0-3.208-2.478-1.647-4.32-.634.403-1.01 1.005-1.277 1.57-1.582 3.352.673 6.74 3.96 6.74 1.368 0 3.13-.76 3.841-2.266 1.006-2.13-2.576-5.89-4.58-5.89"
        fillRule="evenodd"
      />

      <path
        d="M79.875 66.36c-.901 1.908-2.58 2.955-4.47 2.955-5.997 0-7.187-5.469-4.587-10.975.613-1.3 1.62-2.737 3.159-2.737 4.818 0 8.174 5.933 5.897 10.757z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path
        d="M75.089 56.294c-.442 0-.83.16-1.043.61-.466.988 2.072 1.87 2.076 2.892-.265.313-1.008.665-1.331.665-1.828 0-3.208-2.478-1.647-4.32-.633.402-1.01 1.004-1.277 1.57-1.582 3.352.674 6.74 3.96 6.74 1.368 0 3.131-.76 3.842-2.266 1.005-2.13-2.576-5.89-4.58-5.89z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path d="M86.077 56.262c5.168 0 7.795 9.484 5.485 14.376-.441.935-1.264 1.87-2.35 1.87-5.904 0-4.397-11.737-7.72-15.072.303-.323.594-.66.861-1 2.246 2.635 1.13 9.572 5.927 9.572 1.105 0 1.941-.967 2.387-1.912 1.191-2.523-1.013-6.906-3.426-6.906-.024 0-.37.014-.363.154.09 1.611 1.356.607.08 3.348-1.303-.26-2.684-2.353-1.971-3.863.21-.444.734-.567 1.09-.567" />

      <path
        d="M86.077 56.262c5.168 0 7.795 9.484 5.485 14.376-.441.935-1.264 1.87-2.35 1.87-5.904 0-4.397-11.737-7.72-15.072.303-.323.594-.66.861-1 2.246 2.635 1.13 9.572 5.927 9.572 1.105 0 1.941-.967 2.387-1.912 1.191-2.523-1.013-6.906-3.426-6.906-.024 0-.37.014-.363.154.09 1.611 1.356.607.08 3.348-1.303-.26-2.684-2.353-1.971-3.863.21-.444.734-.567 1.09-.567z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path
        d="M98.927 56.154c.487.046 1.218.002 1.636.13.078.023.019.121.032.199.127.716.327 1.396.5 2.089.673 2.698 1.078 5.468 1.85 8.142.841 2.912 1.718 6.194.7 9.848-.1.357-.233.72-.405 1.085-1.154 2.445-11.091 5.262-13.686 5.262-.315 0-.655-.09-.805-.295 1.094-.696 1.78-1.41 2.27-2.446.585-1.242.577-2.406.5-3.477.207.12 7.842-.068 8.118-.068 1.123 0 2.086-1.085 2.536-2.04.827-1.751.686-3.539.543-5.031a23.224 23.224 0 00-.312-2.2l-.21-1.036-.027-.113-.026-.114c.125 0 .013.22.018.331.077 1.806.044 4.328-.926 6.384-.451.955-1.189 1.94-2.32 1.94-4.963 0-2.467-14.074-6.444-17.42.22-.363.417-.738.582-1.105 2.353 2.842 2.477 10.677 5.94 10.677.84 0 1.71-.788 2.067-1.545 1.539-3.26-1.017-7.057-2.13-9.197"
        fillRule="evenodd"
      />

      <path
        d="M98.927 56.154c.487.046 1.218.002 1.636.13.078.023.019.121.032.199.127.716.327 1.396.5 2.089.673 2.698 1.078 5.468 1.85 8.142.841 2.912 1.718 6.194.7 9.848-.1.357-.233.72-.405 1.085-1.154 2.445-11.091 5.262-13.686 5.262-.315 0-.655-.09-.805-.295 1.094-.696 1.78-1.41 2.27-2.446.585-1.242.577-2.406.5-3.477.207.12 7.842-.068 8.118-.068 1.123 0 2.086-1.085 2.536-2.04.827-1.751.686-3.539.543-5.031a23.224 23.224 0 00-.312-2.2l-.21-1.036-.027-.113-.026-.114c.125 0 .013.22.018.331.077 1.806.044 4.328-.926 6.384-.451.955-1.189 1.94-2.32 1.94-4.963 0-2.467-14.074-6.444-17.42.22-.363.417-.738.582-1.105 2.353 2.842 2.477 10.677 5.94 10.677.84 0 1.71-.788 2.067-1.545 1.539-3.26-1.017-7.057-2.13-9.197z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path d="M37.804 44.66c3.413 0 1.424 8.528.21 11.1-1.04 2.201-3.38 5.377-6.153 5.377-3.327 0-3.228-3.727-1.904-6.532.717-1.52 1.82-3.222 3.338-4.474-.39 1.307.925 2.2.393 3.325-.363.769-1.114 1.609-1.504 2.436-.631 1.337-.39 3.71 1.135 3.71 1.365 0 2.578-1.692 3.075-2.745 1.019-2.158 3.566-11.366.719-11.366-3.28 0-8.93 7.99-10.113 10.497-2.112 4.474-.58 9.984 4.231 9.984 3.252 0 5.926-3.126 7.218-5.862a14.931 14.931 0 001.353-4.781l.632-.116c.139 4.21 4.592 11.18 2.697 15.196-2.427 5.14-9.681 5.094-13.824 6.918-.87-2.037-2.05-3.93-3.711-5.376 3.695-1.243 12.775-3.33 14.75-7.512.83-1.758.264-4.671-.694-5.659a10.916 10.916 0 01-.689 1.89c-1.69 3.58-5.31 6.357-9.041 6.357-5.196 0-7.398-3.733-4.953-8.913 1.467-3.109 8.96-13.455 12.835-13.455" />

      <path
        d="M37.804 44.66c3.413 0 1.424 8.528.21 11.1-1.04 2.201-3.38 5.377-6.153 5.377-3.327 0-3.228-3.727-1.904-6.532.717-1.52 1.82-3.222 3.338-4.474-.39 1.307.925 2.2.393 3.325-.363.769-1.114 1.609-1.504 2.436-.631 1.337-.39 3.71 1.135 3.71 1.365 0 2.578-1.692 3.075-2.745 1.019-2.158 3.566-11.366.719-11.366-3.28 0-8.93 7.99-10.113 10.497-2.112 4.474-.58 9.984 4.231 9.984 3.252 0 5.926-3.126 7.218-5.862a14.931 14.931 0 001.353-4.781l.632-.116c.139 4.21 4.592 11.18 2.697 15.196-2.427 5.14-9.681 5.094-13.824 6.918-.87-2.037-2.05-3.93-3.711-5.376 3.695-1.243 12.775-3.33 14.75-7.512.83-1.758.264-4.671-.694-5.659a10.916 10.916 0 01-.689 1.89c-1.69 3.58-5.31 6.357-9.041 6.357-5.196 0-7.398-3.733-4.953-8.913 1.467-3.109 8.96-13.455 12.835-13.455z"
        fill="none"
        stroke="#000"
        strokeMiterlimit="3.864"
        strokeWidth=".47650595999999995"
      />

      <path d="M46.681 57.002c.206.508.803 3.38 1.256 3.38.979 0 2.748-2.663 3.222-3.355.87.57.388 1.767 2 1.767.73 0 1.098-1.573 1.858-1.599.026.636.067 1.211-.28 1.946-.534 1.132-1.886 2.666-3.215 2.666-.953 0-2.279-1.146-2.54-1.146-.282 0-.802.132-.967.483-.693 1.467 1.158 6.363 3.014 6.363.992 0 2.175-.728 3.213-1.176-.27 1.764-.308 3.251.05 4.77a5.674 5.674 0 01-2.942.85c-4.56 0-2.045-10.605-6.25-12.865l1.58-2.084" />

      <path
        d="M46.681 57.002c.206.508.803 3.38 1.256 3.38.979 0 2.748-2.663 3.222-3.355.87.57.388 1.767 2 1.767.73 0 1.098-1.573 1.858-1.599.026.636.067 1.211-.28 1.946-.534 1.132-1.886 2.666-3.215 2.666-.953 0-2.279-1.146-2.54-1.146-.282 0-.802.132-.967.483-.693 1.467 1.158 6.363 3.014 6.363.992 0 2.175-.728 3.213-1.176-.27 1.764-.308 3.251.05 4.77a5.674 5.674 0 01-2.942.85c-4.56 0-2.045-10.605-6.25-12.865z"
        fill="none"
        stroke="#000"
        strokeMiterlimit="3.864"
        strokeWidth=".47650595999999995"
      />

      <path
        d="M64.209 56.154c3.447 0 4.808 6.2 3.261 9.476-1.2 2.542-3.327 4.384-6.052 4.384-4.812 0-4.21-6.56-2.332-10.538.518-1.096 2.287-3.484 3.67-3.484.51 0 .93.162 1.453.162zm-.107.579c-.542 0-1.02.268-1.27.802-.53 1.118 2.105 2.25 1.727 3.051-.238.504-1.144.976-1.601.976-2.284 0-2.265-2.972-.903-4.908-.847.539-1.402 1.363-1.762 2.127-1.338 2.835-.276 6.734 2.778 6.734 1.688 0 3.631-1.516 4.387-3.117.93-1.968-1.448-5.665-3.356-5.665"
        fillRule="evenodd"
      />

      <path
        d="M64.209 56.154c3.447 0 4.808 6.2 3.261 9.476-1.2 2.542-3.327 4.384-6.052 4.384-4.812 0-4.21-6.56-2.332-10.538.518-1.096 2.287-3.484 3.67-3.484.51 0 .93.162 1.453.162z"
        fill="none"
        stroke="#000"
        strokeMiterlimit="3.864"
        strokeWidth=".47650595999999995"
      />

      <path
        d="M64.101 56.733c-.542 0-1.02.268-1.271.802-.528 1.119 2.106 2.25 1.728 3.051-.238.504-1.144.976-1.601.976-2.284 0-2.265-2.972-.903-4.908-.847.54-1.402 1.363-1.762 2.127-1.339 2.835-.277 6.735 2.778 6.735 1.687 0 3.631-1.517 4.387-3.117.93-1.968-1.448-5.666-3.356-5.666z"
        fill="none"
        stroke="#000"
        strokeMiterlimit="3.864"
        strokeWidth=".47650595999999995"
      />

      <path
        d="M79.875 66.36c-.901 1.908-2.58 2.955-4.47 2.955-5.997 0-7.187-5.469-4.587-10.975.613-1.3 1.62-2.737 3.159-2.737 4.818 0 8.174 5.933 5.897 10.757zM75.09 56.293c-.443 0-.83.16-1.043.61-.466.989 2.072 1.87 2.075 2.892-.265.314-1.007.665-1.33.665-1.828 0-3.208-2.478-1.647-4.32-.634.403-1.01 1.005-1.277 1.57-1.582 3.352.673 6.74 3.96 6.74 1.368 0 3.13-.76 3.841-2.266 1.006-2.13-2.576-5.89-4.58-5.89"
        fillRule="evenodd"
      />

      <path
        d="M79.875 66.36c-.901 1.908-2.58 2.955-4.47 2.955-5.997 0-7.187-5.469-4.587-10.975.613-1.3 1.62-2.737 3.159-2.737 4.818 0 8.174 5.933 5.897 10.757z"
        fill="none"
        stroke="#000"
        strokeMiterlimit="3.864"
        strokeWidth=".47650595999999995"
      />

      <path
        d="M75.089 56.294c-.442 0-.83.16-1.043.61-.466.988 2.072 1.87 2.076 2.892-.265.313-1.008.665-1.331.665-1.828 0-3.208-2.478-1.647-4.32-.633.402-1.01 1.004-1.277 1.57-1.582 3.352.674 6.74 3.96 6.74 1.368 0 3.131-.76 3.842-2.266 1.005-2.13-2.576-5.89-4.58-5.89z"
        fill="none"
        stroke="#000"
        strokeMiterlimit="3.864"
        strokeWidth=".47650595999999995"
      />

      <path d="M86.077 56.262c5.168 0 7.795 9.484 5.485 14.376-.441.935-1.264 1.87-2.35 1.87-5.904 0-4.397-11.737-7.72-15.072.303-.323.594-.66.861-1 2.246 2.635 1.13 9.572 5.927 9.572 1.105 0 1.941-.967 2.387-1.912 1.191-2.523-1.013-6.906-3.426-6.906-.024 0-.37.014-.363.154.09 1.611 1.356.607.08 3.348-1.303-.26-2.684-2.353-1.971-3.863.21-.444.734-.567 1.09-.567" />

      <path
        d="M86.077 56.262c5.168 0 7.795 9.484 5.485 14.376-.441.935-1.264 1.87-2.35 1.87-5.904 0-4.397-11.737-7.72-15.072.303-.323.594-.66.861-1 2.246 2.635 1.13 9.572 5.927 9.572 1.105 0 1.941-.967 2.387-1.912 1.191-2.523-1.013-6.906-3.426-6.906-.024 0-.37.014-.363.154.09 1.611 1.356.607.08 3.348-1.303-.26-2.684-2.353-1.971-3.863.21-.444.734-.567 1.09-.567z"
        fill="none"
        stroke="#000"
        strokeMiterlimit="3.864"
        strokeWidth=".47650595999999995"
      />

      <path
        d="M98.927 56.154c.487.046 1.218.002 1.636.13.078.023.019.121.032.199.127.716.327 1.396.5 2.089.673 2.698 1.078 5.468 1.85 8.142.841 2.912 1.718 6.194.7 9.848-.1.357-.233.72-.405 1.085-1.154 2.445-4.12 4.772-6.716 4.772-.315 0-.655-.09-.806-.294 1.096-.696 1.78-1.41 2.27-2.446.587-1.243.578-2.407.5-3.478.208.12.873.422 1.15.422 1.122 0 2.085-1.085 2.536-2.04.827-1.751.685-3.539.542-5.031a23.224 23.224 0 00-.312-2.2l-.21-1.036-.027-.113-.026-.114c.126 0 .013.22.018.331.077 1.806.045 4.328-.926 6.384-.45.955-1.188 1.94-2.32 1.94-4.963 0-2.467-14.074-6.444-17.42.22-.363.417-.738.582-1.105 2.354 2.842 2.477 10.677 5.94 10.677.841 0 1.71-.788 2.067-1.545 1.54-3.26-1.017-7.057-2.13-9.197"
        fillRule="evenodd"
      />

      <path
        d="M98.927 56.154c.487.046 1.218.002 1.636.13.078.023.019.121.032.199.127.716.327 1.396.5 2.089.673 2.698 1.078 5.468 1.85 8.142.841 2.912 1.718 6.194.7 9.848-.1.357-.233.72-.405 1.085-1.154 2.445-4.12 4.772-6.716 4.772-.315 0-.655-.09-.806-.294 1.096-.696 1.78-1.41 2.27-2.446.587-1.243.578-2.407.5-3.478.208.12.873.422 1.15.422 1.122 0 2.085-1.085 2.536-2.04.827-1.751.685-3.539.542-5.031a23.224 23.224 0 00-.312-2.2l-.21-1.036-.027-.113-.026-.114c.126 0 .013.22.018.331.077 1.806.045 4.328-.926 6.384-.45.955-1.188 1.94-2.32 1.94-4.963 0-2.467-14.074-6.444-17.42.22-.363.417-.738.582-1.105 2.354 2.842 2.477 10.677 5.94 10.677.841 0 1.71-.788 2.067-1.545 1.54-3.26-1.017-7.057-2.13-9.197z"
        fill="none"
        stroke="#000"
        strokeMiterlimit="3.864"
        strokeWidth=".47650595999999995"
      />

      <path d="M37.804 44.66c3.413 0 1.424 8.528.21 11.1-1.04 2.201-3.38 5.377-6.153 5.377-3.327 0-3.228-3.727-1.904-6.532.717-1.52 1.82-3.222 3.338-4.474-.39 1.307.925 2.2.393 3.325-.363.769-1.114 1.609-1.504 2.436-.631 1.337-.39 3.71 1.135 3.71 1.365 0 2.578-1.692 3.075-2.745 1.019-2.158 3.566-11.366.719-11.366-3.28 0-8.93 7.99-10.113 10.497-2.112 4.474-.58 9.984 4.231 9.984 3.252 0 5.926-3.126 7.218-5.862a14.931 14.931 0 001.353-4.781l.632-.116c.139 4.21 4.592 11.18 2.697 15.196-2.427 5.14-9.681 5.094-13.824 6.918-.87-2.037-2.05-3.93-3.711-5.376 3.695-1.243 12.775-3.33 14.75-7.512.83-1.758.264-4.671-.694-5.659a10.916 10.916 0 01-.689 1.89c-1.69 3.58-5.31 6.357-9.041 6.357-5.196 0-7.398-3.733-4.953-8.913 1.467-3.109 8.96-13.455 12.835-13.455" />

      <path
        d="M37.804 44.66c3.413 0 1.424 8.528.21 11.1-1.04 2.201-3.38 5.377-6.153 5.377-3.327 0-3.228-3.727-1.904-6.532.717-1.52 1.82-3.222 3.338-4.474-.39 1.307.925 2.2.393 3.325-.363.769-1.114 1.609-1.504 2.436-.631 1.337-.39 3.71 1.135 3.71 1.365 0 2.578-1.692 3.075-2.745 1.019-2.158 3.566-11.366.719-11.366-3.28 0-8.93 7.99-10.113 10.497-2.112 4.474-.58 9.984 4.231 9.984 3.252 0 5.926-3.126 7.218-5.862a14.931 14.931 0 001.353-4.781l.632-.116c.139 4.21 4.592 11.18 2.697 15.196-2.427 5.14-9.681 5.094-13.824 6.918-.87-2.037-2.05-3.93-3.711-5.376 3.695-1.243 12.775-3.33 14.75-7.512.83-1.758.264-4.671-.694-5.659a10.916 10.916 0 01-.689 1.89c-1.69 3.58-5.31 6.357-9.041 6.357-5.196 0-7.398-3.733-4.953-8.913 1.467-3.109 8.96-13.455 12.835-13.455z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path d="M46.681 57.002c.206.508.803 3.38 1.256 3.38.979 0 2.748-2.663 3.222-3.355.87.57.388 1.767 2 1.767.73 0 1.098-1.573 1.858-1.599.026.636.067 1.211-.28 1.946-.534 1.132-1.886 2.666-3.215 2.666-.953 0-2.279-1.146-2.54-1.146-.282 0-.802.132-.967.483-.693 1.467 1.158 6.363 3.014 6.363.992 0 2.175-.728 3.213-1.176-.27 1.764-.308 3.251.05 4.77a5.674 5.674 0 01-2.942.85c-4.56 0-2.045-10.605-6.25-12.865l1.58-2.084" />

      <path
        d="M46.681 57.002c.206.508.803 3.38 1.256 3.38.979 0 2.748-2.663 3.222-3.355.87.57.388 1.767 2 1.767.73 0 1.098-1.573 1.858-1.599.026.636.067 1.211-.28 1.946-.534 1.132-1.886 2.666-3.215 2.666-.953 0-2.279-1.146-2.54-1.146-.282 0-.802.132-.967.483-.693 1.467 1.158 6.363 3.014 6.363.992 0 2.175-.728 3.213-1.176-.27 1.764-.308 3.251.05 4.77a5.674 5.674 0 01-2.942.85c-4.56 0-2.045-10.605-6.25-12.865z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path
        d="M64.209 56.154c3.447 0 4.808 6.2 3.261 9.476-1.2 2.542-3.327 4.384-6.052 4.384-4.812 0-4.21-6.56-2.332-10.538.518-1.096 2.287-3.484 3.67-3.484.51 0 .93.162 1.453.162zm-.107.579c-.542 0-1.02.268-1.27.802-.53 1.118 2.105 2.25 1.727 3.051-.238.504-1.144.976-1.601.976-2.284 0-2.265-2.972-.903-4.908-.847.539-1.402 1.363-1.762 2.127-1.338 2.835-.276 6.734 2.778 6.734 1.688 0 3.631-1.516 4.387-3.117.93-1.968-1.448-5.665-3.356-5.665"
        fillRule="evenodd"
      />

      <path
        d="M64.209 56.154c3.447 0 4.808 6.2 3.261 9.476-1.2 2.542-3.327 4.384-6.052 4.384-4.812 0-4.21-6.56-2.332-10.538.518-1.096 2.287-3.484 3.67-3.484.51 0 .93.162 1.453.162z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path
        d="M64.101 56.733c-.542 0-1.02.268-1.271.802-.528 1.119 2.106 2.25 1.728 3.051-.238.504-1.144.976-1.601.976-2.284 0-2.265-2.972-.903-4.908-.847.54-1.402 1.363-1.762 2.127-1.339 2.835-.277 6.735 2.778 6.735 1.687 0 3.631-1.517 4.387-3.117.93-1.968-1.448-5.666-3.356-5.666z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path
        d="M79.875 66.36c-.901 1.908-2.58 2.955-4.47 2.955-5.997 0-7.187-5.469-4.587-10.975.613-1.3 1.62-2.737 3.159-2.737 4.818 0 8.174 5.933 5.897 10.757zM75.09 56.293c-.443 0-.83.16-1.043.61-.466.989 2.072 1.87 2.075 2.892-.265.314-1.007.665-1.33.665-1.828 0-3.208-2.478-1.647-4.32-.634.403-1.01 1.005-1.277 1.57-1.582 3.352.673 6.74 3.96 6.74 1.368 0 3.13-.76 3.841-2.266 1.006-2.13-2.576-5.89-4.58-5.89"
        fillRule="evenodd"
      />

      <path
        d="M79.875 66.36c-.901 1.908-2.58 2.955-4.47 2.955-5.997 0-7.187-5.469-4.587-10.975.613-1.3 1.62-2.737 3.159-2.737 4.818 0 8.174 5.933 5.897 10.757z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path
        d="M75.089 56.294c-.442 0-.83.16-1.043.61-.466.988 2.072 1.87 2.076 2.892-.265.313-1.008.665-1.331.665-1.828 0-3.208-2.478-1.647-4.32-.633.402-1.01 1.004-1.277 1.57-1.582 3.352.674 6.74 3.96 6.74 1.368 0 3.131-.76 3.842-2.266 1.005-2.13-2.576-5.89-4.58-5.89z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path d="M86.077 56.262c5.168 0 7.795 9.484 5.485 14.376-.441.935-1.264 1.87-2.35 1.87-5.904 0-4.397-11.737-7.72-15.072.303-.323.594-.66.861-1 2.246 2.635 1.13 9.572 5.927 9.572 1.105 0 1.941-.967 2.387-1.912 1.191-2.523-1.013-6.906-3.426-6.906-.024 0-.37.014-.363.154.09 1.611 1.356.607.08 3.348-1.303-.26-2.684-2.353-1.971-3.863.21-.444.734-.567 1.09-.567" />

      <path
        d="M86.077 56.262c5.168 0 7.795 9.484 5.485 14.376-.441.935-1.264 1.87-2.35 1.87-5.904 0-4.397-11.737-7.72-15.072.303-.323.594-.66.861-1 2.246 2.635 1.13 9.572 5.927 9.572 1.105 0 1.941-.967 2.387-1.912 1.191-2.523-1.013-6.906-3.426-6.906-.024 0-.37.014-.363.154.09 1.611 1.356.607.08 3.348-1.303-.26-2.684-2.353-1.971-3.863.21-.444.734-.567 1.09-.567z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path
        d="M98.927 56.154c.487.046 1.218.002 1.636.13.078.023.019.121.032.199.127.716.327 1.396.5 2.089.673 2.698 1.078 5.468 1.85 8.142.841 2.912 1.718 6.194.7 9.848-.1.357-.233.72-.405 1.085-1.154 2.445-4.12 4.772-6.716 4.772-.315 0-.655-.09-.806-.294 1.096-.696 1.78-1.41 2.27-2.446.587-1.243.578-2.407.5-3.478.208.12.873.422 1.15.422 1.122 0 2.085-1.085 2.536-2.04.827-1.751.685-3.539.542-5.031a23.224 23.224 0 00-.312-2.2l-.21-1.036-.027-.113-.026-.114c.126 0 .013.22.018.331.077 1.806.045 4.328-.926 6.384-.45.955-1.188 1.94-2.32 1.94-4.963 0-2.467-14.074-6.444-17.42.22-.363.417-.738.582-1.105 2.354 2.842 2.477 10.677 5.94 10.677.841 0 1.71-.788 2.067-1.545 1.54-3.26-1.017-7.057-2.13-9.197"
        fillRule="evenodd"
      />

      <path
        d="M98.927 56.154c.487.046 1.218.002 1.636.13.078.023.019.121.032.199.127.716.327 1.396.5 2.089.673 2.698 1.078 5.468 1.85 8.142.841 2.912 1.718 6.194.7 9.848-.1.357-.233.72-.405 1.085-1.154 2.445-4.12 4.772-6.716 4.772-.315 0-.655-.09-.806-.294 1.096-.696 1.78-1.41 2.27-2.446.587-1.243.578-2.407.5-3.478.208.12.873.422 1.15.422 1.122 0 2.085-1.085 2.536-2.04.827-1.751.685-3.539.542-5.031a23.224 23.224 0 00-.312-2.2l-.21-1.036-.027-.113-.026-.114c.126 0 .013.22.018.331.077 1.806.045 4.328-.926 6.384-.45.955-1.188 1.94-2.32 1.94-4.963 0-2.467-14.074-6.444-17.42.22-.363.417-.738.582-1.105 2.354 2.842 2.477 10.677 5.94 10.677.841 0 1.71-.788 2.067-1.545 1.54-3.26-1.017-7.057-2.13-9.197z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <g fill="#fff">
        <path d="M37.804 44.66c3.413 0 1.424 8.528.21 11.1-1.04 2.201-3.38 5.377-6.153 5.377-3.327 0-3.228-3.727-1.904-6.532.717-1.52 1.82-3.222 3.338-4.474-.39 1.307.925 2.2.393 3.325-.363.769-1.114 1.609-1.504 2.436-.631 1.337-.39 3.71 1.135 3.71 1.365 0 2.578-1.692 3.075-2.745 1.019-2.158 3.566-11.366.719-11.366-3.28 0-8.93 7.99-10.113 10.497-2.112 4.474-.58 9.984 4.231 9.984 3.252 0 5.926-3.126 7.218-5.862a14.931 14.931 0 001.353-4.781l.632-.116c.139 4.21 4.592 11.18 2.697 15.196-2.427 5.14-9.681 5.094-13.824 6.918-.87-2.037-2.05-3.93-3.711-5.376 3.695-1.243 12.775-3.33 14.75-7.512.83-1.758.264-4.671-.694-5.659a10.916 10.916 0 01-.689 1.89c-1.69 3.58-5.31 6.357-9.041 6.357-5.196 0-7.398-3.733-4.953-8.913 1.467-3.109 8.96-13.455 12.835-13.455M46.681 57.002c.206.508.803 3.38 1.256 3.38.979 0 2.748-2.663 3.222-3.355.87.57.388 1.767 2 1.767.73 0 1.098-1.573 1.858-1.599.026.636.067 1.211-.28 1.946-.534 1.132-1.886 2.666-3.215 2.666-.953 0-2.279-1.146-2.54-1.146-.282 0-.802.132-.967.483-.693 1.467 1.158 6.363 3.014 6.363.992 0 2.175-.728 3.213-1.176-.27 1.764-.308 3.251.05 4.77a5.674 5.674 0 01-2.942.85c-4.56 0-2.045-10.605-6.25-12.865l1.58-2.084" />

        <path
          d="M64.209 56.154c3.447 0 4.808 6.2 3.261 9.476-1.2 2.542-3.327 4.384-6.052 4.384-4.812 0-4.21-6.56-2.332-10.538.518-1.096 2.287-3.484 3.67-3.484.51 0 .93.162 1.453.162zm-.107.579c-.542 0-1.02.268-1.27.802-.53 1.118 2.105 2.25 1.727 3.051-.238.504-1.144.976-1.601.976-2.284 0-2.265-2.972-.903-4.908-.847.539-1.402 1.363-1.762 2.127-1.338 2.835-.276 6.734 2.778 6.734 1.688 0 3.631-1.516 4.387-3.117.93-1.968-1.448-5.665-3.356-5.665M79.875 66.36c-.901 1.908-2.58 2.955-4.47 2.955-5.997 0-7.187-5.469-4.587-10.975.613-1.3 1.62-2.737 3.159-2.737 4.818 0 8.174 5.933 5.897 10.757zM75.09 56.293c-.443 0-.83.16-1.043.61-.466.989 2.072 1.87 2.075 2.892-.265.314-1.007.665-1.33.665-1.828 0-3.208-2.478-1.647-4.32-.634.403-1.01 1.005-1.277 1.57-1.582 3.352.673 6.74 3.96 6.74 1.368 0 3.13-.76 3.841-2.266 1.006-2.13-2.576-5.89-4.58-5.89"
          fillRule="evenodd"
        />

        <path d="M86.077 56.262c5.168 0 7.795 9.484 5.485 14.376-.441.935-1.264 1.87-2.35 1.87-5.904 0-4.397-11.737-7.72-15.072.303-.323.594-.66.861-1 2.246 2.635 1.13 9.572 5.927 9.572 1.105 0 1.941-.967 2.387-1.912 1.191-2.523-1.013-6.906-3.426-6.906-.024 0-.37.014-.363.154.09 1.611 1.356.607.08 3.348-1.303-.26-2.684-2.353-1.971-3.863.21-.444.734-.567 1.09-.567" />

        <path
          d="M98.927 56.154c.487.046 1.218.002 1.636.13.078.023.019.121.032.199.127.716.327 1.396.5 2.089.673 2.698 1.078 5.468 1.85 8.142.841 2.912 1.718 6.194.7 9.848-.1.357-.233.72-.405 1.085-3.87 7.432-12.151 5.43-14.745 5.43-.315 0-.656-.09-.806-.295 1.094-.696 1.78-1.41 2.27-2.446.586-1.243.577-2.406.5-3.478.207.121.872.422 1.148.422 1.123 0 7.378 3.214 10.567-2.696.827-1.752.685-3.54.542-5.032a23.224 23.224 0 00-.312-2.2l-.21-1.036-.027-.113-.026-.113c.126 0 .013.22.018.33.077 1.807.045 4.328-.926 6.384-.45.955-1.188 1.94-2.32 1.94-4.963 0-2.467-14.074-6.444-17.42.22-.363.417-.738.582-1.105 2.354 2.842 2.477 10.677 5.94 10.677.841 0 1.71-.787 2.068-1.545 1.538-3.26-1.018-7.056-2.132-9.197"
          fillRule="evenodd"
        />
      </g>

      <path d="M103.493 95.913l-39.525-15.35-39.525 15.346 15.606-24.464L.013 56.463l49.172.23 14.781-24.605L78.75 56.694l49.172-.225-40.036 14.978 15.609 24.466" />

      <path
        d="M98.204 91.48l-34.17-13.244-34.168 13.242 13.491-21.11-34.61-12.926 42.506.198L64.03 36.41l12.78 21.23 42.509-.194L84.71 70.37l13.493 21.11"
        fill="#619cbc"
      />

      <path d="M37.804 44.66c3.413 0 1.424 8.528.21 11.1-1.04 2.201-3.38 5.377-6.153 5.377-3.327 0-3.228-3.727-1.904-6.532.717-1.52 1.82-3.222 3.338-4.474-.39 1.307.925 2.2.393 3.325-.363.769-1.114 1.609-1.504 2.436-.631 1.337-.39 3.71 1.135 3.71 1.365 0 2.578-1.692 3.075-2.745 1.019-2.158 3.566-11.366.719-11.366-3.28 0-8.93 7.99-10.113 10.497-2.112 4.474-.58 9.984 4.231 9.984 3.252 0 5.926-3.126 7.218-5.862a14.931 14.931 0 001.353-4.781l.632-.116c.139 4.21 4.592 11.18 2.697 15.196-2.427 5.14-9.681 5.094-13.824 6.918-.87-2.037-2.05-3.93-3.711-5.376 3.695-1.243 12.775-3.33 14.75-7.512.83-1.758.264-4.671-.694-5.659a10.916 10.916 0 01-.689 1.89c-1.69 3.58-5.31 6.357-9.041 6.357-5.196 0-7.398-3.733-4.953-8.913 1.467-3.109 8.96-13.455 12.835-13.455" />

      <path
        d="M37.804 44.66c3.413 0 1.424 8.528.21 11.1-1.04 2.201-3.38 5.377-6.153 5.377-3.327 0-3.228-3.727-1.904-6.532.717-1.52 1.82-3.222 3.338-4.474-.39 1.307.925 2.2.393 3.325-.363.769-1.114 1.609-1.504 2.436-.631 1.337-.39 3.71 1.135 3.71 1.365 0 2.578-1.692 3.075-2.745 1.019-2.158 3.566-11.366.719-11.366-3.28 0-8.93 7.99-10.113 10.497-2.112 4.474-.58 9.984 4.231 9.984 3.252 0 5.926-3.126 7.218-5.862a14.931 14.931 0 001.353-4.781l.632-.116c.139 4.21 4.592 11.18 2.697 15.196-2.427 5.14-9.681 5.094-13.824 6.918-.87-2.037-2.05-3.93-3.711-5.376 3.695-1.243 12.775-3.33 14.75-7.512.83-1.758.264-4.671-.694-5.659a10.916 10.916 0 01-.689 1.89c-1.69 3.58-5.31 6.357-9.041 6.357-5.196 0-7.398-3.733-4.953-8.913 1.467-3.109 8.96-13.455 12.835-13.455z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path d="M46.681 57.002c.206.508.803 3.38 1.256 3.38.979 0 2.748-2.663 3.222-3.355.87.57.388 1.767 2 1.767.73 0 1.098-1.573 1.858-1.599.026.636.067 1.211-.28 1.946-.534 1.132-1.886 2.666-3.215 2.666-.953 0-2.279-1.146-2.54-1.146-.282 0-.802.132-.967.483-.693 1.467 1.158 6.363 3.014 6.363.992 0 2.175-.728 3.213-1.176-.27 1.764-.308 3.251.05 4.77a5.674 5.674 0 01-2.942.85c-4.56 0-2.045-10.605-6.25-12.865l1.58-2.084" />

      <path
        d="M46.681 57.002c.206.508.803 3.38 1.256 3.38.979 0 2.748-2.663 3.222-3.355.87.57.388 1.767 2 1.767.73 0 1.098-1.573 1.858-1.599.026.636.067 1.211-.28 1.946-.534 1.132-1.886 2.666-3.215 2.666-.953 0-2.279-1.146-2.54-1.146-.282 0-.802.132-.967.483-.693 1.467 1.158 6.363 3.014 6.363.992 0 2.175-.728 3.213-1.176-.27 1.764-.308 3.251.05 4.77a5.674 5.674 0 01-2.942.85c-4.56 0-2.045-10.605-6.25-12.865z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path
        d="M64.209 56.154c3.447 0 4.808 6.2 3.261 9.476-1.2 2.542-3.327 4.384-6.052 4.384-4.812 0-4.21-6.56-2.332-10.538.518-1.096 2.287-3.484 3.67-3.484.51 0 .93.162 1.453.162zm-.107.579c-.542 0-1.02.268-1.27.802-.53 1.118 2.105 2.25 1.727 3.051-.238.504-1.144.976-1.601.976-2.284 0-2.265-2.972-.903-4.908-.847.539-1.402 1.363-1.762 2.127-1.338 2.835-.276 6.734 2.778 6.734 1.688 0 3.631-1.516 4.387-3.117.93-1.968-1.448-5.665-3.356-5.665"
        fillRule="evenodd"
      />

      <path
        d="M64.209 56.154c3.447 0 4.808 6.2 3.261 9.476-1.2 2.542-3.327 4.384-6.052 4.384-4.812 0-4.21-6.56-2.332-10.538.518-1.096 2.287-3.484 3.67-3.484.51 0 .93.162 1.453.162z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path
        d="M64.101 56.733c-.542 0-1.02.268-1.271.802-.528 1.119 2.106 2.25 1.728 3.051-.238.504-1.144.976-1.601.976-2.284 0-2.265-2.972-.903-4.908-.847.54-1.402 1.363-1.762 2.127-1.339 2.835-.277 6.735 2.778 6.735 1.687 0 3.631-1.517 4.387-3.117.93-1.968-1.448-5.666-3.356-5.666z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path
        d="M79.875 66.36c-.901 1.908-2.58 2.955-4.47 2.955-5.997 0-7.187-5.469-4.587-10.975.613-1.3 1.62-2.737 3.159-2.737 4.818 0 8.174 5.933 5.897 10.757zM75.09 56.293c-.443 0-.83.16-1.043.61-.466.989 2.072 1.87 2.075 2.892-.265.314-1.007.665-1.33.665-1.828 0-3.208-2.478-1.647-4.32-.634.403-1.01 1.005-1.277 1.57-1.582 3.352.673 6.74 3.96 6.74 1.368 0 3.13-.76 3.841-2.266 1.006-2.13-2.576-5.89-4.58-5.89"
        fillRule="evenodd"
      />

      <path
        d="M79.875 66.36c-.901 1.908-2.58 2.955-4.47 2.955-5.997 0-7.187-5.469-4.587-10.975.613-1.3 1.62-2.737 3.159-2.737 4.818 0 8.174 5.933 5.897 10.757z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path
        d="M75.089 56.294c-.442 0-.83.16-1.043.61-.466.988 2.072 1.87 2.076 2.892-.265.313-1.008.665-1.331.665-1.828 0-3.208-2.478-1.647-4.32-.633.402-1.01 1.004-1.277 1.57-1.582 3.352.674 6.74 3.96 6.74 1.368 0 3.131-.76 3.842-2.266 1.005-2.13-2.576-5.89-4.58-5.89z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path d="M86.077 56.262c5.168 0 7.795 9.484 5.485 14.376-.441.935-1.264 1.87-2.35 1.87-5.904 0-4.397-11.737-7.72-15.072.303-.323.594-.66.861-1 2.246 2.635 1.13 9.572 5.927 9.572 1.105 0 1.941-.967 2.387-1.912 1.191-2.523-1.013-6.906-3.426-6.906-.024 0-.37.014-.363.154.09 1.611 1.356.607.08 3.348-1.303-.26-2.684-2.353-1.971-3.863.21-.444.734-.567 1.09-.567" />

      <path
        d="M86.077 56.262c5.168 0 7.795 9.484 5.485 14.376-.441.935-1.264 1.87-2.35 1.87-5.904 0-4.397-11.737-7.72-15.072.303-.323.594-.66.861-1 2.246 2.635 1.13 9.572 5.927 9.572 1.105 0 1.941-.967 2.387-1.912 1.191-2.523-1.013-6.906-3.426-6.906-.024 0-.37.014-.363.154.09 1.611 1.356.607.08 3.348-1.303-.26-2.684-2.353-1.971-3.863.21-.444.734-.567 1.09-.567z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path
        d="M98.927 56.154c.487.046 1.218.002 1.636.13.078.023.019.121.032.199.127.716.327 1.396.5 2.089.673 2.698 1.078 5.468 1.85 8.142.841 2.912 1.718 6.194.7 9.848-.1.357-.233.72-.405 1.085-1.154 2.445-11.091 5.262-13.686 5.262-.315 0-.655-.09-.805-.295 1.094-.696 1.78-1.41 2.27-2.446.585-1.242.577-2.406.5-3.477.207.12 7.842-.068 8.118-.068 1.123 0 2.086-1.085 2.536-2.04.827-1.751.686-3.539.543-5.031a23.224 23.224 0 00-.312-2.2l-.21-1.036-.027-.113-.026-.114c.125 0 .013.22.018.331.077 1.806.044 4.328-.926 6.384-.451.955-1.189 1.94-2.32 1.94-4.963 0-2.467-14.074-6.444-17.42.22-.363.417-.738.582-1.105 2.353 2.842 2.477 10.677 5.94 10.677.84 0 1.71-.788 2.067-1.545 1.539-3.26-1.017-7.057-2.13-9.197"
        fillRule="evenodd"
      />

      <path
        d="M98.927 56.154c.487.046 1.218.002 1.636.13.078.023.019.121.032.199.127.716.327 1.396.5 2.089.673 2.698 1.078 5.468 1.85 8.142.841 2.912 1.718 6.194.7 9.848-.1.357-.233.72-.405 1.085-1.154 2.445-11.091 5.262-13.686 5.262-.315 0-.655-.09-.805-.295 1.094-.696 1.78-1.41 2.27-2.446.585-1.242.577-2.406.5-3.477.207.12 7.842-.068 8.118-.068 1.123 0 2.086-1.085 2.536-2.04.827-1.751.686-3.539.543-5.031a23.224 23.224 0 00-.312-2.2l-.21-1.036-.027-.113-.026-.114c.125 0 .013.22.018.331.077 1.806.044 4.328-.926 6.384-.451.955-1.189 1.94-2.32 1.94-4.963 0-2.467-14.074-6.444-17.42.22-.363.417-.738.582-1.105 2.353 2.842 2.477 10.677 5.94 10.677.84 0 1.71-.788 2.067-1.545 1.539-3.26-1.017-7.057-2.13-9.197z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path d="M37.804 44.66c3.413 0 1.424 8.528.21 11.1-1.04 2.201-3.38 5.377-6.153 5.377-3.327 0-3.228-3.727-1.904-6.532.717-1.52 1.82-3.222 3.338-4.474-.39 1.307.925 2.2.393 3.325-.363.769-1.114 1.609-1.504 2.436-.631 1.337-.39 3.71 1.135 3.71 1.365 0 2.578-1.692 3.075-2.745 1.019-2.158 3.566-11.366.719-11.366-3.28 0-8.93 7.99-10.113 10.497-2.112 4.474-.58 9.984 4.231 9.984 3.252 0 5.926-3.126 7.218-5.862a14.931 14.931 0 001.353-4.781l.632-.116c.139 4.21 4.592 11.18 2.697 15.196-2.427 5.14-9.681 5.094-13.824 6.918-.87-2.037-2.05-3.93-3.711-5.376 3.695-1.243 12.775-3.33 14.75-7.512.83-1.758.264-4.671-.694-5.659a10.916 10.916 0 01-.689 1.89c-1.69 3.58-5.31 6.357-9.041 6.357-5.196 0-7.398-3.733-4.953-8.913 1.467-3.109 8.96-13.455 12.835-13.455" />

      <path
        d="M37.804 44.66c3.413 0 1.424 8.528.21 11.1-1.04 2.201-3.38 5.377-6.153 5.377-3.327 0-3.228-3.727-1.904-6.532.717-1.52 1.82-3.222 3.338-4.474-.39 1.307.925 2.2.393 3.325-.363.769-1.114 1.609-1.504 2.436-.631 1.337-.39 3.71 1.135 3.71 1.365 0 2.578-1.692 3.075-2.745 1.019-2.158 3.566-11.366.719-11.366-3.28 0-8.93 7.99-10.113 10.497-2.112 4.474-.58 9.984 4.231 9.984 3.252 0 5.926-3.126 7.218-5.862a14.931 14.931 0 001.353-4.781l.632-.116c.139 4.21 4.592 11.18 2.697 15.196-2.427 5.14-9.681 5.094-13.824 6.918-.87-2.037-2.05-3.93-3.711-5.376 3.695-1.243 12.775-3.33 14.75-7.512.83-1.758.264-4.671-.694-5.659a10.916 10.916 0 01-.689 1.89c-1.69 3.58-5.31 6.357-9.041 6.357-5.196 0-7.398-3.733-4.953-8.913 1.467-3.109 8.96-13.455 12.835-13.455z"
        fill="none"
        stroke="#000"
        strokeMiterlimit="3.864"
        strokeWidth=".47650595999999995"
      />

      <path d="M46.681 57.002c.206.508.803 3.38 1.256 3.38.979 0 2.748-2.663 3.222-3.355.87.57.388 1.767 2 1.767.73 0 1.098-1.573 1.858-1.599.026.636.067 1.211-.28 1.946-.534 1.132-1.886 2.666-3.215 2.666-.953 0-2.279-1.146-2.54-1.146-.282 0-.802.132-.967.483-.693 1.467 1.158 6.363 3.014 6.363.992 0 2.175-.728 3.213-1.176-.27 1.764-.308 3.251.05 4.77a5.674 5.674 0 01-2.942.85c-4.56 0-2.045-10.605-6.25-12.865l1.58-2.084" />

      <path
        d="M46.681 57.002c.206.508.803 3.38 1.256 3.38.979 0 2.748-2.663 3.222-3.355.87.57.388 1.767 2 1.767.73 0 1.098-1.573 1.858-1.599.026.636.067 1.211-.28 1.946-.534 1.132-1.886 2.666-3.215 2.666-.953 0-2.279-1.146-2.54-1.146-.282 0-.802.132-.967.483-.693 1.467 1.158 6.363 3.014 6.363.992 0 2.175-.728 3.213-1.176-.27 1.764-.308 3.251.05 4.77a5.674 5.674 0 01-2.942.85c-4.56 0-2.045-10.605-6.25-12.865z"
        fill="none"
        stroke="#000"
        strokeMiterlimit="3.864"
        strokeWidth=".47650595999999995"
      />

      <path
        d="M64.209 56.154c3.447 0 4.808 6.2 3.261 9.476-1.2 2.542-3.327 4.384-6.052 4.384-4.812 0-4.21-6.56-2.332-10.538.518-1.096 2.287-3.484 3.67-3.484.51 0 .93.162 1.453.162zm-.107.579c-.542 0-1.02.268-1.27.802-.53 1.118 2.105 2.25 1.727 3.051-.238.504-1.144.976-1.601.976-2.284 0-2.265-2.972-.903-4.908-.847.539-1.402 1.363-1.762 2.127-1.338 2.835-.276 6.734 2.778 6.734 1.688 0 3.631-1.516 4.387-3.117.93-1.968-1.448-5.665-3.356-5.665"
        fillRule="evenodd"
      />

      <path
        d="M64.209 56.154c3.447 0 4.808 6.2 3.261 9.476-1.2 2.542-3.327 4.384-6.052 4.384-4.812 0-4.21-6.56-2.332-10.538.518-1.096 2.287-3.484 3.67-3.484.51 0 .93.162 1.453.162z"
        fill="none"
        stroke="#000"
        strokeMiterlimit="3.864"
        strokeWidth=".47650595999999995"
      />

      <path
        d="M64.101 56.733c-.542 0-1.02.268-1.271.802-.528 1.119 2.106 2.25 1.728 3.051-.238.504-1.144.976-1.601.976-2.284 0-2.265-2.972-.903-4.908-.847.54-1.402 1.363-1.762 2.127-1.339 2.835-.277 6.735 2.778 6.735 1.687 0 3.631-1.517 4.387-3.117.93-1.968-1.448-5.666-3.356-5.666z"
        fill="none"
        stroke="#000"
        strokeMiterlimit="3.864"
        strokeWidth=".47650595999999995"
      />

      <path
        d="M79.875 66.36c-.901 1.908-2.58 2.955-4.47 2.955-5.997 0-7.187-5.469-4.587-10.975.613-1.3 1.62-2.737 3.159-2.737 4.818 0 8.174 5.933 5.897 10.757zM75.09 56.293c-.443 0-.83.16-1.043.61-.466.989 2.072 1.87 2.075 2.892-.265.314-1.007.665-1.33.665-1.828 0-3.208-2.478-1.647-4.32-.634.403-1.01 1.005-1.277 1.57-1.582 3.352.673 6.74 3.96 6.74 1.368 0 3.13-.76 3.841-2.266 1.006-2.13-2.576-5.89-4.58-5.89"
        fillRule="evenodd"
      />

      <path
        d="M79.875 66.36c-.901 1.908-2.58 2.955-4.47 2.955-5.997 0-7.187-5.469-4.587-10.975.613-1.3 1.62-2.737 3.159-2.737 4.818 0 8.174 5.933 5.897 10.757z"
        fill="none"
        stroke="#000"
        strokeMiterlimit="3.864"
        strokeWidth=".47650595999999995"
      />

      <path
        d="M75.089 56.294c-.442 0-.83.16-1.043.61-.466.988 2.072 1.87 2.076 2.892-.265.313-1.008.665-1.331.665-1.828 0-3.208-2.478-1.647-4.32-.633.402-1.01 1.004-1.277 1.57-1.582 3.352.674 6.74 3.96 6.74 1.368 0 3.131-.76 3.842-2.266 1.005-2.13-2.576-5.89-4.58-5.89z"
        fill="none"
        stroke="#000"
        strokeMiterlimit="3.864"
        strokeWidth=".47650595999999995"
      />

      <path d="M86.077 56.262c5.168 0 7.795 9.484 5.485 14.376-.441.935-1.264 1.87-2.35 1.87-5.904 0-4.397-11.737-7.72-15.072.303-.323.594-.66.861-1 2.246 2.635 1.13 9.572 5.927 9.572 1.105 0 1.941-.967 2.387-1.912 1.191-2.523-1.013-6.906-3.426-6.906-.024 0-.37.014-.363.154.09 1.611 1.356.607.08 3.348-1.303-.26-2.684-2.353-1.971-3.863.21-.444.734-.567 1.09-.567" />

      <path
        d="M86.077 56.262c5.168 0 7.795 9.484 5.485 14.376-.441.935-1.264 1.87-2.35 1.87-5.904 0-4.397-11.737-7.72-15.072.303-.323.594-.66.861-1 2.246 2.635 1.13 9.572 5.927 9.572 1.105 0 1.941-.967 2.387-1.912 1.191-2.523-1.013-6.906-3.426-6.906-.024 0-.37.014-.363.154.09 1.611 1.356.607.08 3.348-1.303-.26-2.684-2.353-1.971-3.863.21-.444.734-.567 1.09-.567z"
        fill="none"
        stroke="#000"
        strokeMiterlimit="3.864"
        strokeWidth=".47650595999999995"
      />

      <path
        d="M98.927 56.154c.487.046 1.218.002 1.636.13.078.023.019.121.032.199.127.716.327 1.396.5 2.089.673 2.698 1.078 5.468 1.85 8.142.841 2.912 1.718 6.194.7 9.848-.1.357-.233.72-.405 1.085-1.154 2.445-4.12 4.772-6.716 4.772-.315 0-.655-.09-.806-.294 1.096-.696 1.78-1.41 2.27-2.446.587-1.243.578-2.407.5-3.478.208.12.873.422 1.15.422 1.122 0 2.085-1.085 2.536-2.04.827-1.751.685-3.539.542-5.031a23.224 23.224 0 00-.312-2.2l-.21-1.036-.027-.113-.026-.114c.126 0 .013.22.018.331.077 1.806.045 4.328-.926 6.384-.45.955-1.188 1.94-2.32 1.94-4.963 0-2.467-14.074-6.444-17.42.22-.363.417-.738.582-1.105 2.354 2.842 2.477 10.677 5.94 10.677.841 0 1.71-.788 2.067-1.545 1.54-3.26-1.017-7.057-2.13-9.197"
        fillRule="evenodd"
      />

      <path
        d="M98.927 56.154c.487.046 1.218.002 1.636.13.078.023.019.121.032.199.127.716.327 1.396.5 2.089.673 2.698 1.078 5.468 1.85 8.142.841 2.912 1.718 6.194.7 9.848-.1.357-.233.72-.405 1.085-1.154 2.445-4.12 4.772-6.716 4.772-.315 0-.655-.09-.806-.294 1.096-.696 1.78-1.41 2.27-2.446.587-1.243.578-2.407.5-3.478.208.12.873.422 1.15.422 1.122 0 2.085-1.085 2.536-2.04.827-1.751.685-3.539.542-5.031a23.224 23.224 0 00-.312-2.2l-.21-1.036-.027-.113-.026-.114c.126 0 .013.22.018.331.077 1.806.045 4.328-.926 6.384-.45.955-1.188 1.94-2.32 1.94-4.963 0-2.467-14.074-6.444-17.42.22-.363.417-.738.582-1.105 2.354 2.842 2.477 10.677 5.94 10.677.841 0 1.71-.788 2.067-1.545 1.54-3.26-1.017-7.057-2.13-9.197z"
        fill="none"
        stroke="#000"
        strokeMiterlimit="3.864"
        strokeWidth=".47650595999999995"
      />

      <path d="M37.804 44.66c3.413 0 1.424 8.528.21 11.1-1.04 2.201-3.38 5.377-6.153 5.377-3.327 0-3.228-3.727-1.904-6.532.717-1.52 1.82-3.222 3.338-4.474-.39 1.307.925 2.2.393 3.325-.363.769-1.114 1.609-1.504 2.436-.631 1.337-.39 3.71 1.135 3.71 1.365 0 2.578-1.692 3.075-2.745 1.019-2.158 3.566-11.366.719-11.366-3.28 0-8.93 7.99-10.113 10.497-2.112 4.474-.58 9.984 4.231 9.984 3.252 0 5.926-3.126 7.218-5.862a14.931 14.931 0 001.353-4.781l.632-.116c.139 4.21 4.592 11.18 2.697 15.196-2.427 5.14-9.681 5.094-13.824 6.918-.87-2.037-2.05-3.93-3.711-5.376 3.695-1.243 12.775-3.33 14.75-7.512.83-1.758.264-4.671-.694-5.659a10.916 10.916 0 01-.689 1.89c-1.69 3.58-5.31 6.357-9.041 6.357-5.196 0-7.398-3.733-4.953-8.913 1.467-3.109 8.96-13.455 12.835-13.455" />

      <path
        d="M37.804 44.66c3.413 0 1.424 8.528.21 11.1-1.04 2.201-3.38 5.377-6.153 5.377-3.327 0-3.228-3.727-1.904-6.532.717-1.52 1.82-3.222 3.338-4.474-.39 1.307.925 2.2.393 3.325-.363.769-1.114 1.609-1.504 2.436-.631 1.337-.39 3.71 1.135 3.71 1.365 0 2.578-1.692 3.075-2.745 1.019-2.158 3.566-11.366.719-11.366-3.28 0-8.93 7.99-10.113 10.497-2.112 4.474-.58 9.984 4.231 9.984 3.252 0 5.926-3.126 7.218-5.862a14.931 14.931 0 001.353-4.781l.632-.116c.139 4.21 4.592 11.18 2.697 15.196-2.427 5.14-9.681 5.094-13.824 6.918-.87-2.037-2.05-3.93-3.711-5.376 3.695-1.243 12.775-3.33 14.75-7.512.83-1.758.264-4.671-.694-5.659a10.916 10.916 0 01-.689 1.89c-1.69 3.58-5.31 6.357-9.041 6.357-5.196 0-7.398-3.733-4.953-8.913 1.467-3.109 8.96-13.455 12.835-13.455z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path d="M46.681 57.002c.206.508.803 3.38 1.256 3.38.979 0 2.748-2.663 3.222-3.355.87.57.388 1.767 2 1.767.73 0 1.098-1.573 1.858-1.599.026.636.067 1.211-.28 1.946-.534 1.132-1.886 2.666-3.215 2.666-.953 0-2.279-1.146-2.54-1.146-.282 0-.802.132-.967.483-.693 1.467 1.158 6.363 3.014 6.363.992 0 2.175-.728 3.213-1.176-.27 1.764-.308 3.251.05 4.77a5.674 5.674 0 01-2.942.85c-4.56 0-2.045-10.605-6.25-12.865l1.58-2.084" />

      <path
        d="M46.681 57.002c.206.508.803 3.38 1.256 3.38.979 0 2.748-2.663 3.222-3.355.87.57.388 1.767 2 1.767.73 0 1.098-1.573 1.858-1.599.026.636.067 1.211-.28 1.946-.534 1.132-1.886 2.666-3.215 2.666-.953 0-2.279-1.146-2.54-1.146-.282 0-.802.132-.967.483-.693 1.467 1.158 6.363 3.014 6.363.992 0 2.175-.728 3.213-1.176-.27 1.764-.308 3.251.05 4.77a5.674 5.674 0 01-2.942.85c-4.56 0-2.045-10.605-6.25-12.865z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path
        d="M64.209 56.154c3.447 0 4.808 6.2 3.261 9.476-1.2 2.542-3.327 4.384-6.052 4.384-4.812 0-4.21-6.56-2.332-10.538.518-1.096 2.287-3.484 3.67-3.484.51 0 .93.162 1.453.162zm-.107.579c-.542 0-1.02.268-1.27.802-.53 1.118 2.105 2.25 1.727 3.051-.238.504-1.144.976-1.601.976-2.284 0-2.265-2.972-.903-4.908-.847.539-1.402 1.363-1.762 2.127-1.338 2.835-.276 6.734 2.778 6.734 1.688 0 3.631-1.516 4.387-3.117.93-1.968-1.448-5.665-3.356-5.665"
        fillRule="evenodd"
      />

      <path
        d="M64.209 56.154c3.447 0 4.808 6.2 3.261 9.476-1.2 2.542-3.327 4.384-6.052 4.384-4.812 0-4.21-6.56-2.332-10.538.518-1.096 2.287-3.484 3.67-3.484.51 0 .93.162 1.453.162z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path
        d="M64.101 56.733c-.542 0-1.02.268-1.271.802-.528 1.119 2.106 2.25 1.728 3.051-.238.504-1.144.976-1.601.976-2.284 0-2.265-2.972-.903-4.908-.847.54-1.402 1.363-1.762 2.127-1.339 2.835-.277 6.735 2.778 6.735 1.687 0 3.631-1.517 4.387-3.117.93-1.968-1.448-5.666-3.356-5.666z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path
        d="M79.875 66.36c-.901 1.908-2.58 2.955-4.47 2.955-5.997 0-7.187-5.469-4.587-10.975.613-1.3 1.62-2.737 3.159-2.737 4.818 0 8.174 5.933 5.897 10.757zM75.09 56.293c-.443 0-.83.16-1.043.61-.466.989 2.072 1.87 2.075 2.892-.265.314-1.007.665-1.33.665-1.828 0-3.208-2.478-1.647-4.32-.634.403-1.01 1.005-1.277 1.57-1.582 3.352.673 6.74 3.96 6.74 1.368 0 3.13-.76 3.841-2.266 1.006-2.13-2.576-5.89-4.58-5.89"
        fillRule="evenodd"
      />

      <path
        d="M79.875 66.36c-.901 1.908-2.58 2.955-4.47 2.955-5.997 0-7.187-5.469-4.587-10.975.613-1.3 1.62-2.737 3.159-2.737 4.818 0 8.174 5.933 5.897 10.757z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path
        d="M75.089 56.294c-.442 0-.83.16-1.043.61-.466.988 2.072 1.87 2.076 2.892-.265.313-1.008.665-1.331.665-1.828 0-3.208-2.478-1.647-4.32-.633.402-1.01 1.004-1.277 1.57-1.582 3.352.674 6.74 3.96 6.74 1.368 0 3.131-.76 3.842-2.266 1.005-2.13-2.576-5.89-4.58-5.89z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path d="M86.077 56.262c5.168 0 7.795 9.484 5.485 14.376-.441.935-1.264 1.87-2.35 1.87-5.904 0-4.397-11.737-7.72-15.072.303-.323.594-.66.861-1 2.246 2.635 1.13 9.572 5.927 9.572 1.105 0 1.941-.967 2.387-1.912 1.191-2.523-1.013-6.906-3.426-6.906-.024 0-.37.014-.363.154.09 1.611 1.356.607.08 3.348-1.303-.26-2.684-2.353-1.971-3.863.21-.444.734-.567 1.09-.567" />

      <path
        d="M86.077 56.262c5.168 0 7.795 9.484 5.485 14.376-.441.935-1.264 1.87-2.35 1.87-5.904 0-4.397-11.737-7.72-15.072.303-.323.594-.66.861-1 2.246 2.635 1.13 9.572 5.927 9.572 1.105 0 1.941-.967 2.387-1.912 1.191-2.523-1.013-6.906-3.426-6.906-.024 0-.37.014-.363.154.09 1.611 1.356.607.08 3.348-1.303-.26-2.684-2.353-1.971-3.863.21-.444.734-.567 1.09-.567z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <path
        d="M98.927 56.154c.487.046 1.218.002 1.636.13.078.023.019.121.032.199.127.716.327 1.396.5 2.089.673 2.698 1.078 5.468 1.85 8.142.841 2.912 1.718 6.194.7 9.848-.1.357-.233.72-.405 1.085-1.154 2.445-4.12 4.772-6.716 4.772-.315 0-.655-.09-.806-.294 1.096-.696 1.78-1.41 2.27-2.446.587-1.243.578-2.407.5-3.478.208.12.873.422 1.15.422 1.122 0 2.085-1.085 2.536-2.04.827-1.751.685-3.539.542-5.031a23.224 23.224 0 00-.312-2.2l-.21-1.036-.027-.113-.026-.114c.126 0 .013.22.018.331.077 1.806.045 4.328-.926 6.384-.45.955-1.188 1.94-2.32 1.94-4.963 0-2.467-14.074-6.444-17.42.22-.363.417-.738.582-1.105 2.354 2.842 2.477 10.677 5.94 10.677.841 0 1.71-.788 2.067-1.545 1.54-3.26-1.017-7.057-2.13-9.197"
        fillRule="evenodd"
      />

      <path
        d="M98.927 56.154c.487.046 1.218.002 1.636.13.078.023.019.121.032.199.127.716.327 1.396.5 2.089.673 2.698 1.078 5.468 1.85 8.142.841 2.912 1.718 6.194.7 9.848-.1.357-.233.72-.405 1.085-1.154 2.445-4.12 4.772-6.716 4.772-.315 0-.655-.09-.806-.294 1.096-.696 1.78-1.41 2.27-2.446.587-1.243.578-2.407.5-3.478.208.12.873.422 1.15.422 1.122 0 2.085-1.085 2.536-2.04.827-1.751.685-3.539.542-5.031a23.224 23.224 0 00-.312-2.2l-.21-1.036-.027-.113-.026-.114c.126 0 .013.22.018.331.077 1.806.045 4.328-.926 6.384-.45.955-1.188 1.94-2.32 1.94-4.963 0-2.467-14.074-6.444-17.42.22-.363.417-.738.582-1.105 2.354 2.842 2.477 10.677 5.94 10.677.841 0 1.71-.788 2.067-1.545 1.54-3.26-1.017-7.057-2.13-9.197z"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth="5.7179996"
      />

      <g fill="#fff">
        <path d="M37.804 44.66c3.413 0 1.424 8.528.21 11.1-1.04 2.201-3.38 5.377-6.153 5.377-3.327 0-3.228-3.727-1.904-6.532.717-1.52 1.82-3.222 3.338-4.474-.39 1.307.925 2.2.393 3.325-.363.769-1.114 1.609-1.504 2.436-.631 1.337-.39 3.71 1.135 3.71 1.365 0 2.578-1.692 3.075-2.745 1.019-2.158 3.566-11.366.719-11.366-3.28 0-8.93 7.99-10.113 10.497-2.112 4.474-.58 9.984 4.231 9.984 3.252 0 5.926-3.126 7.218-5.862a14.931 14.931 0 001.353-4.781l.632-.116c.139 4.21 4.592 11.18 2.697 15.196-2.427 5.14-9.681 5.094-13.824 6.918-.87-2.037-2.05-3.93-3.711-5.376 3.695-1.243 12.775-3.33 14.75-7.512.83-1.758.264-4.671-.694-5.659a10.916 10.916 0 01-.689 1.89c-1.69 3.58-5.31 6.357-9.041 6.357-5.196 0-7.398-3.733-4.953-8.913 1.467-3.109 8.96-13.455 12.835-13.455M46.681 57.002c.206.508.803 3.38 1.256 3.38.979 0 2.748-2.663 3.222-3.355.87.57.388 1.767 2 1.767.73 0 1.098-1.573 1.858-1.599.026.636.067 1.211-.28 1.946-.534 1.132-1.886 2.666-3.215 2.666-.953 0-2.279-1.146-2.54-1.146-.282 0-.802.132-.967.483-.693 1.467 1.158 6.363 3.014 6.363.992 0 2.175-.728 3.213-1.176-.27 1.764-.308 3.251.05 4.77a5.674 5.674 0 01-2.942.85c-4.56 0-2.045-10.605-6.25-12.865l1.58-2.084" />

        <path
          d="M64.209 56.154c3.447 0 4.808 6.2 3.261 9.476-1.2 2.542-3.327 4.384-6.052 4.384-4.812 0-4.21-6.56-2.332-10.538.518-1.096 2.287-3.484 3.67-3.484.51 0 .93.162 1.453.162zm-.107.579c-.542 0-1.02.268-1.27.802-.53 1.118 2.105 2.25 1.727 3.051-.238.504-1.144.976-1.601.976-2.284 0-2.265-2.972-.903-4.908-.847.539-1.402 1.363-1.762 2.127-1.338 2.835-.276 6.734 2.778 6.734 1.688 0 3.631-1.516 4.387-3.117.93-1.968-1.448-5.665-3.356-5.665M79.875 66.36c-.901 1.908-2.58 2.955-4.47 2.955-5.997 0-7.187-5.469-4.587-10.975.613-1.3 1.62-2.737 3.159-2.737 4.818 0 8.174 5.933 5.897 10.757zM75.09 56.293c-.443 0-.83.16-1.043.61-.466.989 2.072 1.87 2.075 2.892-.265.314-1.007.665-1.33.665-1.828 0-3.208-2.478-1.647-4.32-.634.403-1.01 1.005-1.277 1.57-1.582 3.352.673 6.74 3.96 6.74 1.368 0 3.13-.76 3.841-2.266 1.006-2.13-2.576-5.89-4.58-5.89"
          fillRule="evenodd"
        />

        <path d="M86.077 56.262c5.168 0 7.795 9.484 5.485 14.376-.441.935-1.264 1.87-2.35 1.87-5.904 0-4.397-11.737-7.72-15.072.303-.323.594-.66.861-1 2.246 2.635 1.13 9.572 5.927 9.572 1.105 0 1.941-.967 2.387-1.912 1.191-2.523-1.013-6.906-3.426-6.906-.024 0-.37.014-.363.154.09 1.611 1.356.607.08 3.348-1.303-.26-2.684-2.353-1.971-3.863.21-.444.734-.567 1.09-.567" />

        <path
          d="M98.927 56.154c.487.046 1.218.002 1.636.13.078.023.019.121.032.199.127.716.327 1.396.5 2.089.673 2.698 1.078 5.468 1.85 8.142.841 2.912 1.718 6.194.7 9.848-.1.357-.233.72-.405 1.085-3.87 7.432-12.151 5.43-14.745 5.43-.315 0-.656-.09-.806-.295 1.094-.696 1.78-1.41 2.27-2.446.586-1.243.577-2.406.5-3.478.207.121.872.422 1.148.422 1.123 0 7.378 3.214 10.567-2.696.827-1.752.685-3.54.542-5.032a23.224 23.224 0 00-.312-2.2l-.21-1.036-.027-.113-.026-.113c.126 0 .013.22.018.33.077 1.807.045 4.328-.926 6.384-.45.955-1.188 1.94-2.32 1.94-4.963 0-2.467-14.074-6.444-17.42.22-.363.417-.738.582-1.105 2.354 2.842 2.477 10.677 5.94 10.677.841 0 1.71-.787 2.068-1.545 1.538-3.26-1.018-7.056-2.132-9.197"
          fillRule="evenodd"
        />
      </g>
    </Icon>
  );
});

export const GroovyIconMetadata = {
  name: 'Groovy',
  importName: 'GroovyIcon',
  component: <GroovyIcon aria-label="Groovy Icon" />,
  tags: 'Groovy, programming, language, logo, application',
};
