// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const AzureIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 16 16" {...props} fill="none" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3702 13.6804L11.3702 1.68038C11.3006 1.4734 11.1652 1.29489 10.9846 1.1721C10.8041 1.04931 10.5883 0.989001 10.3702 1.00038H5.63021C5.42056 0.999842 5.21605 1.0652 5.04558 1.18723C4.87511 1.30925 4.74729 1.48176 4.68021 1.68038L0.630208 13.6804C0.577689 13.8351 0.563887 14.0004 0.59001 14.1617C0.616133 14.323 0.681385 14.4754 0.780056 14.6056C0.878728 14.7359 1.00781 14.84 1.15602 14.9088C1.30423 14.9776 1.46704 15.0091 1.63021 15.0004H4.56021C4.76814 14.9988 4.97042 14.9325 5.1389 14.8106C5.30738 14.6888 5.43367 14.5174 5.50021 14.3204L6.11021 12.5404L9.11021 14.8104C9.28455 14.9366 9.49497 15.0033 9.71021 15.0004H14.3902C14.5517 15.0057 14.7121 14.9717 14.8577 14.9015C15.0032 14.8313 15.1295 14.7268 15.2259 14.597C15.3222 14.4673 15.3857 14.3161 15.4108 14.1565C15.4359 13.9968 15.422 13.8335 15.3702 13.6804ZM9.75021 14.3404C9.67753 14.3404 9.60699 14.3158 9.55021 14.2704L3.90021 10.0804L3.81021 10.0104H6.81021L6.89021 9.80038L7.89021 7.27038L10.1302 13.9004C10.1482 13.956 10.1516 14.0153 10.14 14.0725C10.1284 14.1298 10.1023 14.1831 10.0641 14.2273C10.0259 14.2716 9.97692 14.3052 9.92195 14.325C9.86698 14.3448 9.80783 14.3501 9.75021 14.3404ZM14.4202 14.3404H10.7002C10.7749 14.1267 10.7749 13.894 10.7002 13.6804L6.65021 1.68038H10.3702C10.4408 1.68074 10.5096 1.70308 10.5669 1.74429C10.6243 1.78551 10.6674 1.84356 10.6902 1.91038L14.7402 13.9104C14.7539 13.9602 14.7561 14.0125 14.7465 14.0633C14.737 14.1141 14.716 14.162 14.6851 14.2035C14.6543 14.2449 14.6144 14.2788 14.5685 14.3025C14.5226 14.3262 14.4719 14.3392 14.4202 14.3404Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const AzureIconMetadata = {
  name: 'Azure Logo',
  importName: 'AzureIcon',
  component: <AzureIcon aria-label="Azure Logo Icon" />,
  tags: 'azure, microsoft, logo, brand, company, org, organization, version, control, vc',
};
