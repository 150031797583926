// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const CisIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 601 601" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="m288.81 0 6.72-.04-1.19 12.07a.51.49 5.7 0 0 .46.54q4.5.41-2.3.64l-10.75.5-2.22-.25a.74.45 3.5 0 1-.65-.45l-.75-10.25a.87.59-13.4 0 1 .62-.68q1.32-.41 1.25-1.54l8.81-.54Z"
      />

      <path
        fill="currentColor"
        d="m295.53-.04 17.28.04 11.44.79-1.59.49a1.09.6-13.3 0 0-.81.73l.2 11.24a.46.21 86.7 0 1-.17.44l-1.13.27q-14.11-.9-28.25-.75 6.8-.23 2.3-.64a.51.49 5.7 0 1-.46-.54l1.19-12.07Z"
      />

      <path
        fill="currentColor"
        d="M280 .54q.07 1.13-1.25 1.54a.87.59-13.4 0 0-.62.68l.75 10.25a.74.45 3.5 0 0 .65.45l2.22.25q-16.94 1.48-33.71 4.29l-.22-10.94a1.34 1.07-29.3 0 1 .34-.84q.97-1.09-.41-1.68 16.02-2.82 32.25-4Z"
      />

      <path
        fill="transparent"
        d="M324.25.79c85.54 6.98 164.66 50.1 216.2 118.8q55.89 74.5 60.55 168.97v24c-6.93 150.91-122.53 273.09-272.96 287.19q-2.66-.52-5.34.14a.67.55-17.4 0 1-.74-.3q-.23-.52.15-.96a1.44.91-33.8 0 0 .37-.83l.02-10.76q88.54-7.65 156.57-61.72c81.98-65.17 122.83-171.14 104.36-274.51C560.22 120.83 452.17 23.78 320.75 13.96l1.13-.27a.46.21 86.7 0 0 .17-.44l-.2-11.24a1.09.6-13.3 0 1 .81-.73l1.59-.49Z"
      />

      <path
        fill="currentColor"
        d="M247.75 4.54q1.38.59.41 1.68a1.34 1.07-29.3 0 0-.34.84l.22 10.94-20.83 4.73q-1.07-5.72-.63-11.5a1 .73 26.1 0 0-.39-.74L225 9.54q11.26-3 22.75-5Z"
      />

      <path
        fill="currentColor"
        d="m225 9.54 1.19.95a1 .73 26.1 0 1 .39.74q-.44 5.78.63 11.5-14.91 4.05-29.42 9.53.71-7.14-.24-14.12 13.39-5.08 27.45-8.6Z"
      />

      <path
        fill="currentColor"
        d="M197.55 18.14q.95 6.98.24 14.12l-10.85 4.34-.48-14.1 11.09-4.36Z"
      />

      <path
        fill="currentColor"
        d="m186.46 22.5.48 14.1-12.92 5.96q.25-7.28-.52-14.35 6.26-3.36 12.96-5.71Z"
      />

      <path
        fill="currentColor"
        d="M173.5 28.21q.77 7.07.52 14.35Q149.07 55.21 126.54 72q-.38-2.22.22-1.97a.95.95 0 0 0 1.32-.8q.65-7.82-.53-14.43 21.63-15.54 45.95-26.59Z"
      />

      <path
        fill="currentColor"
        d="M358.53 58.5q-3.13 3.57-7.71 4.68c-1.11.26-3.03-.62-3.14.97a1.02.63 87.9 0 1-.48.85l-3.17 1.08a.94.88-.1 0 0-.57.53q-.28.73-1.16.31a.93.74 39.2 0 0-.79-.01q-1.9 1.12.49 1.63C244.71 51.95 150.2 95.3 99.29 179.5q-2.07-.68-2.06 1.29a1.05.17-47.3 0 1-.48.72l-1.41 1.62a.52.19-52.3 0 1-.43.27c-1.31-.61-4.95 1.6-6.26 2.26-4.16 2.13-7.9 2.01-10.49 7.09a.92.7-72.7 0 1-.7.5h-.67C127.26 87.58 244 30.92 358.53 58.5Z"
      />

      <path
        fill="currentColor"
        d="M127.55 54.8q1.18 6.61.53 14.43a.95.95 0 0 1-1.32.8q-.6-.25-.22 1.97C-28.43 192.92-23.9 424.28 138.25 537.46l-2.62.16a.62.58-1.1 0 0-.59.57l-.06 9.81a.79.75 77 0 1-.41.69c-1.45.77 1.23 1.1.14 2.31C53.74 497.49 4.45 409.38 0 312.44v-23.75c4.64-93.97 50.32-179.8 127.55-233.89Z"
      />

      <path
        fill="currentColor"
        d="M358.53 58.5q20.38 4.7 39.51 13.25-1.35 1.48-3.33 1.43-.03 0-.89.4l-5.81 2.68q-.63.28-.91.59-.97 1.02-2.35.9a1.02.79-65.6 0 0-.8.42q-.58.8-.74 1.83-20.15-7.38-41.21-11.46-2.39-.51-.49-1.63a.93.74 39.2 0 1 .79.01q.88.42 1.16-.31a.94.88-.1 0 1 .57-.53L347.2 65a1.02.63 87.9 0 0 .48-.85c.11-1.59 2.03-.71 3.14-.97q4.58-1.11 7.71-4.68Z"
      />

      <path
        fill="currentColor"
        d="m398.04 71.75 13.51 6.2q-5.75 4.04-12.3 6.39a1.27.98-82.7 0 0-.64.64q-.29.67-.9 1.02l-14.5-6q.16-1.03.74-1.83a1.02.79-65.6 0 1 .8-.42q1.38.12 2.35-.9.28-.31.91-.59l5.81-2.68q.86-.4.89-.4 1.98.05 3.33-1.43Z"
      />

      <path
        fill="currentColor"
        d="m411.55 77.95 12.94 6.97q-6.11 2.3-11.87 5.39c-.32.17-1.05.9-1.38.81a.85.33 17.6 0 0-.78.05q-.34.42-.25 1.08L397.71 86q.61-.35.9-1.02a1.27.98-82.7 0 1 .64-.64q6.55-2.35 12.3-6.39Z"
      />

      <path
        fill="currentColor"
        d="M424.49 84.92q9.82 5.7 19.12 12.35-3.89 4.08-9.32 5.63-.03.01-.84.55-1 .68-2.2.54-.45-.05-.1.37.31.37.35.85-10.32-7.02-21.29-12.96-.09-.66.25-1.08a.85.33 17.6 0 1 .78-.05c.33.09 1.06-.64 1.38-.81q5.76-3.09 11.87-5.39Z"
      />

      <path
        fill="currentColor"
        d="m443.61 97.27 14.02 10.85-12.67 6.88-13.46-9.79q-.04-.48-.35-.85-.35-.42.1-.37 1.2.14 2.2-.54.81-.54.84-.55 5.43-1.55 9.32-5.63Z"
      />

      <path
        fill="currentColor"
        d="M457.63 108.12q8.8 6.86 16.87 15.09-2.94 2.82-5.99 3.35-2.95.52-4.57 2.23a1.03.29-50.2 0 1-.68.47l-.52-.02q-.51-.02-.08.51l2.05 2.5q-9.48-9.08-19.75-17.25l12.67-6.88Z"
      />

      <path
        fill="currentColor"
        d="M474.5 123.21q7.38 7.15 14 15-1.14.11-1.29.98a.85.66 88.5 0 1-.48.65q-5.34 1.81-9.85 5.43l-12.17-13.02-2.05-2.5q-.43-.53.08-.51l.52.02a1.03.29-50.2 0 0 .68-.47q1.62-1.71 4.57-2.23 3.05-.53 5.99-3.35Z"
      />

      <path
        fill="transparent"
        d="M488.5 138.21q52.29 61.79 58.82 142.13c9.57 117.76-68.03 226.68-181.82 257.65-142.19 38.7-287.35-55.65-309.56-200.86q3.69-2.49 7.8-3.41 1.08-.25 3.81-1.95a.27.24 72.3 0 1 .38.22q.07.82.78.01 13.19 86.26 76.95 142.61c69.36 61.28 169.08 76.12 253.53 36.83 86.85-40.41 140.72-130.56 135.12-226.22-3.04-51.96-23.47-100.58-57.43-139.95q4.51-3.62 9.85-5.43a.85.66 88.5 0 0 .48-.65q.15-.87 1.29-.98Z"
      />

      <path
        fill="currentColor"
        d="M99.29 179.5q-11.56 19.47-19.33 40.75-1.19 1.26-2.87 1.09a.89.82 20 0 0-.78.33c-2.46 3.31-9.29 5.13-13.16 7.22q5.35-18.34 13.64-35.64h.67a.92.7-72.7 0 0 .7-.5c2.59-5.08 6.33-4.96 10.49-7.09 1.31-.66 4.95-2.87 6.26-2.26a.52.19-52.3 0 0 .43-.27l1.41-1.62a1.05.17-47.3 0 0 .48-.72q-.01-1.97 2.06-1.29Z"
      />

      <path
        fill="currentColor"
        d="M246.38 220.77a.54.44 5.2 0 1 .48.47q-.84 15.26.04 30.52a.71.42 87.7 0 1-.4.74h-4.25a.87.43 85.4 0 1-.48-.8c-2.1-22.42-32.64-23.24-48.6-20.81-41.24 6.27-44.78 53.11-42.15 85.61 1.59 19.6 7.79 39.04 25.01 49.58 12.6 7.71 29.61 9.16 44.11 6.33q18.58-3.63 20.24-22.46a.76.62-87 0 1 .63-.7H245a.69.45-86.8 0 1 .43.74q-1.16 14.86-.9 29.76a1.13 1 83.1 0 1-.75 1.08c-45.95 11.79-112.65 7.54-127.81-48.39-10.6-39.11 1.9-80.87 38.03-101.92 27.14-15.81 61.76-13.87 92.38-9.75Zm174.81 104.04c-23.47-16.05-58.72-30.52-54.5-66.82 5.39-46.35 63.83-41.9 95.74-37a.66.66 0 0 1 .56.71c-.83 10.06-.15 20.16 0 30.24a.55.49 89.4 0 1-.49.56h-4.24a.88.53 82.7 0 1-.59-.74c-2.99-16.72-15.47-21.75-30.84-22.33-11.81-.45-25.4 2.92-28.92 16.05-4.36 16.22 3.94 24.93 16.58 32.75q12.19 7.55 24.34 15.17c19.47 12.22 29.51 26.6 27.27 50.54-4.6 49.08-68.8 46.11-102.12 37.9a1.12 1-83.2 0 1-.76-1.09q.28-15.4-.89-30.76a.69.45 86.7 0 1 .42-.74H367a.79.44 80.6 0 1 .51.64q3.13 14.7 15.05 20.7c13.41 6.75 40.27 6.96 48.63-8.73 7.1-13.34 2.36-28.61-10-37.05Zm-83.48 60.14c-22.1-.79-44.04-.93-66.16.02a.77.49-2 0 1-.8-.47v-3.75a.82.5-3.3 0 1 .76-.53c14.72-1.27 15.96-13.55 15.97-26.25q.04-53.98 0-107.97c0-11.29-1.72-20.67-15.2-21.98a.86.48 4.5 0 1-.78-.52v-3.75a.71.52 2 0 1 .75-.51q33.12 1.5 66.25 0a.72.52-1.5 0 1 .75.51v3.75a.83.47-4.6 0 1-.75.51c-16.1 1.65-15.99 16.69-15.99 29.85q-.02 51.95-.01 103.89c0 11.42 1.59 20.12 14.5 22.25a1.8 1.45 5.7 0 1 1.51 1.49l-.01 3.01a.76.46 1.6 0 1-.79.45Z"
      />

      <path
        fill="currentColor"
        d="m79.96 220.25-6.42 21q-2.88.32-3.99 1.03-4.95 3.18-10.88 4.03l4.48-17.42c3.87-2.09 10.7-3.91 13.16-7.22a.89.82 20 0 1 .78-.33q1.68.17 2.87-1.09Z"
      />

      <path
        fill="currentColor"
        d="M73.54 241.25 70.46 255q-1.11-.71-2.04.21a.77.37-48.8 0 1-.67.29l-.19-.09a1.14.79-42.3 0 0-.94.13q-4.8 2.92-10.42 3.97l2.47-13.2q5.93-.85 10.88-4.03 1.11-.71 3.99-1.03Z"
      />

      <path
        fill="currentColor"
        d="M70.46 255q-3.09 17-4 34.25l-2.48.05a.71.29.5 0 0-.67.27l-.08.43a1.16.65-88.9 0 1-.46.79l-8.75 4.2a1.04.86-8.6 0 0-.52.58l-.46 1.68q-.01-19.08 3.16-37.74 5.62-1.05 10.42-3.97a1.14.79-42.3 0 1 .94-.13l.19.09a.77.37-48.8 0 0 .67-.29q.93-.92 2.04-.21Z"
      />

      <path
        fill="currentColor"
        d="M66.46 289.25 66.21 304l-.27 2.48a.61.05-16.3 0 1-.55.21l-1.61.48a.4.09-18.1 0 1-.4.04q-.29-.75-.45-.07-4.28 1.83-8.41 3.83a.48.43 65.4 0 1-.59-.26q-.32-.7-.64 1.29l-.25-14.75.46-1.68a1.04.86-8.6 0 1 .52-.58l8.75-4.2a1.16.65-88.9 0 0 .46-.79l.08-.43a.71.29.5 0 1 .67-.27l2.48-.05Z"
      />

      <path
        fill="currentColor"
        d="M66.21 304q.58 14.06 2.5 28-.71.81-.78-.01a.27.24 72.3 0 0-.38-.22q-2.73 1.7-3.81 1.95-4.11.92-7.8 3.41-2.06-12.58-2.65-25.13.32-1.99.64-1.29a.48.43 65.4 0 0 .59.26q4.13-2 8.41-3.83.16-.68.45.07a.4.09-18.1 0 0 .4-.04l1.61-.48a.61.05-16.3 0 0 .55-.21l.27-2.48Z"
      />

      <path
        fill="currentColor"
        d="M509.26 372.21a17 17 0 0 1-17 17 17 17 0 0 1-17-17 17 17 0 0 1 17-17 17 17 0 0 1 17 17Zm-13.62 9.01c-.77-2.01-2.24-7.35-4.88-6.99a1.05.87 86.9 0 0-.76 1.02v6a.75.5-90 0 1-.5.75h-4.25a.75.5 90 0 1-.5-.75V362.5a.64.64 0 0 1 .6-.64c6.36-.42 16.31-1.56 14.66 7.78a2.6 2.1-89.7 0 1-1.64 2.01q-2.03.5-.46 1.66.32.23.63.92l3.21 7.02q.46 1.02 1.07.14 6.2-8.97 1.04-16.67c-8.56-12.8-28.05-4.65-25.33 10.01 1.87 10.09 14.43 15.13 22.47 8.34q1.22-1.03-.5-1.06l-4.32-.07a.98.33 71.6 0 1-.54-.72Zm-1.81-14.73q-4.83-2.17-3.74 2.69a1.2.16 77.4 0 0 .4 1.1q1.97-.05 3.68-.84 3.08-1.42-.34-2.95Z"
      />

      <path
        fill="currentColor"
        d="M138.25 537.46q18.05 12.14 37.54 21.79c-2.79 4.49-1.23 9.02-2.04 13.79q-20.46-9.36-39.04-22.04c1.09-1.21-1.59-1.54-.14-2.31a.79.75 77 0 0 .41-.69l.06-9.81a.62.58-1.1 0 1 .59-.57l2.62-.16Z"
      />

      <path
        fill="currentColor"
        d="m175.79 559.25 13.96 6.29c-.72 4.47.19 8.8-.66 13.15a.83.58 27 0 0 .37.69l1.54 1.08-17.25-7.42c.81-4.77-.75-9.3 2.04-13.79Z"
      />

      <path
        fill="currentColor"
        d="m189.75 565.54 25.3 9.23q-1.13.59-.85 1.08.36.63.36.84l-.09 11.84q-11.95-3.5-23.47-8.07l-1.54-1.08a.83.58 27 0 1-.37-.69c.85-4.35-.06-8.68.66-13.15Z"
      />

      <path
        fill="currentColor"
        d="m215.05 574.77 20.2 5.52q-.37 6.87.57 13.78l-21.35-5.54.09-11.84q0-.21-.36-.84-.28-.49.85-1.08Z"
      />

      <path
        fill="currentColor"
        d="m235.25 580.29 14.66 2.97q-1.95 5.17-1.2 10.74a1.53 1-41.2 0 1-.25.89q-.5.79.25 1.61l-12.89-2.43q-.94-6.91-.57-13.78Z"
      />

      <path
        fill="currentColor"
        d="M249.91 583.26q23.73 4.15 47.59 4.53-2.2.63-4.53.19a1.13.99-59 0 0-.93.3l-1.28 1.23a1.34 1.15 69.2 0 0-.36 1l.42 7q.05.88-.03 1.07-.29.75.26 1.41a1.17.43 4.5 0 0 .69.32l3.2.69h-6.5q-20.02-.93-39.73-4.5-.75-.82-.25-1.61a1.53 1-41.2 0 0 .25-.89q-.75-5.57 1.2-10.74Z"
      />

      <path
        fill="currentColor"
        d="m322.5 587.04-.02 10.76a1.44.91-33.8 0 1-.37.83q-.38.44-.15.96a.67.55-17.4 0 0 .74.3q2.68-.66 5.34-.14l-15.5 1c-2.01-.48-7.79.84-6.17-2.8a1.6 1.41-41.3 0 0 .12-.92q-.85-4.31-.42-7.05.25-1.62 2.64-2.23l13.79-.71Z"
      />

      <path
        fill="currentColor"
        d="m297.5 587.79 11.21-.04q-2.39.61-2.64 2.23-.43 2.74.42 7.05a1.6 1.41-41.3 0 1-.12.92c-1.62 3.64 4.16 2.32 6.17 2.8l-.1.25h-17.5l-3.2-.69a1.17.43 4.5 0 1-.69-.32q-.55-.66-.26-1.41.08-.19.03-1.07l-.42-7a1.34 1.15 69.2 0 1 .36-1l1.28-1.23a1.13.99-59 0 1 .93-.3q2.33.44 4.53-.19Z"
      />
    </Icon>
  );
});

export const CisIconMetadata = {
  name: 'CIS Logo',
  importName: 'CisIcon',
  component: <CisIcon aria-label="CIS Logo Icon" />,
  tags: 'cis, security, frameworks, industry, standards, compliance, logo, brand, company, org, organization',
};
