// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

// INSIGHT this icon is from Adam
export const AuthenticationIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <g id="user-check">
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.04651 4.49612C3.04651 2.56527 4.61178 1 6.54264 1C8.47349 1 10.0388 2.56527 10.0388 4.49612C10.0388 6.42698 8.47349 7.99225 6.54264 7.99225C4.61178 7.99225 3.04651 6.42698 3.04651 4.49612ZM6.54264 2.53488C5.45947 2.53488 4.5814 3.41296 4.5814 4.49612C4.5814 5.57929 5.45947 6.45736 6.54264 6.45736C7.6258 6.45736 8.50388 5.57929 8.50388 4.49612C8.50388 3.41296 7.6258 2.53488 6.54264 2.53488Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.11654 8.00962C7.54308 7.3632 10.1011 8.42866 11.3513 10.6065C11.5623 10.9741 11.4353 11.4431 11.0678 11.6541C10.7002 11.8652 10.2311 11.7382 10.0201 11.3706C9.11608 9.7958 7.26633 9.02534 5.51165 9.49278C3.75707 9.96019 2.53579 11.5486 2.53488 13.3643V14.6434H3.81395C4.20823 14.6434 4.53835 14.9422 4.57759 15.3345L5.19065 21.4651H7.89456L8.1193 19.2157C8.16143 18.7939 8.53748 18.4862 8.95923 18.5283C9.38098 18.5704 9.68871 18.9465 9.64658 19.3682L9.35279 22.3089C9.31359 22.7012 8.98345 23 8.58915 23H4.49612C4.10185 23 3.77172 22.7012 3.73249 22.3089L3.11943 16.1783H1.76744C1.3436 16.1783 1 15.8347 1 15.4109V13.3643C1.00106 10.8532 2.69 8.65604 5.11654 8.00962Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.7752 12.0853C14.185 12.0853 12.0853 14.185 12.0853 16.7752C12.0853 19.3654 14.185 21.4651 16.7752 21.4651C19.3654 21.4651 21.4651 19.3654 21.4651 16.7752C21.4651 16.3513 21.8087 16.0078 22.2326 16.0078C22.6564 16.0078 23 16.3513 23 16.7752C23 20.2131 20.2131 23 16.7752 23C13.3373 23 10.5504 20.2131 10.5504 16.7752C10.5504 13.3373 13.3373 10.5504 16.7752 10.5504C17.199 10.5504 17.5426 10.894 17.5426 11.3178C17.5426 11.7417 17.199 12.0853 16.7752 12.0853Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.7752 12.1386C23.0749 12.4383 23.0749 12.9242 22.7752 13.2239L17.8003 18.1988C17.8002 18.199 17.8004 18.1987 17.8003 18.1988C17.5286 18.4707 17.1596 18.6239 16.7752 18.6239C16.3909 18.6239 16.0223 18.4712 15.7506 18.1994L14.1862 16.6358C13.8864 16.3362 13.8862 15.8503 14.1859 15.5505C14.4855 15.2507 14.9714 15.2506 15.2712 15.5502L16.7752 17.0533L21.6899 12.1386C21.9896 11.8389 22.4755 11.8389 22.7752 12.1386Z"
            fill="currentColor"
          />
        </g>
      </g>
    </Icon>
  );
});

export const AuthenticationIconMetadata = {
  name: 'Authentication',
  importName: 'AuthenticationIcon',
  component: <AuthenticationIcon aria-label="Authentication Icon" />,
  tags: 'authentication, auth, login, security, verify, lock, identity, user',
};
