// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const TagsIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 512 512" {...props} fill="none" ref={ref}>
      <g clipPath="url(#clip0_1027_45)">
        <path
          d="M403.29 32H280.36C276.539 31.9972 272.872 33.5072 270.16 36.2L24.4001 281.9C19.0202 287.306 16 294.623 16 302.25C16 309.877 19.0202 317.194 24.4001 322.6L141.4 439.6C146.808 444.981 154.126 448.001 161.755 448.001C169.384 448.001 176.702 444.981 182.11 439.6L427.8 194C430.493 191.289 432.003 187.621 432 183.8V60.8C432.019 57.0204 431.289 53.2744 429.854 49.7778C428.419 46.2811 426.306 43.1029 423.638 40.4261C420.969 37.7492 417.798 35.6267 414.306 34.1806C410.813 32.7345 407.07 31.9934 403.29 32Z"
          stroke="currentColor"
          strokeWidth="32"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M352 144C345.671 144 339.484 142.123 334.222 138.607C328.959 135.091 324.858 130.093 322.436 124.246C320.014 118.399 319.38 111.965 320.615 105.757C321.85 99.5497 324.897 93.8479 329.373 89.3726C333.848 84.8973 339.55 81.8496 345.757 80.6149C351.965 79.3802 358.399 80.0139 364.246 82.4359C370.093 84.8579 375.091 88.9594 378.607 94.2218C382.123 99.4841 384 105.671 384 112C384 120.487 380.629 128.626 374.627 134.627C368.626 140.629 360.487 144 352 144Z"
          fill="currentColor"
        />
        <path
          d="M230 480L492 218C493.303 216.686 494.329 215.124 495.016 213.406C495.703 211.688 496.038 209.85 496 208V80"
          stroke="currentColor"
          strokeWidth="32"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>

      <defs>
        <clipPath id="clip0_1027_45">
          <rect width="512" height="512" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
});

export const TagsIconMetadata = {
  name: 'Tags',
  importName: 'TagsIcon',
  component: <TagsIcon aria-label="Tags Icon" />,
  tags: 'tag, tags, label, category, group',
};
