'use client';

// 3rd
import { createIcon } from '@chakra-ui/icons';

// INSIGHT this illustration is from Adam
export const CompletedIllustration = createIcon({
  displayName: 'CompletedIllustration',
  viewBox: '0 0 256 256',
  path: (
    <svg
      width="256"
      height="256"
      viewBox="0 0 256 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.4713 209.347C97.4713 208.988 97.762 208.697 98.1205 208.697H157.881C158.239 208.697 158.53 208.988 158.53 209.347V239.51C158.53 239.869 158.239 240.16 157.881 240.16H98.1205C97.762 240.16 97.4713 239.869 97.4713 239.51V209.347ZM98.7697 209.996V238.861H157.232V209.996H98.7697Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.2982 241.92C80.095 239.887 89.0928 238.861 98.1206 238.861H157.88C166.907 238.861 175.906 239.887 184.702 241.92C184.996 241.988 185.205 242.25 185.205 242.553V247.554C185.205 247.913 184.914 248.203 184.556 248.203H71.4444C71.0858 248.203 70.7952 247.913 70.7952 247.554V242.553C70.7952 242.25 71.0037 241.988 71.2982 241.92ZM72.0936 243.07V246.905H183.907V243.07C175.367 241.136 166.637 240.159 157.88 240.16H98.1206C89.3632 240.16 80.6343 241.136 72.0936 243.07Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 62.3254C4 61.9669 4.29066 61.6762 4.64921 61.6762H67.1115C67.47 61.6762 67.7607 61.9669 67.7607 62.3254C67.7607 62.684 67.47 62.9746 67.1115 62.9746H5.29843V208.697H250.702V62.9746H190.528C190.17 62.9746 189.879 62.684 189.879 62.3254C189.879 61.9669 190.17 61.6762 190.528 61.6762H251.351C251.709 61.6762 252 61.9669 252 62.3254V209.347C252 209.705 251.709 209.996 251.351 209.996H4.64921C4.29066 209.996 4 209.705 4 209.347V62.3254Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 183.133C4 182.774 4.29066 182.483 4.64921 182.483H251.351C251.709 182.483 252 182.774 252 183.133C252 183.491 251.709 183.782 251.351 183.782H4.64921C4.29066 183.782 4 183.491 4 183.133Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.258 196.24C115.258 195.882 115.549 195.591 115.908 195.591H140.092C140.451 195.591 140.741 195.882 140.741 196.24C140.741 196.599 140.451 196.889 140.092 196.889H115.908C115.549 196.889 115.258 196.599 115.258 196.24Z"
        fill="#1E293B"
      />
      <path d="M26.7524 71.6793H11.9036V86.5282H26.7524V71.6793Z" fill="#3782C0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2543 71.6793C11.2543 71.3208 11.5449 71.0301 11.9035 71.0301H26.7523C27.1109 71.0301 27.4015 71.3208 27.4015 71.6793V86.5281C27.4015 86.8867 27.1109 87.1774 26.7523 87.1774H11.9035C11.5449 87.1774 11.2543 86.8867 11.2543 86.5281V71.6793ZM12.5527 72.3285V85.8789H26.1031V72.3285H12.5527Z"
        fill="#1E293B"
      />
      <path d="M48.2596 71.6793H33.4108V86.5282H48.2596V71.6793Z" fill="#21A9AB" />
      <path d="M69.9523 71.6793H55.1035V86.5282H69.9523V71.6793Z" fill="#21A9AB" />
      <path d="M26.7524 92.7866H11.9036V107.635H26.7524V92.7866Z" fill="#21A9AB" />
      <path d="M69.9523 92.7866H55.1035V107.635H69.9523V92.7866Z" fill="#21A9AB" />
      <path d="M48.2596 114.355H33.4108V129.204H48.2596V114.355Z" fill="#21A9AB" />
      <path d="M69.9523 114.355H55.1035V129.204H69.9523V114.355Z" fill="#3782C0" />
      <path d="M48.2596 136.171H33.4108V151.02H48.2596V136.171Z" fill="#3782C0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.7615 71.6793C32.7615 71.3208 33.0521 71.0301 33.4107 71.0301H48.2595C48.6181 71.0301 48.9087 71.3208 48.9087 71.6793V86.5281C48.9087 86.8867 48.6181 87.1774 48.2595 87.1774H33.4107C33.0521 87.1774 32.7615 86.8867 32.7615 86.5281V71.6793ZM34.0599 72.3285V85.8789H47.6103V72.3285H34.0599Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.4543 71.6793C54.4543 71.3208 54.745 71.0301 55.1036 71.0301H69.9524C70.3109 71.0301 70.6016 71.3208 70.6016 71.6793V86.5281C70.6016 86.8867 70.3109 87.1774 69.9524 87.1774H55.1036C54.745 87.1774 54.4543 86.8867 54.4543 86.5281V71.6793ZM55.7528 72.3285V85.8789H69.3032V72.3285H55.7528Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2543 92.7866C11.2543 92.428 11.5449 92.1374 11.9035 92.1374H26.7523C27.1109 92.1374 27.4015 92.428 27.4015 92.7866V107.635C27.4015 107.994 27.1109 108.285 26.7523 108.285H11.9035C11.5449 108.285 11.2543 107.994 11.2543 107.635V92.7866ZM12.5527 93.4358V106.986H26.1031V93.4358H12.5527Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.4543 92.7866C54.4543 92.428 54.745 92.1374 55.1036 92.1374H69.9524C70.3109 92.1374 70.6016 92.428 70.6016 92.7866V107.635C70.6016 107.994 70.3109 108.285 69.9524 108.285H55.1036C54.745 108.285 54.4543 107.994 54.4543 107.635V92.7866ZM55.7528 93.4358V106.986H69.3032V93.4358H55.7528Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.7615 114.355C32.7615 113.996 33.0521 113.706 33.4107 113.706H48.2595C48.6181 113.706 48.9087 113.996 48.9087 114.355V129.204C48.9087 129.562 48.6181 129.853 48.2595 129.853H33.4107C33.0521 129.853 32.7615 129.562 32.7615 129.204V114.355ZM34.0599 115.004V128.554H47.6103V115.004H34.0599Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.4543 114.355C54.4543 113.996 54.745 113.706 55.1036 113.706H69.9524C70.3109 113.706 70.6016 113.996 70.6016 114.355V129.204C70.6016 129.562 70.3109 129.853 69.9524 129.853H55.1036C54.745 129.853 54.4543 129.562 54.4543 129.204V114.355ZM55.7528 115.004V128.554H69.3032V115.004H55.7528Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.7615 136.171C32.7615 135.812 33.0521 135.522 33.4107 135.522H48.2595C48.6181 135.522 48.9087 135.812 48.9087 136.171V151.02C48.9087 151.378 48.6181 151.669 48.2595 151.669H33.4107C33.0521 151.669 32.7615 151.378 32.7615 151.02V136.171ZM34.0599 136.82V150.371H47.6103V136.82H34.0599Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.7615 92.7866C32.7615 92.428 33.0521 92.1374 33.4107 92.1374H48.2595C48.6181 92.1374 48.9087 92.428 48.9087 92.7866V107.635C48.9087 107.994 48.6181 108.285 48.2595 108.285H33.4107C33.0521 108.285 32.7615 107.994 32.7615 107.635V92.7866ZM34.0599 93.4358V106.986H47.6103V93.4358H34.0599Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2543 114.355C11.2543 113.996 11.5449 113.706 11.9035 113.706H26.7523C27.1109 113.706 27.4015 113.996 27.4015 114.355V129.204C27.4015 129.562 27.1109 129.853 26.7523 129.853H11.9035C11.5449 129.853 11.2543 129.562 11.2543 129.204V114.355ZM12.5527 115.004V128.554H26.1031V115.004H12.5527Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2543 136.171C11.2543 135.812 11.5449 135.522 11.9035 135.522H26.7523C27.1109 135.522 27.4015 135.812 27.4015 136.171V151.02C27.4015 151.378 27.1109 151.669 26.7523 151.669H11.9035C11.5449 151.669 11.2543 151.378 11.2543 151.02V136.171ZM12.5527 136.82V150.371H26.1031V136.82H12.5527Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2543 157.663C11.2543 157.304 11.5449 157.013 11.9035 157.013H26.7523C27.1109 157.013 27.4015 157.304 27.4015 157.663V172.511C27.4015 172.87 27.1109 173.161 26.7523 173.161H11.9035C11.5449 173.161 11.2543 172.87 11.2543 172.511V157.663ZM12.5527 158.312V171.862H26.1031V158.312H12.5527Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.7615 157.663C32.7615 157.304 33.0521 157.013 33.4107 157.013H48.2595C48.6181 157.013 48.9087 157.304 48.9087 157.663V172.511C48.9087 172.87 48.6181 173.161 48.2595 173.161H33.4107C33.0521 173.161 32.7615 172.87 32.7615 172.511V157.663ZM34.0599 158.312V171.862H47.6103V158.312H34.0599Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.196 157.663C228.196 157.304 228.487 157.013 228.845 157.013H243.694C244.053 157.013 244.343 157.304 244.343 157.663V172.511C244.343 172.87 244.053 173.161 243.694 173.161H228.845C228.487 173.161 228.196 172.87 228.196 172.511V157.663ZM229.494 158.312V171.862H243.045V158.312H229.494Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M244.317 150.839C244.417 151.183 244.219 151.543 243.875 151.643L166.795 174.04C166.451 174.14 166.091 173.942 165.991 173.598C165.891 173.253 166.089 172.893 166.433 172.793L243.513 150.396C243.857 150.296 244.217 150.494 244.317 150.839Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M174.612 77.3284C174.844 77.0555 175.254 77.0227 175.527 77.2552L244.115 135.677C244.388 135.909 244.421 136.319 244.188 136.592C243.956 136.865 243.546 136.898 243.273 136.665L174.685 78.2437C174.412 78.0112 174.379 77.6014 174.612 77.3284Z"
        fill="#1E293B"
      />
      <path d="M243.694 136.171H228.845V151.02H243.694V136.171Z" fill="#3782C0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.196 136.171C228.196 135.812 228.487 135.522 228.845 135.522H243.694C244.053 135.522 244.343 135.812 244.343 136.171V151.02C244.343 151.378 244.053 151.669 243.694 151.669H228.845C228.487 151.669 228.196 151.378 228.196 151.02V136.171ZM229.494 136.82V150.371H243.045V136.82H229.494Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M163.672 74.0803H92.2975C84.4093 74.0803 78.0147 80.4749 78.0147 88.363V158.604C78.0147 166.492 84.4093 172.887 92.2975 172.887H163.672C171.56 172.887 177.955 166.492 177.955 158.604V88.363C177.955 80.4749 171.56 74.0803 163.672 74.0803ZM92.2975 72.7819C83.6922 72.7819 76.7163 79.7578 76.7163 88.363V158.604C76.7163 167.209 83.6922 174.185 92.2975 174.185H163.672C172.277 174.185 179.253 167.209 179.253 158.604V88.363C179.253 79.7578 172.277 72.7819 163.672 72.7819H92.2975Z"
        fill="#1E293B"
      />
      <path
        d="M134.057 119.484H118.764C122.973 119.483 127.087 120.73 130.587 123.068C134.086 125.406 136.814 128.73 138.424 132.618C140.035 136.506 140.456 140.785 139.635 144.913C138.813 149.04 136.786 152.832 133.809 155.807C131.833 157.783 129.488 159.35 126.906 160.419C124.325 161.488 121.558 162.038 118.764 162.038H134.062C139.705 162.038 145.117 159.797 149.108 155.806C153.098 151.816 155.34 146.404 155.34 140.761C155.34 135.118 153.098 129.706 149.108 125.716C145.117 121.725 139.705 119.484 134.062 119.484H134.057Z"
        fill="#3782C0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.771 120.133C118.769 120.133 118.767 120.133 118.764 120.133C118.406 120.133 118.115 119.842 118.115 119.484C118.115 119.125 118.406 118.834 118.764 118.834C118.764 118.834 118.764 118.834 118.764 118.834C118.767 118.834 118.769 118.834 118.771 118.834L134.062 118.834C139.878 118.834 145.455 121.145 149.567 125.257C153.679 129.369 155.989 134.946 155.989 140.761C155.989 146.576 153.679 152.153 149.567 156.265C145.455 160.377 139.878 162.688 134.062 162.688H118.766C118.766 162.688 118.765 162.688 118.764 162.688C118.406 162.688 118.115 162.397 118.115 162.038C118.115 161.68 118.406 161.389 118.764 161.389C118.765 161.389 118.766 161.389 118.766 161.389C121.474 161.389 124.156 160.856 126.658 159.819C129.161 158.783 131.435 157.263 133.35 155.348C136.236 152.464 138.201 148.788 138.998 144.786C139.794 140.784 139.386 136.636 137.825 132.867C136.263 129.097 133.619 125.875 130.226 123.608C126.835 121.343 122.849 120.134 118.771 120.133ZM126.201 120.133C127.866 120.733 129.461 121.535 130.948 122.529C134.554 124.938 137.365 128.363 139.024 132.37C140.684 136.377 141.118 140.786 140.271 145.039C139.425 149.293 137.336 153.2 134.268 156.266M126.201 120.133H134.062C139.533 120.133 144.78 122.306 148.649 126.175C152.517 130.043 154.691 135.29 154.691 140.761C154.691 146.232 152.517 151.479 148.649 155.347C144.78 159.216 139.533 161.389 134.062 161.389H126.198C126.52 161.273 126.839 161.15 127.155 161.019C129.815 159.917 132.232 158.302 134.268 156.266"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.373 120.501C113.033 119.4 115.885 118.834 118.764 118.834C124.58 118.836 130.157 121.147 134.268 125.261C138.379 129.374 140.688 134.952 140.686 140.767C140.685 146.582 138.373 152.159 134.26 156.27C130.147 160.381 124.569 162.69 118.754 162.689C112.938 162.687 107.361 160.376 103.25 156.263C101.215 154.226 99.6001 151.808 98.4989 149.148C97.3976 146.487 96.8311 143.636 96.8318 140.756C96.8332 134.941 99.1448 129.364 103.258 125.253C105.295 123.217 107.712 121.603 110.373 120.501ZM118.764 120.133C116.055 120.132 113.372 120.665 110.869 121.701C108.366 122.737 106.092 124.256 104.176 126.171C100.306 130.039 98.1316 135.286 98.1302 140.757C98.1296 143.466 98.6625 146.148 99.6986 148.651C100.735 151.154 102.254 153.429 104.169 155.345C108.036 159.214 113.283 161.389 118.754 161.39C124.225 161.392 129.473 159.22 133.342 155.352C137.212 151.484 139.386 146.238 139.388 140.767C139.389 135.296 137.217 130.048 133.349 126.178C129.482 122.309 124.235 120.134 118.764 120.133Z"
        fill="#1E293B"
      />
      <path
        d="M164.405 8.44584L118.759 54.0934L88.8044 24.1387L58.8639 54.0804L118.759 113.974L194.346 38.3876L164.405 8.44714V8.44584Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M164.888 8.01196C164.825 7.94122 164.745 7.88394 164.654 7.84604C164.411 7.74556 164.132 7.80111 163.946 7.98678L118.759 53.1753L89.2634 23.6796C89.1416 23.5578 88.9765 23.4894 88.8043 23.4894C88.6321 23.4894 88.467 23.5578 88.3452 23.6796L58.4047 53.6214C58.1512 53.8749 58.1512 54.286 58.4048 54.5395L118.3 114.433C118.554 114.687 118.965 114.687 119.218 114.433L194.805 38.8467C194.927 38.7249 194.995 38.5598 194.995 38.3876C194.995 38.2154 194.927 38.0503 194.805 37.9286L164.888 8.01196ZM164.405 9.36463L119.218 54.5525C119.096 54.6742 118.931 54.7426 118.759 54.7426C118.587 54.7426 118.422 54.6742 118.3 54.5525L88.8043 25.0568L59.7819 54.0804L118.759 113.056L193.428 38.3876L164.405 9.36463Z"
        fill="#1E293B"
      />
      <path
        d="M108.927 24.1374H88.8043L118.759 54.0921L128.821 44.0306L108.927 24.1374Z"
        fill="#21A9AB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.2046 23.8889C88.3051 23.6463 88.5418 23.4881 88.8044 23.4881H108.927C109.1 23.4881 109.265 23.5565 109.387 23.6783L129.28 43.5715C129.401 43.6933 129.47 43.8584 129.47 44.0306C129.47 44.2028 129.401 44.3679 129.28 44.4897L119.218 54.5512C118.965 54.8047 118.554 54.8047 118.3 54.5512L88.3453 24.5964C88.1596 24.4107 88.1041 24.1315 88.2046 23.8889ZM90.3717 24.7866L118.759 53.174L127.903 44.0306L108.659 24.7866H90.3717Z"
        fill="#1E293B"
      />
      <path d="M184.528 8.44584H164.405L194.346 38.3876H214.469L184.528 8.44584Z" fill="#21A9AB" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M163.806 8.19737C163.906 7.95478 164.143 7.79661 164.405 7.79661H184.528C184.701 7.79661 184.866 7.86501 184.987 7.98677L214.928 37.9285C215.114 38.1142 215.169 38.3935 215.069 38.6361C214.968 38.8786 214.731 39.0368 214.469 39.0368H194.346C194.174 39.0368 194.009 38.9684 193.887 38.8467L163.946 8.90488C163.761 8.7192 163.705 8.43996 163.806 8.19737ZM165.973 9.09504L194.615 37.7384H212.902L184.259 9.09504H165.973Z"
        fill="#1E293B"
      />
      <path d="M194.346 38.3863L118.759 113.973H138.882L214.469 38.3863H194.346Z" fill="#3782C0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M193.887 37.9273C194.009 37.8055 194.174 37.7371 194.346 37.7371H214.469C214.731 37.7371 214.968 37.8953 215.069 38.1379C215.169 38.3805 215.114 38.6597 214.928 38.8454L139.341 114.432C139.219 114.554 139.054 114.622 138.882 114.622H118.759C118.497 114.622 118.26 114.464 118.159 114.222C118.059 113.979 118.114 113.7 118.3 113.514L193.887 37.9273ZM194.615 39.0356L120.326 113.324H138.613L212.902 39.0356H194.615Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.89551 236.794C6.89551 236.436 7.18617 236.145 7.54472 236.145H19.328C19.6865 236.145 19.9772 236.436 19.9772 236.794C19.9772 237.153 19.6865 237.443 19.328 237.443H7.54472C7.18617 237.443 6.89551 237.153 6.89551 236.794Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4371 230.253C13.7956 230.253 14.0863 230.543 14.0863 230.902V242.685C14.0863 243.044 13.7956 243.334 13.4371 243.334C13.0785 243.334 12.7878 243.044 12.7878 242.685V230.902C12.7878 230.543 13.0785 230.253 13.4371 230.253Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M224.221 19.8064C224.474 19.5528 224.885 19.5528 225.139 19.8064L233.471 28.1384C233.724 28.3919 233.724 28.803 233.471 29.0565C233.217 29.31 232.806 29.31 232.553 29.0565L224.221 20.7245C223.967 20.4709 223.967 20.0599 224.221 19.8064Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M233.471 19.8064C233.724 20.0599 233.724 20.4709 233.471 20.7245L225.139 29.0565C224.885 29.31 224.474 29.31 224.221 29.0565C223.967 28.803 223.967 28.3919 224.221 28.1384L232.553 19.8064C232.806 19.5528 233.217 19.5528 233.471 19.8064Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M217.421 228.319C217.674 228.065 218.085 228.065 218.339 228.319L222.589 232.568C222.842 232.822 222.842 233.233 222.589 233.486C222.335 233.74 221.924 233.74 221.671 233.486L217.421 229.237C217.167 228.983 217.167 228.572 217.421 228.319Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M222.589 228.319C222.842 228.572 222.842 228.983 222.589 229.237L218.339 233.486C218.085 233.74 217.674 233.74 217.421 233.486C217.167 233.233 217.167 232.822 217.421 232.568L221.671 228.319C221.924 228.065 222.335 228.065 222.589 228.319Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M176.923 192.38C177.246 192.534 177.383 192.922 177.229 193.245L172.944 202.221C172.841 202.437 172.629 202.578 172.391 202.59C172.152 202.602 171.927 202.482 171.803 202.278L168.16 196.269C167.974 195.962 168.072 195.563 168.378 195.377C168.685 195.191 169.084 195.289 169.27 195.596L172.29 200.577L176.057 192.686C176.212 192.362 176.599 192.225 176.923 192.38Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.9723 49.4475C36.9723 49.089 37.263 48.7983 37.6215 48.7983H44.0487C44.4073 48.7983 44.6979 49.089 44.6979 49.4475C44.6979 49.8061 44.4073 50.0967 44.0487 50.0967H37.6215C37.263 50.0967 36.9723 49.8061 36.9723 49.4475Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.8351 45.5847C41.1937 45.5847 41.4843 45.8754 41.4843 46.234V52.6612C41.4843 53.0197 41.1937 53.3104 40.8351 53.3104C40.4766 53.3104 40.1859 53.0197 40.1859 52.6612V46.234C40.1859 45.8754 40.4766 45.5847 40.8351 45.5847Z"
        fill="#1E293B"
      />
    </svg>
  ),
});
