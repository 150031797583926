// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const CloverOldLogoIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 40 42" {...props} ref={ref} fill="none">
      <mask
        id="mask0_80_654"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="40"
        height="42"
      >
        <g clipPath="url(#clip0_80_654)">
          <path
            d="M9.2767 40.5812C4.86188 40.5812 1.27008 36.9894 1.27008 32.5745C1.27008 30.4898 2.09094 28.7851 3.77826 27.3627C5.24407 26.1293 7.10078 25.3236 8.88799 24.6396C10.0715 24.1857 11.6177 23.6233 13.0748 23.291C16.6623 22.4767 19.1509 19.2063 18.9837 15.532C18.912 13.9662 19.0466 12.1421 19.3811 10.1095C19.8089 7.51665 20.8599 5.26255 22.4191 3.5926C24.1564 1.73156 26.3975 0.747828 28.9056 0.747828C34.1652 0.747828 38.4454 5.02584 38.4454 10.2876C38.4454 12.7436 37.4638 14.7524 35.4421 16.4267C33.7265 17.849 31.479 18.8458 29.3247 19.7427C27.5571 20.4788 26.0022 20.9566 24.7014 21.165C21.3116 21.7079 18.7057 24.4572 18.3452 27.8709C18.3235 28.0772 18.2953 28.29 18.2649 28.505C18.0303 30.0816 17.596 31.6712 17.2421 32.8764C15.748 37.9861 13.0705 40.5768 9.27887 40.5768L9.2767 40.5812Z"
            fill="#00E0C4"
          />

          <path
            d="M30.7472 41.25C25.8198 41.25 22.8361 37.1522 21.8263 32.3291C21.3594 30.0968 20.5928 26.3377 21.9284 24.7786C23.5636 22.8676 28.1977 23.2584 30.7472 23.4083C35.6658 23.6993 39.668 27.4018 39.668 32.3291C39.668 37.2565 35.6745 41.25 30.7472 41.25Z"
            fill="#00E0C4"
          />

          <path
            d="M7.86953 19.9077C3.78261 19.1086 0.33197 16.5331 0.33197 12.3701C0.33197 8.20721 3.70661 4.83257 7.86953 4.83257C12.0325 4.83257 14.2952 8.35705 15.4071 12.3701C15.9413 14.3007 17.0141 18.3767 15.7241 19.7296C14.3517 21.1694 10.2952 20.3811 7.86953 19.9077Z"
            fill="#00E0C4"
          />
        </g>
      </mask>

      <g mask="url(#mask0_80_654)">
        <rect x="-30" y="-29" width="100" height="100" fill="#2683FE" />
      </g>

      <defs>
        <clipPath id="clip0_80_654">
          <rect width="39.336" height="40.5" fill="white" transform="translate(0.33197 0.75)" />
        </clipPath>
      </defs>
    </Icon>
  );
});

export const CloverOldLogoIconMetadata = {
  name: 'Old Logo',
  importName: 'CloverOldLogoIcon',
  component: <CloverOldLogoIcon aria-label="Clover Logo Icon" />,
  tags: 'clover, logo, brand, company, org, organization, old',
};
