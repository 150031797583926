// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const AzureDevOpsWorkItemIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 125 125" {...props} ref={ref} fill="none">
      <path
        d="M125 23.4107V99.4107L93.75 125L45.3125 107.366V124.848L17.8929 89.0179L97.8125 95.2679V26.8304L125 23.4107ZM98.3571 27.2321L53.5179 0V17.8661L12.3393 29.9643L0 45.8036V81.7768L17.6607 89.5714V43.4732L98.3571 27.2411V27.2321Z"
        fill="#00947D"
      />

      <path
        d="M88.0048 74.5258C92.3594 74.5258 96.3217 76.163 99.3332 78.8526V52.4327C99.3332 51.0963 98.257 50.0188 96.9223 50.0188H49.0231C47.6884 50.0188 46.6123 51.0963 46.6123 52.4327V91.7075C46.6123 93.0439 47.6884 94.1215 49.0231 94.1215H71.2042C71.0791 93.2861 71.0123 92.4258 71.0123 91.5488C71.004 82.1519 78.6202 74.5258 88.0048 74.5258Z"
        fill="#00947D"
      />

      <path d="M62.6793 54.4432H50.9506V61.8772H62.6793V54.4432Z" fill="#1FC1A7" />

      <path d="M78.8034 54.4432H67.0746V61.8772H78.8034V54.4432Z" fill="#61DDC9" />

      <path d="M62.6793 66.2218H50.9506V73.6557H62.6793V66.2218Z" fill="#9BFDEC" />

      <path d="M62.6793 78.0003H50.9506V85.3257H62.6793V78.0003Z" fill="#00B99D" />

      <path d="M78.8034 66.2218H67.0746V73.6557H78.8034V66.2218Z" fill="#04BCA1" />

      <path d="M94.961 54.4432H83.2322V61.8772H94.961V54.4432Z" fill="#1DC0A7" />

      <path
        d="M99.3306 91.7058V78.8593C96.3276 76.1697 92.3568 74.5325 88.0024 74.5325C78.6177 74.5325 71.0016 82.1502 71.0016 91.5554C71.0016 92.4325 71.0683 93.2845 71.1935 94.128H96.9199C98.2546 94.1197 99.3306 93.0423 99.3306 91.7058Z"
        fill="#00BA9D"
      />

      <path
        d="M99.3349 78.8589V91.7137C99.3349 93.0419 98.2589 94.1277 96.9242 94.1277H71.1976C72.4323 102.305 79.4895 108.578 88.0066 108.578C97.3996 108.57 105.007 100.952 105.007 91.5467C105.007 86.5017 102.813 81.9745 99.3349 78.8589Z"
        fill="#27CFB2"
      />

      <path
        d="M96.7391 83.1709L86.0864 93.8291L80.6141 88.3496L77.3441 91.6324L82.8164 97.1117L86.2949 100.595L89.565 97.3122L100.218 86.6541L96.7391 83.1709Z"
        fill="#C5FDF4"
      />
    </Icon>
  );
});

export const AzureDevOpsWorkItemIconMetadata = {
  name: 'Azure DevOps Work Item',
  importName: 'AzureDevopsWorkItemIcon',
  component: <AzureDevOpsWorkItemIcon aria-label="Azure DevOps Work Item Icon" />,
  tags: 'azure, devops, code, repo, repository, cat, microsoft, logo, brand, company, org, organization, version, control, vc',
};
