// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const KotlinIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 128 128" {...props} ref={ref}>
      <path fill="#c711e1" d="M112.484 112.484H15.516V15.516h96.968L64 64Zm0 0" />
    </Icon>
  );
});

export const KotlinIconMetadata = {
  name: 'Kotlin',
  importName: 'KotlinIcon',
  component: <KotlinIcon aria-label="Kotlin Icon" />,
  tags: 'Kotlin, programming, language, logo, application',
};
