// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

// INSIGHT this icon is from Adam
export const GenAIIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <g id="ai-magic">
        <g id="Vector">
          <path
            d="M8.79784 20.1358C8.79784 18.2451 9.56631 16.4319 10.9341 15.0951C12.302 13.7582 14.1572 13.0072 16.0916 13.0072C14.1572 13.0072 12.302 12.2561 10.9341 10.9192C9.56631 9.58234 8.79784 7.76918 8.79784 5.87856C8.79784 7.76918 8.02938 9.58234 6.66156 10.9192C5.29368 12.2561 3.43848 13.0072 1.50404 13.0072C3.43848 13.0072 5.29368 13.7582 6.66156 15.0951C8.02938 16.4319 8.79784 18.2451 8.79784 20.1358Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.79784 5.37451C9.07622 5.37451 9.30189 5.60018 9.30189 5.87856C9.30189 7.63172 10.0144 9.31546 11.2864 10.5587C12.5589 11.8024 14.2872 12.5031 16.0916 12.5031C16.37 12.5031 16.5957 12.7288 16.5957 13.0072C16.5957 13.2855 16.37 13.5112 16.0916 13.5112C14.2872 13.5112 12.5589 14.2119 11.2864 15.4555C10.0144 16.6988 9.30189 18.3826 9.30189 20.1358C9.30189 20.4142 9.07622 20.6398 8.79784 20.6398C8.51947 20.6398 8.2938 20.4142 8.2938 20.1358C8.2938 18.3826 7.58132 16.6988 6.30926 15.4555C5.03675 14.2119 3.30848 13.5112 1.50404 13.5112C1.22567 13.5112 1 13.2855 1 13.0072C1 12.7288 1.22567 12.5031 1.50404 12.5031C3.30847 12.5031 5.03674 11.8024 6.30926 10.5587C7.58132 9.31546 8.2938 7.63172 8.2938 5.87856C8.2938 5.60018 8.51947 5.37451 8.79784 5.37451ZM8.79784 8.57821C8.40948 9.58343 7.80507 10.5064 7.01387 11.2797C6.23211 12.0437 5.30222 12.6283 4.29081 13.0071C5.30222 13.3859 6.2321 13.9705 7.01386 14.7346C7.80506 15.5079 8.40948 16.4309 8.79784 17.4361C9.1862 16.4309 9.79062 15.5079 10.5818 14.7346C11.3636 13.9705 12.2935 13.3859 13.3049 13.0071C12.2935 12.6283 11.3636 12.0437 10.5818 11.2797C9.79063 10.5064 9.1862 9.58343 8.79784 8.57821Z"
            fill="currentColor"
          />

          <path
            d="M19.3525 6.57622C18.8966 6.13059 18.2782 5.88025 17.6334 5.88025C18.2782 5.88025 18.8966 5.62989 19.3525 5.18427C19.8085 4.73864 20.0647 4.13425 20.0647 3.50404C20.0647 4.13425 20.3208 4.73864 20.7768 5.18427C21.2327 5.62989 21.8511 5.88025 22.496 5.88025C21.8511 5.88025 21.2327 6.13059 20.7768 6.57622C20.3208 7.02184 20.0647 7.62624 20.0647 8.25646C20.0647 7.62624 19.8085 7.02184 19.3525 6.57622Z"
            fill="currentColor"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.0647 3C20.343 3 20.5687 3.22567 20.5687 3.50404C20.5687 3.99679 20.7689 4.47176 21.1291 4.8238C21.4897 5.17622 21.9811 5.3762 22.496 5.3762C22.7743 5.3762 23 5.60187 23 5.88025C23 6.15862 22.7743 6.38429 22.496 6.38429C21.9811 6.38429 21.4897 6.58427 21.1291 6.93669C20.7689 7.28872 20.5687 7.76369 20.5687 8.25646C20.5687 8.53483 20.343 8.7605 20.0647 8.7605C19.7863 8.7605 19.5606 8.53483 19.5606 8.25646C19.5606 7.76369 19.3604 7.28872 19.0002 6.93669C18.6397 6.5843 18.1482 6.38429 17.6334 6.38429C17.355 6.38429 17.1294 6.15862 17.1294 5.88025C17.1294 5.60187 17.355 5.3762 17.6334 5.3762C18.1482 5.3762 18.6397 5.17621 19.0002 4.82381C19.3604 4.47178 19.5606 3.99679 19.5606 3.50404C19.5606 3.22567 19.7863 3 20.0647 3ZM19.7049 5.54472C19.8394 5.41324 19.9597 5.2702 20.0647 5.11791L19.7049 5.54472ZM20.0647 5.11791C20.1696 5.2702 20.2899 5.41326 20.4245 5.54474C20.5522 5.66963 20.6905 5.78172 20.837 5.88024C20.6905 5.97877 20.5522 6.09086 20.4245 6.21574C20.2899 6.34723 20.1696 6.49028 20.0647 6.64258C19.9597 6.49029 19.8394 6.34724 19.7049 6.21576M19.7049 6.21576C19.5771 6.09088 19.4389 5.97877 19.2923 5.88024L19.7049 6.21576ZM19.2923 5.88024C19.4389 5.78172 19.5771 5.66961 19.7049 5.54472L19.2923 5.88024Z"
            fill="currentColor"
          />
        </g>
      </g>
    </Icon>
  );
});

export const GenAIIconMetadata = {
  name: 'Gen AI',
  importName: 'GenAIIcon',
  component: <GenAIIcon aria-label="Gen AI Icon" />,
  tags: 'gen, ai, gpt, llm, model, artificial, intelligence, magic, generate, generation',
};
