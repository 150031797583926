// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

// INSIGHT this icon is from Adam
export const CryptographyIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref} fill="none">
      <path
        d="M4 8V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H8M4 16V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H8M16 4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V8M16 20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V16M10 11V9C10 8.46957 10.2107 7.96086 10.5858 7.58579C10.9609 7.21071 11.4696 7 12 7C12.5304 7 13.0391 7.21071 13.4142 7.58579C13.7893 7.96086 14 8.46957 14 9V11M8 12C8 11.7348 8.10536 11.4804 8.29289 11.2929C8.48043 11.1054 8.73478 11 9 11H15C15.2652 11 15.5196 11.1054 15.7071 11.2929C15.8946 11.4804 16 11.7348 16 12V15C16 15.2652 15.8946 15.5196 15.7071 15.7071C15.5196 15.8946 15.2652 16 15 16H9C8.73478 16 8.48043 15.8946 8.29289 15.7071C8.10536 15.5196 8 15.2652 8 15V12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
});

export const CryptographyIconMetadata = {
  name: 'Cryptography',
  importName: 'CryptographyIcon',
  component: <CryptographyIcon aria-label="Cryptography Icon" />,
  tags: 'cryptography, lock, unlock, security, secure, encryption, decryption, key, keys, password',
};
