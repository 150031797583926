'use client';

// 3rd
import { createIcon } from '@chakra-ui/icons';

// INSIGHT this illustration is from Adam
export const EmptyIllustration = createIcon({
  displayName: 'EmptyIllustration',
  viewBox: '0 0 256 256',
  path: (
    <svg
      width="256"
      height="256"
      viewBox="0 0 256 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M91.1931 225.025C115.064 225.025 134.414 205.674 134.414 181.804C134.414 157.933 115.064 138.583 91.1931 138.583C67.3221 138.583 47.9722 157.933 47.9722 181.804C47.9722 205.674 67.3234 225.025 91.1931 225.025Z"
        fill="#21A9AB"
      />
      <path d="M72.7897 120.895L52.0674 138.534L49.5757 134.217L72.7897 120.895Z" fill="#3782C0" />
      <path
        d="M97.0892 61.5651C98.3519 61.5651 99.563 61.0635 100.456 60.1706C101.349 59.2777 101.85 58.0667 101.85 56.8039C101.85 55.5412 101.349 54.3302 100.456 53.4373C99.563 52.5444 98.3519 52.0427 97.0892 52.0427C95.8264 52.0427 94.6154 52.5444 93.7225 53.4373C92.8296 54.3302 92.328 55.5412 92.328 56.8039C92.328 58.0667 92.8296 59.2777 93.7225 60.1706C94.6154 61.0635 95.8264 61.5651 97.0892 61.5651Z"
        fill="#3782C0"
      />
      <path
        d="M193.127 83.0029C194.174 83.0029 195.178 82.5871 195.919 81.8469C196.659 81.1066 197.075 80.1027 197.075 79.0558C197.075 78.009 196.659 77.005 195.919 76.2648C195.178 75.5246 194.174 75.1087 193.127 75.1087C192.081 75.1087 191.077 75.5246 190.336 76.2648C189.596 77.005 189.18 78.009 189.18 79.0558C189.18 80.1027 189.596 81.1066 190.336 81.8469C191.077 82.5871 192.081 83.0029 193.127 83.0029Z"
        fill="#3782C0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M156.313 199.14C156.885 198.569 157.659 198.248 158.467 198.248C159.274 198.248 160.049 198.569 160.62 199.14C161.191 199.711 161.512 200.485 161.512 201.293C161.512 202.101 161.191 202.875 160.62 203.446C160.049 204.018 159.274 204.338 158.467 204.338C157.659 204.338 156.885 204.018 156.313 203.446C155.742 202.875 155.421 202.101 155.421 201.293C155.421 200.485 155.742 199.711 156.313 199.14ZM158.467 199.553C158.005 199.553 157.562 199.736 157.236 200.062C156.91 200.389 156.726 200.831 156.726 201.293C156.726 201.755 156.91 202.197 157.236 202.524C157.562 202.85 158.005 203.034 158.467 203.034C158.928 203.034 159.371 202.85 159.698 202.524C160.024 202.197 160.207 201.755 160.207 201.293C160.207 200.831 160.024 200.389 159.698 200.062C159.371 199.736 158.928 199.553 158.467 199.553Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M132.385 54.9061C132.696 54.7253 133.095 54.8314 133.276 55.1431L186.809 147.433C186.99 147.745 186.884 148.144 186.572 148.325L134.569 178.495C134.257 178.676 133.858 178.57 133.677 178.259C133.496 177.947 133.602 177.548 133.914 177.367L185.353 147.523L132.475 56.3622L60.0272 98.3827L50.2768 134.126L60.4277 151.677C60.6081 151.989 60.5015 152.388 60.1896 152.568C59.8776 152.749 59.4785 152.642 59.2981 152.33L49.0109 134.543C48.9237 134.393 48.9004 134.213 48.9463 134.045L58.8387 97.7809C58.8839 97.6154 58.9924 97.4743 59.1408 97.3882L132.385 54.9061Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.1406 97.3884C59.4522 97.2074 59.8515 97.3134 60.0324 97.625L73.3539 120.568C73.4411 120.718 73.4649 120.896 73.4199 121.064C73.375 121.232 73.265 121.375 73.1144 121.461L49.9005 134.783C49.5879 134.962 49.1892 134.854 49.0098 134.541C48.8305 134.229 48.9384 133.83 49.251 133.651L71.8962 120.656L58.9039 98.2802C58.723 97.9686 58.8289 97.5693 59.1406 97.3884Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.3233 120.52C73.5173 120.796 73.4693 121.174 73.2126 121.392L52.4903 139.031C52.3428 139.156 52.1471 139.21 51.9563 139.177C51.7654 139.144 51.5991 139.028 51.5022 138.86L49.0106 134.543C48.924 134.393 48.9006 134.214 48.9457 134.047C48.9908 133.88 49.1007 133.737 49.251 133.651L72.465 120.329C72.7573 120.162 73.1294 120.244 73.3233 120.52ZM50.4678 134.457L52.2409 137.529L66.9877 124.977L50.4678 134.457Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.7511 60.9178C97.1087 60.9627 97.3621 61.289 97.3171 61.6465L93.8881 88.9309C93.8432 89.2885 93.5169 89.5419 93.1594 89.4969C92.8018 89.452 92.5484 89.1257 92.5934 88.7682L96.0224 61.4838C96.0673 61.1262 96.3936 60.8728 96.7511 60.9178Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.2611 52.9759C94.2764 51.9606 95.6534 51.3902 97.0892 51.3902C98.525 51.3902 99.902 51.9606 100.917 52.9759C101.932 53.9911 102.503 55.3681 102.503 56.8039C102.503 58.2397 101.932 59.6167 100.917 60.632C99.902 61.6472 98.525 62.2176 97.0892 62.2176C95.6534 62.2176 94.2764 61.6472 93.2611 60.632C92.2459 59.6167 91.6755 58.2397 91.6755 56.8039C91.6755 55.3681 92.2459 53.9911 93.2611 52.9759ZM97.0892 52.6952C95.9995 52.6952 94.9544 53.1281 94.1838 53.8986C93.4133 54.6691 92.9804 55.7142 92.9804 56.8039C92.9804 57.8936 93.4133 58.9387 94.1838 59.7092C94.9544 60.4798 95.9995 60.9127 97.0892 60.9127C98.1789 60.9127 99.224 60.4798 99.9945 59.7092C100.765 58.9387 101.198 57.8936 101.198 56.8039C101.198 55.7142 100.765 54.6691 99.9945 53.8986C99.224 53.1281 98.1789 52.6952 97.0892 52.6952Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M147.939 82.6818C148.021 82.3309 148.372 82.1129 148.723 82.1949L215.454 97.786C215.622 97.8254 215.768 97.9301 215.86 98.0771C215.951 98.2241 215.98 98.4014 215.941 98.5699L195.86 184.469C195.778 184.82 195.427 185.038 195.076 184.956L143.788 172.966C143.437 172.884 143.219 172.533 143.301 172.183C143.383 171.832 143.734 171.614 144.085 171.696L194.738 183.537L214.521 98.9083L148.426 83.4656C148.075 83.3836 147.857 83.0327 147.939 82.6818Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M190.67 81.2551C190.9 81.5328 190.861 81.9441 190.583 82.1737L173.067 96.6533C172.79 96.8829 172.378 96.8439 172.149 96.5662C171.919 96.2885 171.958 95.8772 172.236 95.6476L189.751 81.1679C190.029 80.9383 190.44 80.9773 190.67 81.2551Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M189.875 75.8034C190.738 74.9408 191.908 74.4562 193.127 74.4562C194.347 74.4562 195.517 74.9408 196.38 75.8034C197.242 76.666 197.727 77.8359 197.727 79.0558C197.727 80.2757 197.242 81.4456 196.38 82.3082C195.517 83.1708 194.347 83.6554 193.127 83.6554C191.908 83.6554 190.738 83.1708 189.875 82.3082C189.012 81.4456 188.528 80.2757 188.528 79.0558C188.528 77.8359 189.012 76.666 189.875 75.8034ZM193.127 75.7611C192.254 75.7611 191.416 76.1083 190.798 76.7261C190.18 77.344 189.833 78.182 189.833 79.0558C189.833 79.9296 190.18 80.7676 190.798 81.3855C191.416 82.0034 192.254 82.3505 193.127 82.3505C194.001 82.3505 194.839 82.0034 195.457 81.3855C196.075 80.7676 196.422 79.9296 196.422 79.0558C196.422 78.182 196.075 77.344 195.457 76.7261C194.839 76.1083 194.001 75.7611 193.127 75.7611Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.3198 181.804C47.3198 157.573 66.9618 137.931 91.1932 137.931C115.424 137.931 135.067 157.573 135.067 181.804C135.067 206.034 115.424 225.678 91.1932 225.678C66.9631 225.678 47.3198 206.034 47.3198 181.804ZM91.1932 139.236C67.6825 139.236 48.6247 158.294 48.6247 181.804C48.6247 205.314 67.6838 224.373 91.1932 224.373C114.704 224.373 133.762 205.314 133.762 181.804C133.762 158.294 114.704 139.236 91.1932 139.236Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.0847 159.736C97.4329 159.829 97.6398 160.187 97.5468 160.535L86.1002 203.41C86.0072 203.758 85.6497 203.965 85.3015 203.872C84.9534 203.779 84.7465 203.422 84.8394 203.074L96.2861 160.198C96.379 159.85 96.7366 159.643 97.0847 159.736Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.125 175.913C69.2179 175.564 69.5755 175.358 69.9237 175.45L112.799 186.897C113.147 186.99 113.354 187.348 113.261 187.696C113.168 188.044 112.811 188.251 112.463 188.158L69.5871 176.711C69.2389 176.618 69.032 176.261 69.125 175.913Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M144.349 30.4107C144.661 30.2298 145.06 30.3358 145.241 30.6474L154.221 46.1152C154.401 46.4268 154.295 46.8261 153.984 47.007C153.672 47.1879 153.273 47.082 153.092 46.7703L144.112 31.3026C143.931 30.9909 144.037 30.5916 144.349 30.4107Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M157.452 33.8784C157.633 34.1898 157.528 34.5893 157.216 34.7706L141.748 43.7749C141.437 43.9562 141.038 43.8507 140.856 43.5393C140.675 43.2279 140.781 42.8285 141.092 42.6472L156.56 33.6428C156.871 33.4615 157.271 33.567 157.452 33.8784Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.0165 128.842C4.09667 128.49 4.44646 128.271 4.79777 128.351L24.9034 132.939C25.2547 133.019 25.4745 133.369 25.3943 133.721C25.3142 134.072 24.9644 134.292 24.6131 134.211L4.50743 129.623C4.15612 129.543 3.93632 129.193 4.0165 128.842Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.2329 156.212C32.497 156.458 32.5124 156.87 32.2672 157.135L18.2303 172.257C17.9851 172.521 17.5723 172.536 17.3082 172.291C17.0441 172.046 17.0287 171.633 17.2739 171.369L31.3108 156.247C31.556 155.983 31.9688 155.967 32.2329 156.212Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M225.317 143.98C225.565 143.719 225.978 143.709 226.239 143.957L241.214 158.191C241.475 158.44 241.485 158.853 241.237 159.114C240.989 159.375 240.576 159.385 240.315 159.137L225.34 144.903C225.079 144.655 225.069 144.242 225.317 143.98Z"
        fill="#1E293B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M251.973 114.938C252.075 115.283 251.878 115.646 251.532 115.748L231.747 121.595C231.402 121.697 231.039 121.5 230.937 121.154C230.835 120.809 231.032 120.446 231.378 120.344L251.162 114.497C251.508 114.395 251.871 114.592 251.973 114.938Z"
        fill="#1E293B"
      />
    </svg>
  ),
});
