// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const UsersIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon
      viewBox="0 0 24 24"
      {...props}
      fill="none"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-users"
      ref={ref}
    >
      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />

      <circle cx="9" cy="7" r="4" />

      <path d="M23 21v-2a4 4 0 0 0-3-3.87" />

      <path d="M16 3.13a4 4 0 0 1 0 7.75" />
    </Icon>
  );
});

export const UsersIconMetadata = {
  name: 'Users',
  importName: 'UsersIcon',
  component: <UsersIcon aria-label="Users Icon" />,
  tags: 'users, user, custom, people',
};
