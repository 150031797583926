// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const KeyIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 17 18" {...props} ref={ref} fill="none">
      <path
        d="M12.6667 6.5C12.6667 6.05797 12.4911 5.63405 12.1785 5.32149C11.866 5.00893 11.442 4.83333 11 4.83333M11 11.5C12.0517 11.4997 13.0765 11.1678 13.9287 10.5515C14.7809 9.93514 15.417 9.0658 15.7466 8.0671C16.0762 7.0684 16.0826 5.9912 15.7647 4.9887C15.4468 3.9862 14.8209 3.10947 13.976 2.48319C13.1311 1.85692 12.1103 1.513 11.0586 1.50036C10.007 1.48772 8.97821 1.80699 8.11851 2.41277C7.25881 3.01855 6.61202 3.87999 6.27013 4.87455C5.92823 5.86912 5.90865 6.94616 6.21417 7.9525L6 8.16667L1.24417 12.9225C1.08788 13.0787 1.00005 13.2907 1 13.5117V15.6667C1 15.8877 1.0878 16.0996 1.24408 16.2559C1.40036 16.4122 1.61232 16.5 1.83333 16.5H3.5C3.72101 16.5 3.93298 16.4122 4.08926 16.2559C4.24554 16.0996 4.33333 15.8877 4.33333 15.6667C4.33333 15.4457 4.42113 15.2337 4.57741 15.0774C4.73369 14.9211 4.94565 14.8333 5.16667 14.8333C5.38768 14.8333 5.59964 14.7455 5.75592 14.5893C5.9122 14.433 6 14.221 6 14C6 13.779 6.0878 13.567 6.24408 13.4107C6.40036 13.2545 6.61232 13.1667 6.83333 13.1667H7.32167C7.54266 13.1666 7.75459 13.0788 7.91083 12.9225L9.33333 11.5L9.5475 11.2858C10.0185 11.4283 10.5079 11.5005 11 11.5Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
});

export const KeyIconMetadata = {
  name: 'Key',
  importName: 'KeyIcon',
  component: <KeyIcon aria-label="Key Icon" />,
  tags: 'key',
};
