// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const ChevronLeftIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <path d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z" fill="currentColor" />
    </Icon>
  );
});

export const ChevronLeftIconMetadata = {
  name: 'Chevron Left',
  importName: 'ChevronLeftIcon',
  component: <ChevronLeftIcon aria-label="Chevron Left Icon" />,
  tags: 'chevron, left, arrow, back, previous, navigate, go, move, direction, swipe, slide, scroll, carousel, slider, menu, list, item, option, select, choose',
};
