// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const MinimizeIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 512 512" {...props} ref={ref} fill="none">
      <path
        d="M304 416V304H416M314.2 314.23L432 432M208 96V208H96M197.8 197.77L80 80M416 208H304V96M314.23 197.8L432 80M96 304H208V416M197.77 314.2L80 432"
        stroke="currentColor"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
});

export const MinimizeIconMetadata = {
  name: 'Minimize',
  importName: 'MinimizeIcon',
  component: <MinimizeIcon aria-label="Minimize Icon" />,
  tags: 'minimize, shrink, reduce, collapse, decrease, compress',
};
