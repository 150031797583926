// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const ArrowDownIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <path
        d="M20 12L18.59 10.59L13 16.17V4H11V16.17L5.42 10.58L4 12L12 20L20 12Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const ArrowDownIconMetadata = {
  name: 'Arrow Down',
  importName: 'ArrowDownIcon',
  component: <ArrowDownIcon aria-label="Arrow Down Icon" />,
  tags: 'arrow, down, download, decrease, expand, descend',
};
