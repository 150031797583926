// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

// INSIGHT this icon is from Guy
export const SpeedometerIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 12 12" {...props} ref={ref} fill="none">
      <g clipPath="url(#clip0_939_19394)">
        <path
          id="Icon"
          d="M2.5 6C2.5 4.067 4.067 2.5 6 2.5M8.24995 3.75L5.99995 6M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6ZM6.5 6C6.5 6.27614 6.27614 6.5 6 6.5C5.72386 6.5 5.5 6.27614 5.5 6C5.5 5.72386 5.72386 5.5 6 5.5C6.27614 5.5 6.5 5.72386 6.5 6Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>

      <defs>
        <clipPath id="clip0_939_19394">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
});

export const SpeedometerIconMetadata = {
  name: 'Speedometer',
  importName: 'SpeedometerIcon',
  component: <SpeedometerIcon aria-label="Speedometer Icon" />,
  tags: 'speedometer, speed, meter, gauge, velocity',
};
