// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const BinaryTreeIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 16 16" {...props} ref={ref} fill="none">
      <g>
        <path
          d="M14 12C13.896 12 13.792 12 13.688 12.032L12.4 9.432C12.685 9.15413 12.8806 8.79753 12.9618 8.40785C13.0429 8.01818 13.006 7.61314 12.8557 7.24458C12.7053 6.87602 12.4484 6.56071 12.1178 6.33899C11.7873 6.11728 11.398 5.99924 11 6C10.896 6 10.8 6 10.688 6.032L9.384 3.432C9.768 3.072 10 2.568 10 2C10 1.46957 9.78929 0.960859 9.41421 0.585786C9.03914 0.210714 8.53043 0 8 0C7.46957 0 6.96086 0.210714 6.58579 0.585786C6.21071 0.960859 6 1.46957 6 2C6 2.568 6.232 3.072 6.608 3.432L5.312 6.032C5.2 6 5.104 6 5 6C4.60196 5.99924 4.21274 6.11728 3.88217 6.33899C3.55159 6.56071 3.29469 6.87602 3.14435 7.24458C2.994 7.61314 2.95705 8.01818 3.03823 8.40785C3.1194 8.79753 3.315 9.15413 3.6 9.432L2.312 12.032C2.208 12 2.104 12 2 12C1.46957 12 0.960859 12.2107 0.585786 12.5858C0.210714 12.9609 0 13.4696 0 14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16C2.26264 16 2.52272 15.9483 2.76537 15.8478C3.00802 15.7472 3.2285 15.5999 3.41421 15.4142C3.59993 15.2285 3.74725 15.008 3.84776 14.7654C3.94827 14.5227 4 14.2626 4 14C4 13.44 3.768 12.928 3.392 12.568L4.688 9.968C4.8 10 4.896 10 5 10C5.104 10 5.2 10 5.312 9.968L6.616 12.568C6.24 12.928 6 13.44 6 14C6 14.5304 6.21071 15.0391 6.58579 15.4142C6.96086 15.7893 7.46957 16 8 16C8.53043 16 9.03914 15.7893 9.41421 15.4142C9.78929 15.0391 10 14.5304 10 14C10 13.4696 9.78929 12.9609 9.41421 12.5858C9.03914 12.2107 8.53043 12 8 12C7.896 12 7.792 12 7.688 12.032L6.4 9.432C6.768 9.072 7 8.568 7 8C7 7.432 6.768 6.928 6.4 6.568L7.688 3.968C7.792 4 7.896 4 8 4C8.104 4 8.208 4 8.312 3.968L9.6 6.568C9.24 6.928 9 7.44 9 8C9 8.53043 9.21071 9.03914 9.58579 9.41421C9.96086 9.78929 10.4696 10 11 10C11.104 10 11.2 10 11.312 9.968L12.616 12.568C12.24 12.928 12 13.44 12 14C12 14.5304 12.2107 15.0391 12.5858 15.4142C12.9609 15.7893 13.4696 16 14 16C14.5304 16 15.0391 15.7893 15.4142 15.4142C15.7893 15.0391 16 14.5304 16 14C16 13.4696 15.7893 12.9609 15.4142 12.5858C15.0391 12.2107 14.5304 12 14 12Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
});

export const BinaryTreeIconMetadata = {
  name: 'Binary Tree',
  importName: 'BinaryTreeIcon',
  component: <BinaryTreeIcon aria-label="Binary Tree Icon" />,
  tags: 'binary, tree, context, structure, data, hierarchy, organization, flow, diagram, chart, graph, network, system, relationship, connection, link, node, leaf, root, branch',
};
