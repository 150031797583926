// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const JiraBugIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="#FF5630"
        fillRule="evenodd"
        d="M2,0 L14,0 C15.1045695,-2.02906125e-16 16,0.8954305 16,2 L16,14 C16,15.1045695 15.1045695,16 14,16 L2,16 C0.8954305,16 1.3527075e-16,15.1045695 0,14 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z M8,12 C10.209139,12 12,10.209139 12,8 C12,5.790861 10.209139,4 8,4 C5.790861,4 4,5.790861 4,8 C4,10.209139 5.790861,12 8,12 Z"
      />
    </Icon>
  );
});

export const JiraBugIconMetadata = {
  name: 'Jira Bug',
  importName: 'JiraBugIcon',
  component: <JiraBugIcon aria-label="Jira Bug Icon" />,
  tags: 'jira, bug, issue, type, crm, ticket, atlassian, logo, brand, company, org, organization',
};
