// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { uuid4 } from '@/utils/generators';
import { Icon } from './icon';

export const OneDriveIcon = forwardRef((props: IconProps, ref) => {
  const guid = `onedrive-icon-${uuid4()}`;

  return (
    <Icon viewBox="0 0 256 165" {...props} ref={ref} fill="none">
      <g clipPath={`url(#clip-1-${guid})`}>
        <path
          d="M154.66 110.682L207.502 60.148C196.526 17.348 152.932 -8.44901 110.132 2.52799C90.8589 7.47084 74.1192 19.4181 63.1801 36.038C63.9971 36.018 154.66 110.682 154.66 110.682Z"
          fill="#0364B8"
        />

        <path
          d="M97.618 45.552L97.616 45.561C87.5188 39.3072 75.874 36.0018 63.997 36.018C63.723 36.018 63.453 36.035 63.179 36.038C27.852 36.476 -0.432004 65.47 0.00499642 100.798C0.163695 113.613 4.16767 126.084 11.498 136.596L90.663 126.681L151.357 77.741L97.618 45.552Z"
          fill="#0078D4"
        />

        <path
          d="M207.502 60.148C206.334 60.0653 205.163 60.0216 203.992 60.017C196.903 60.0101 189.889 61.458 183.382 64.271L183.38 64.266L151.358 77.741L186.66 121.348L249.77 136.689C263.39 111.406 253.934 79.869 228.65 66.249C222.117 62.73 214.906 60.649 207.502 60.148Z"
          fill="#1490DF"
        />

        <path
          d="M11.498 136.596C17.3871 145.063 25.238 151.979 34.3802 156.753C43.5224 161.528 53.6843 164.019 63.998 164.013H203.992C213.378 164.016 222.59 161.477 230.65 156.666C238.71 151.856 245.317 144.953 249.77 136.69L151.357 77.74L11.498 136.596Z"
          fill="#28A8EA"
        />
      </g>

      <defs>
        <clipPath id={`clip-1-${guid}`}>
          <rect width="256" height="165" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
});

export const OneDriveIconMetadata = {
  name: 'OneDrive',
  importName: 'OneDriveIcon',
  component: <OneDriveIcon aria-label="OneDrive Icon" />,
  tags: 'one, drive, onedrive, microsoft, office, cloud, storage, file, folder, document, logo, brand, company, org, organization',
};
