// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const TicketIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref}>
      <g clipPath="url(#clip0_597_75)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 3C14.5046 2.99984 14.9906 3.19041 15.3605 3.5335C15.7305 3.87659 15.9572 4.34684 15.995 4.85L16 5V6.055L19.641 7.031C20.1285 7.16149 20.5487 7.47147 20.8172 7.89877C21.0858 8.32608 21.1829 8.83912 21.089 9.335L21.055 9.48L18.467 19.14C18.3363 19.6273 18.0262 20.0473 17.599 20.3156C17.1717 20.584 16.6587 20.6809 16.163 20.587L16.018 20.554L15.431 20.397C15.109 20.727 14.672 20.947 14.185 20.992L14 21H5C4.49542 21.0002 4.00943 20.8096 3.63945 20.4665C3.26947 20.1234 3.04284 19.6532 3.005 19.15L3 19V5C2.99984 4.49542 3.19041 4.00943 3.5335 3.63945C3.87659 3.26947 4.34684 3.04284 4.85 3.005L5 3H14ZM14 5H5V19H14V5ZM16 8.126V18.479L16.535 18.622L19.124 8.962L16 8.126Z"
          fill="currentColor"
        />
      </g>

      <defs>
        <clipPath id="clip0_597_75">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
});

export const TicketIconMetadata = {
  name: 'Ticket',
  importName: 'TicketIcon',
  component: <TicketIcon aria-label="Ticket Icon" />,
  tags: 'ticket, tickets, pass, card, cards, event, entry',
};
