// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const ViolationAttentionIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 34 34" {...props} ref={ref}>
      <path
        d="M17 1.41666L4.25 7.08332V15.5833C4.25 23.4458 9.69 30.7983 17 32.5833C24.31 30.7983 29.75 23.4458 29.75 15.5833V7.08332M15.5833 9.91666H18.4167V18.4167H15.5833M15.5833 21.25H18.4167V24.0833H15.5833"
        fill="currentColor"
      />
    </Icon>
  );
});

export const ViolationAttentionIconMetadata = {
  name: 'Violation Attention',
  importName: 'ViolationAttentionIcon',
  component: <ViolationAttentionIcon aria-label="Violation Attention Icon" />,
  tags: 'violation, attention, shield, exclamation, alert, warning, danger, caution',
};
