// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const AzureDevOpsWikiIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 126 125" {...props} ref={ref} fill="none">
      <path
        d="M125.957 23.4107V99.4107L94.7068 125L46.2693 107.366V124.848L18.8496 89.0179L98.7693 95.2679V26.8304L125.957 23.4107ZM99.3139 27.2321L54.4746 0V17.8661L13.2961 29.9643L0.956787 45.8036V81.7768L18.6175 89.5714V43.4732L99.3139 27.2411V27.2321Z"
        fill="#1F88DC"
      />

      <path
        d="M49 66C49 64.8954 49.8954 64 51 64H92C93.1046 64 94 64.8954 94 66V94C94 96.2091 92.2091 98 90 98H53C50.7909 98 49 96.2091 49 94V66Z"
        fill="#1C6DAE"
      />

      <path
        d="M58 50C58 48.8954 58.8954 48 60 48H101C102.105 48 103 48.8954 103 50V78C103 80.2091 101.209 82 99 82H62C59.7909 82 58 80.2091 58 78V50Z"
        fill="#3996E0"
      />

      <path
        d="M58 64H103V94C103 96.2091 101.209 98 99 98H62C59.7909 98 58 96.2091 58 94V64Z"
        fill="#2783D1"
      />

      <path
        d="M94 64H103V93.1538C103 95.8303 100.83 98 98.1538 98C95.8597 98 94 96.1403 94 93.8462V64Z"
        fill="url(#paint0_linear_12_4)"
      />

      <path
        d="M85 59C85 58.4477 85.4477 58 86 58H93C93.5523 58 94 58.4477 94 59V74C94 74.5523 93.5523 75 93 75H85V59Z"
        fill="#ACDAFF"
      />

      <path
        d="M85 59C85 58.4477 85.4477 58 86 58H93C93.5523 58 94 58.4477 94 59V64H85V59Z"
        fill="#BEE2FD"
      />

      <path d="M76 65C76 64.4477 76.4477 64 77 64H85V75H76V65Z" fill="#96D0FC" />

      <path
        d="M67 69C67 68.4477 67.4477 68 68 68H76V75H68C67.4477 75 67 74.5523 67 74V69Z"
        fill="#94CFFC"
      />

      <path d="M67 82H94V84.5H67V82Z" fill="#54AAEE" />

      <path d="M67 87H81V89.5H67V87Z" fill="#54AAEE" />

      <defs>
        <linearGradient
          id="paint0_linear_12_4"
          x1="76"
          y1="48"
          x2="76"
          y2="98"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B86DB" />

          <stop offset="1" stopColor="#047AD8" />
        </linearGradient>
      </defs>
    </Icon>
  );
});

export const AzureDevOpsWikiIconMetadata = {
  name: 'Azure DevOps Wiki',
  importName: 'AzureDevopsWikiIcon',
  component: <AzureDevOpsWikiIcon aria-label="Azure DevOps Wiki Icon" />,
  tags: 'azure, devops, code, repo, repository, cat, microsoft, logo, brand, company, org, organization, version, control, vc',
};
