// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const DenialOfServiceIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 512 512" {...props} ref={ref} fill="none">
      <path
        d="M256 96V56M256 456V416"
        stroke="currentColor"
        strokeWidth="48"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M256 112C227.52 112 199.679 120.445 175.998 136.268C152.317 152.091 133.86 174.581 122.961 200.894C112.062 227.206 109.211 256.16 114.767 284.093C120.323 312.026 134.038 337.685 154.177 357.823C174.315 377.962 199.974 391.677 227.907 397.233C255.84 402.789 284.794 399.938 311.106 389.039C337.419 378.14 359.909 359.683 375.732 336.002C391.555 312.321 400 284.481 400 256C400 217.809 384.829 181.182 357.823 154.177C330.818 127.171 294.191 112 256 112Z"
        stroke="currentColor"
        strokeWidth="32"
        strokeMiterlimit="10"
      />

      <path
        d="M416 256H456M56 256H96"
        stroke="currentColor"
        strokeWidth="48"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
});

export const DenialOfServiceIconMetadata = {
  name: 'Denial Of Service',
  importName: 'DenialOfServiceIcon',
  component: <DenialOfServiceIcon aria-label="Denial Of Service Icon" />,
  tags: 'denial, service, stride, security',
};
