// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { uuid4 } from '@/utils/generators';
import { Icon } from './icon';

export const SlackIcon = forwardRef((props: IconProps, ref) => {
  const guid = `slack-icon-${uuid4()}`;

  return (
    <Icon viewBox="0 0 24 24" {...props} ref={ref} fill="none">
      <g clipPath={`url(#clip-1-${guid})`}>
        <path
          d="M5.11035 15.1349C5.11035 16.5093 3.98948 17.6318 2.61491 17.6318C1.24035 17.6318 0.118164 16.5093 0.118164 15.1349C0.118164 13.7605 1.24073 12.6379 2.6151 12.6379H5.11054L5.11035 15.1349ZM6.36829 15.1349C6.36829 13.7605 7.49085 12.6379 8.86523 12.6379C10.2396 12.6379 11.3622 13.7603 11.3622 15.1349V21.3852C11.3622 22.7596 10.2398 23.8821 8.86523 23.8821C7.49085 23.8821 6.36829 22.7596 6.36829 21.3852V15.1349Z"
          fill="#DE1C59"
        />

        <path
          d="M8.86523 5.11035C7.49085 5.11035 6.36829 3.98948 6.36829 2.61491C6.36829 1.24035 7.49085 0.118164 8.86523 0.118164C10.2396 0.118164 11.3622 1.24073 11.3622 2.6151V5.11054L8.86523 5.11035ZM8.86523 6.36829C10.2396 6.36829 11.3622 7.49085 11.3622 8.86523C11.3622 10.2396 10.2398 11.3622 8.86523 11.3622H2.61491C1.24054 11.3622 0.118164 10.2398 0.118164 8.86523C0.118164 7.49085 1.24073 6.36829 2.6151 6.36829H8.86523Z"
          fill="#35C5F0"
        />

        <path
          d="M18.8895 8.86523C18.8895 7.49085 20.0104 6.36829 21.3849 6.36829C22.7595 6.36829 23.8819 7.49085 23.8819 8.86523C23.8819 10.2396 22.7593 11.3622 21.3849 11.3622H18.8895V8.86523ZM17.6316 8.86523C17.6316 10.2396 16.509 11.3622 15.1346 11.3622C13.7603 11.3622 12.6377 10.2398 12.6377 8.86523V2.61491C12.6377 1.24054 13.7601 0.118164 15.1346 0.118164C16.509 0.118164 17.6316 1.24073 17.6316 2.6151V8.86523Z"
          fill="#2EB57D"
        />

        <path
          d="M15.1346 18.8898C16.509 18.8898 17.6316 20.0106 17.6316 21.3852C17.6316 22.7598 16.509 23.8821 15.1346 23.8821C13.7603 23.8821 12.6377 22.7596 12.6377 21.3852V18.8898H15.1346ZM15.1346 17.6318C13.7603 17.6318 12.6377 16.5093 12.6377 15.1349C12.6377 13.7605 13.7601 12.6379 15.1346 12.6379H21.3849C22.7593 12.6379 23.8819 13.7603 23.8819 15.1349C23.8819 16.5093 22.7593 17.6318 21.3849 17.6318H15.1346Z"
          fill="#EBB02E"
        />
      </g>

      <defs>
        <clipPath id={`clip-1-${guid}`}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
});

export const SlackIconMetadata = {
  name: 'Slack',
  importName: 'SlackIcon',
  component: <SlackIcon aria-label="Slack Icon" />,
  tags: 'slack, chat, message, communication, logo, brand, company, org, organization, team, collaboration, workspace, channel',
};
